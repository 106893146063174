import { ResizablePanel } from '@packages/ui/shadcn/ui/resizable.tsx';
import {
  Background,
  BackgroundVariant,
  NodeProps,
  ReactFlow,
} from '@xyflow/react';
import { FlowChartNode } from '@app/types';
import { ShapesHOC } from '@components/flowchart/shapes/ShapesHOC.tsx';
import {
  CircleShape,
  RectangleShape,
  SquareShape,
} from '@components/flowchart/shapes';
import { DiamondShape } from '@components/flowchart/shapes/Diamond.tsx';
import { InputEdge } from '@components/flowchart/shapes/edge.tsx';
import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { ReactNode } from 'react';

const nodeTypes = {
  square: (props: NodeProps<FlowChartNode>) => ShapesHOC(SquareShape, props),
  circle: (props: NodeProps<FlowChartNode>) => ShapesHOC(CircleShape, props),
  diamond: (props: NodeProps<FlowChartNode>) => ShapesHOC(DiamondShape, props),
  rectangle: (props: NodeProps<FlowChartNode>) =>
    ShapesHOC(RectangleShape, props),
};

const edgeTypes = {
  input: InputEdge,
};

export const LockedFlowChart = ({
  children,
  id = 'left-panel',
}: {
  children?: ReactNode;
  id?: string;
}) => {
  const { nodes, edges } = flowChartStore(
    useShallow(state => ({ nodes: state.nodes, edges: state.edges })),
  );

  return (
    <ResizablePanel
      defaultSize={50}
      className="min-w-[400px] flex flex-col items-center justify-start p-8 gap-6 bg-light-3 fade-in"
      id={id}
      order={1}
    >
      <div className="w-full max-h-[calc(100%-50px)] h-[calc(100%-50px)] flex-grow overflow-scroll relative">
        {children}
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          fitView
          edgesReconnectable={false}
          edgesFocusable={false}
          nodesDraggable={false}
          nodesConnectable={false}
          nodesFocusable={false}
          draggable={true}
          panOnDrag={true}
          elementsSelectable={false}
        >
          <Background
            variant={BackgroundVariant.Lines}
            color="#FFF"
            style={{ backgroundColor: '#D4D4D4' }}
          />
        </ReactFlow>
      </div>
    </ResizablePanel>
  );
};
