import { ResizablePanel } from '@rangpt/components/ui/resizable.tsx';
import { Code } from '@rangpt/components/code/index.ts';
import { Header } from '@rangpt/components/code/header.tsx';
import { Markup } from '@rangpt/components/code/markup.tsx';

export const EmptyState = () => {
  return (
    <ResizablePanel
      className="w-full flex basis-1/2 grow-0 overflow-hidden p-4 min-h-sm"
      defaultSize={40}
    >
      <Code.Container>
        <div className="w-full flex items-start justify-between">
          <Header title="Final Code"></Header>
        </div>

        <Markup
          code={{
            content: 'No code generated for this prompt',
            timestamp: Date.now() / 1000,
            id: 'nullable',
            type: 'code',
          }}
          readOnly={false}
        />
      </Code.Container>
    </ResizablePanel>
  );
};
