export enum SOCKET_EVENTS {
  FLOW_CHART_CODE = 'flow_chart_code',
  STATUS = 'status',
  REVIEW_CODE_AND_RUN = 'review_code_and_run',
  TEST_APPLICATION_RESULT = 'test_application_result',
  TEST_APPLICATION = 'test_application',
  RUN_TEST_CASE = 'run_test_case',
  TEST_CASE_RESULT = 'test_case_result',
  FLOWCHART_OBJECTIVES = 'flowchart_objectives',
  KEY_PHRASES = 'key_phrases',
}

export enum WS_STATUS {
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface WsBaseEvent {
  chat_id: string;
  timestamp: number;
  origin: string;
}

export interface WSTestEvents<T> extends WsBaseEvent {
  id: string;
  initial: T | null;
  expected: T | null;
  result: T | null;
}

export interface WSStatusEvent {
  chat_id: string;
  status: WS_STATUS;
  reason: string;
  timestamp: number;
  subject: WS_STATUS_SUBJECTS;
}

export enum WS_STATUS_SUBJECTS {
  CODE = 'code',
  REVIEW = 'review',
  TEST = 'test',
  KEY_PHRASE = 'key_phrase',
}

export interface WSKeyPhraseEvent extends WsBaseEvent {
  list: { key: string; list: string[] }[];
  status: WS_STATUS;
}
