import { Check, PlusIcon } from 'lucide-react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useMemo, useRef, useState } from 'react';
import useDashboard from '@rangpt/hooks/useDashboard.ts';
import { CodeT } from '@rangpt/types';
import { Chart } from '@rangpt/types/dashboard.types.ts';
import { ChartType } from '@packages/api';

interface AddToDashBoardProps {
  chart: {
    timestamp?: number;
    content?: string;
    cachedChart?: string;
  };
  code?: CodeT;
  isHTML: boolean;
}

const AddToDashBoard = ({ chart, code, isHTML }: AddToDashBoardProps) => {
  const { addGraphToDashboard, dashboard } = useDashboard();
  const graphToDashboard: Chart = {
    id: chart.content!,
    url: chart.content!,
    cachedChart: chart.cachedChart,
    code: code?.content ?? '',
    type: isHTML ? ChartType.HTML : ChartType.CHART,
  };
  const [graphSelected, setGraphSelected] = useState(false);
  const addRef = useRef<HTMLDivElement>(null);
  const addedRef = useRef<HTMLDivElement>(null);
  const nodeRef = graphSelected ? addRef : addedRef;

  const addChart = () => {
    addGraphToDashboard(graphToDashboard);
    setGraphSelected(true);
  };

  useMemo(() => {
    if (dashboard.find(graph => graph.id === chart.content)) {
      setGraphSelected(true);
    } else {
      setGraphSelected(false);
    }
  }, [chart, dashboard]);

  return (
    <button
      type="button"
      className={`fade-in relative flex items-center justify-between w-full rounded-md bg-white py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:leading-6 cursor-pointer disabled:bg-gray-100 disabled:shadow-neutral-200 ${
        graphSelected && 'cursor-auto'
      }`}
      onClick={addChart}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={graphSelected ? 'Selected' : 'select'}
          nodeRef={nodeRef}
          addEndListener={(done: () => void) => {
            nodeRef.current?.addEventListener('transitionend', done, false);
          }}
          classNames="slide"
        >
          <span ref={nodeRef} className="text-left text-gray-900 text-sm">
            {graphSelected ? 'Chart added' : 'Add to dashboard'}
          </span>
        </CSSTransition>
      </SwitchTransition>
      {graphSelected ? (
        <Check width={16} height={16} />
      ) : (
        <PlusIcon width={16} height={16} />
      )}
    </button>
  );
};

export { AddToDashBoard };
