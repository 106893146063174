import { ReactNode, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@packages/ui/shadcn/ui/dialog.tsx';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { Loader2 } from 'lucide-react';

interface DefaultModalProps {
  children: ReactNode;
  trigger: ReactNode;
  onConfirm: (...args: any[]) => void;
  onCancel?: (...args: unknown[]) => void;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  loading: boolean;
}

export const DefaultModal = ({
  children,
  trigger,
  onConfirm,
  onCancel = () => {},
  title,
  description,
  confirmText = 'Save',
  cancelText = 'Cancel',
  loading,
}: DefaultModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCancel();
    setIsOpen(false);
    e.stopPropagation();
  };

  const handleSave = () => {
    onConfirm();
    setTimeout(() => {
      if (!loading) {
        setIsOpen(false);
      }
    }, 400);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild onClick={e => e.stopPropagation()}>
        {trigger}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] gap-8">
        <DialogHeader
          className="flex flex-col items-start gap-1"
          onClick={e => e.stopPropagation()}
        >
          <DialogTitle className="text-center">{title}</DialogTitle>
          <DialogDescription className="text-sm">
            {description}
          </DialogDescription>
        </DialogHeader>
        {children}
        <section className="w-full flex items-center justify-center gap-8">
          <DialogClose asChild>
            <Button
              style={{ borderColor: '#151515' }}
              variant="outline"
              className="p-3 w-full self-end text-center rounded-xl bg-white text-dark-1 text-xs font-normal pointer-events-auto hover:bg-transparent"
              type="button"
              onClick={handleCancel}
              disabled={loading}
            >
              {cancelText}
            </Button>
          </DialogClose>
          <Button
            className="p-3 w-full self-end flex rounded-xl bg-dark-1 text-white text-xs shadow-md pointer-events-auto"
            variant="outline"
            onClick={handleSave}
            disabled={loading}
            type="submit"
          >
            {loading ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              confirmText
            )}
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
};
