import { useEffect, useRef, useState } from 'react';

export const usePromptShadow = ({
  containers,
}: {
  containers: React.MutableRefObject<HTMLDivElement[]>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [indexToShowShadow, setIndexToShowShadow] = useState(-1);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener('scroll', listener);

    return () => {
      ref.current?.removeEventListener('scroll', listener);
    };
  }, []);

  function listener() {
    if (ref.current) {
      if (ref.current.scrollTop <= 20) {
        setIndexToShowShadow(-1);
        return;
      }
      if (containers.current[0]) {
        let totalHeight = 0;
        let currentContainerIndex = -1;
        for (let i = 0; i < containers.current.length; i++) {
          totalHeight += containers.current[i].clientHeight;

          if (ref.current.scrollTop < totalHeight - 50) {
            if (currentContainerIndex === i) break;
            currentContainerIndex = i;
            break;
          }
        }
        setIndexToShowShadow(currentContainerIndex);
      }
    }
  }

  return {
    ref,
    indexToShowShadow,
    setIndexToShowShadow,
  };
};
