import {
  ResizableHandle,
  ResizablePanelGroup,
} from '@packages/ui/shadcn/ui/resizable.tsx';
import { cn } from '@app/lib/utils.ts';
import { Outlet, useLocation } from '@tanstack/react-router';
import { Stepper } from '@components/stepper';
import { CodeContainer } from '@components/CodeConsole';

export function CodeConsoleLayout() {
  const location = useLocation();
  const currentRoute = location.pathname.split('/').filter(Boolean).pop() || '';

  const isOnDevelopment = currentRoute === 'development';

  return (
    <div className="w-full h-full max-h-screen ease-resize">
      <Stepper.Container />
      <ResizablePanelGroup
        direction="horizontal"
        className={cn('max-w-full w-full h-full flex flex-col')}
      >
        {isOnDevelopment ? (
          <>
            <Outlet />
            <ResizableHandle />
            <CodeContainer
              title="TiltDemo - Generated Code"
              description="Code generated based on your flowchart, you can edit the code and run tests."
            />
          </>
        ) : (
          <>
            <CodeContainer title="TiltDemo - Generated Code" />
            <ResizableHandle />
            <Outlet />
          </>
        )}
      </ResizablePanelGroup>
    </div>
  );
}
