import { create } from 'zustand';
import { defaultForm, tables } from '@packages/api/types';

const defaultData = {
  redshift: [
    { field: 'host', tooltip: 'main database URL' },
    { field: 'database', tooltip: 'database name' },
    { field: 'tables', tooltip: 'data tables list' },
    { field: 'port', tooltip: 'connection port' },
    { field: 'user', tooltip: 'username access' },
    { field: 'password', tooltip: 'user access key' },
  ],
  snowflake: [
    { field: 'user', tooltip: 'username access' },
    { field: 'password', tooltip: 'user access key' },
    { field: 'account', tooltip: 'account identifier' },
    { field: 'warehouse', tooltip: 'data warehouse name' },
    { field: 'database', tooltip: 'database name' },
    { field: 'tables', tooltip: 'data tables list' },
    { field: 'schema', tooltip: 'database schema' },
  ],
};

interface UIStateStore {
  // selectedColumns: string[];
  // setSelectedColumns: (columns: string[]) => void;
  defaultForm: defaultForm;
  WsTables: tables[];
  setWsTables: (tables: tables[]) => void;
  setDefaultForm: (form: defaultForm) => void;
  currentDatabase: string;
  setCurrentDatabase: (currentDatabase: string) => void;
  showConnect: boolean;
  setShowConnect: (showConnect: boolean) => void;
}

/*
* This store represents UI state data (as in UI) and is used to represent data in the format we received to mount or display
* */
export const UiStore = create<UIStateStore>(set => ({
  // selectedColumns: [],
  // setSelectedColumns: columns => set({ selectedColumns: columns }),
  defaultForm: defaultData,
  WsTables: [],
  setWsTables: tables => set({ WsTables: tables }),
  setDefaultForm: json => set({ defaultForm: json }),
  currentDatabase: Object.keys(defaultData)[0],
  setCurrentDatabase: currentDatabase => set({ currentDatabase }),
  showConnect: false,
  setShowConnect: showConnect => set({ showConnect }),
}));
