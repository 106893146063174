import { Header } from './header.tsx';
import { Container } from './container.tsx';
import { Markup } from './markup.tsx';
import { EmptyState } from '@rangpt/components/code/emptyState.tsx';

const Code = {
  Container,
  Header,
  Markup,
  EmptyState,
};

export { Code };
