import { create } from 'zustand';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  MarkerType,
} from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';

import { FlowChartState } from '@app/types/flowchart.types';
import { initialEdges, initialNodes } from '@app/lib/mockValues.ts';

export const flowChartStore = create<FlowChartState>((set, get) => ({
  nodes: initialNodes,
  edges: initialEdges,
  id: uuidv4(),
  setId: id => set({ id }),
  onNodesChange: changes => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: changes => {
    set({
      edges: applyEdgeChanges(
        changes,
        get().edges.map(edg => ({
          ...edg,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            height: 20,
            width: 20,
            color: '#000',
          },
          type: 'input',
          style: {
            stroke: '#000',
            strokeWidth: 1,
          },
        })),
      ),
    });
  },
  onConnect: connection => {
    set({
      edges: addEdge(
        {
          ...connection,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            height: 20,
            width: 20,
            color: '#000',
          },
          type: 'input',
          style: {
            stroke: '#000',
            strokeWidth: 1,
          },
        },
        get().edges,
      ),
    });
  },
  setNodes: nodes => {
    set({ nodes });
  },
  setEdges: edges => {
    set({
      edges: edges.map(edg => ({
        ...edg,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          height: 20,
          width: 20,
          color: '#000',
        },
        type: 'input',
        style: {
          stroke: '#000',
          strokeWidth: 1,
        },
      })),
    });
  },
  updateEdge: edge => {
    set({
      edges: get().edges.map(currEdge =>
        currEdge.id === edge.id ? { ...currEdge, ...edge } : currEdge,
      ),
    });
  },
  updateNode: node => {
    set({
      nodes: get().nodes.map(currNode =>
        currNode.id === node.id ? { ...currNode, ...node } : currNode,
      ),
    });
  },
  resetStore: () =>
    set({
      nodes: initialNodes,
      edges: initialEdges,
      id: uuidv4(),
    }),
}));
