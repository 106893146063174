import { ResizablePanel, ResizablePanelGroup } from '@rangpt/components/ui/resizable.tsx';
import { Welcome } from '@rangpt/components/welcome.tsx';
import { Children } from '@rangpt/types/jsx.types.ts';
import { useCommandInterface } from '@rangpt/hooks';

export function Content({ children }: Readonly<Children>) {
  const { chat } = useCommandInterface();

  if (chat.length > 0) {
    return (
      <ResizablePanel defaultSize={60} className="min-w-xl">
        <ResizablePanelGroup
          direction="vertical"
          className="flex flex-col min-h-0 overflow-hidden ease-resize"
        >
          {children}
        </ResizablePanelGroup>
      </ResizablePanel>
    );
  }

  return (
    <ResizablePanel
      className="fade-in h-full flex items-center justify-center min-w-3xl"
      defaultSize={60}
    >
      <Welcome />
    </ResizablePanel>
  );
}
