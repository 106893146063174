import { ReactElement } from 'react';

interface CodeContainerProps {
  children?: ReactElement | ReactElement[] | undefined;
}

const Container = ({ children }: CodeContainerProps) => {
  return <div className="w-full h-full flex flex-col fade-in relative">{children}</div>;
};

export { Container };
