// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const API_URL: string = import.meta.env.VITE_API_URL;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const WS_URL: string = import.meta.env.VITE_WS_URL;

export const token = localStorage.getItem('token') ?? '';
