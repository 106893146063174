import { API_URL, token } from '../configs.ts';
import { ClientInfo, UpdateClientPayload } from '@api/types';

const defaultUrl = `${API_URL}/client`;

export class ClientClient {
  public defaultHeaders: Record<string, string>;

  constructor(userToken?: string) {
    this.defaultHeaders = {
      authorization: userToken ?? token,
      'Content-Type': 'application/json',
    };
  }

  public async getClient(id: string) {
    console.debug('Getting client client id');
    return await this.fetchWithErrorHandling<ClientInfo>(
      `${defaultUrl}/${id}`,
      {
        method: 'GET',
        headers: this.defaultHeaders,
      },
    );
  }

  public async update(payload: UpdateClientPayload) {
    return await this.fetchWithErrorHandling(defaultUrl, {
      method: 'PATCH',
      headers: this.defaultHeaders,
      body: JSON.stringify(payload, (_k, v: unknown) => v ?? undefined),
    });
  }

  public async getFeatures(id: string) {
    return fetch(`${API_URL}/client/features/${id}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    }).then(res => {
      if (!res.ok) {
        console.error('~ client feature - Failed to get features data!');
        throw new Error('Failed to get features data!');
      }
      return res.json();
    });
  }

  private async fetchWithErrorHandling<T>(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Wrong user or password!');
      }

      throw new Error(
        `Error: status: ${response.status}, message: ${response.statusText}`,
      );
    }

    return (await response.json()) as T;
  }
}
