import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { FormEvent, useEffect, useRef } from 'react';

const Popover = ({
  submit,
  defaultValue,
  close,
  fromTop,
}: {
  submit: (value: string) => void;
  defaultValue?: string;
  close: () => void;
  fromTop: boolean;
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const sendFeedback = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // @ts-ignore
    const textarea = e.target[0] as HTMLTextAreaElement;
    console.debug(textarea.value);

    submit(textarea.value);
    close();
  };

  const position = fromTop
    ? 'bottom-full mb-1 right-0'
    : 'top-full mt-1 left-0';

  return (
    <div
      className={`w-72 bg-white border border-[#CBD5E1] rounded-sm absolute ${position} p-2 z-20`}
    >
      <form
        className="w-full flex flex-col justify-center items-center my-1"
        onSubmit={sendFeedback}
      >
        <div className="flex flex-col relative w-full min-w-[200px] h-20">
          <textarea
            ref={inputRef}
            className="peer w-full h-full max-h-full rounded-[7px] border border-blue-gray-200 px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            placeholder=" "
            cols={4}
            id="feedback"
            defaultValue={defaultValue}
          />
          <label
            className="before:content[' '] after:content[' ']
          pointer-events-none absolute left-0 -top-1.5 flex h-5/10 w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all
          before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:rounded-tl-md before:border-t before:border-l
          before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block
          after:h-1.5 after:flex-grow after:rounded-tr-md
          after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75]
          peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px]
          peer-focus:leading-tight peer-focus:before:border-t-1 peer-focus:before:border-l-2 peer-focus:before:border-[#e5e5e5] peer-focus:after:border-t-1
          peer-focus:after:border-r-2 peer-focus:after:border-[#e5e5e5] peer-disabled:text-transparent peer-disabled:before:border-transparent
          peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
            htmlFor="feedback"
          >
            Feedback
          </label>
          <div className="w-full text-[#94A3B8] ml-1 text-[9px] text-start">
            Your Feedback Powers the Future of Intelligent Conversations
          </div>
        </div>
        <section className="w-full my-2 flex flex-row gap-2 justify-end">
          <Button
            className="p-2 px-4 flex rounded-[6px] border-[#cbd5e1] text-sm text-[0.75rem]"
            variant="outline"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="p-2 px-4 flex rounded-[6px] bg-[#121212] text-white text-sm text-[0.75rem]"
            variant="outline"
          >
            Continue
          </Button>
        </section>
      </form>
    </div>
  );
};

export { Popover };
