import { User } from '@packages/api';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from '@packages/ui/shadcn/ui/table.tsx';
import { TableColumnHeader } from './TableColumnHeader.tsx';
import { UserRow } from '@management/components/AccountManagement/UserRow.tsx';

interface UserListTableProps {
  users: User[];
  switchUserState: (id: string) => void;
  usersActive: number;
}

export const UserListTable = ({
  users,
  usersActive,
  switchUserState,
}: UserListTableProps) => {
  if (users.length === 0) return;

  return (
    <div className="bg-white py-3 px-6 w-full rounded-lg self-start min-w-3xl shadow-lg">
      <Table className="max-h-full w-full">
        <TableHeader>
          <TableRow className="hover:bg-transparent">
            <TableColumnHeader title="Email" field="username" />
            <TableColumnHeader title="Data" field="createdAt" />
            <TableColumnHeader title="Active" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map(user => (
            <UserRow
              key={user.id}
              user={user}
              onChecked={switchUserState}
              usersActives={usersActive}
            />
          ))}
          {/*@TODO: work pagination container*/}
          {/*<PaginationContainer usersQTD={users.length} />*/}
        </TableBody>
      </Table>
    </div>
  );
};
