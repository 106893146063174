import { RoutineData, RoutinesList } from '@app/types/ui.types.ts';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { initialEdges, initialNodes } from '@app/lib/mockValues.ts';

export interface RoutinesStore {
  routines: RoutinesList[];
  setRoutines: (routines: RoutinesList[]) => void;
  currentRoutine: RoutineData;
  setCurrentRoutine: (routine: RoutineData) => void;
  owner: string | null;
  resetStore: () => void;
}

const initialRoutine = {
  flowChart: {
    nodes: initialNodes,
    edges: initialEdges,
  },
  id: uuidv4(),
  code: null,
  review: null,
  updatedAt: Date.now(),
  name: null,
};

const initialState = {
  routines: [],
  currentRoutine: initialRoutine,
  owner: null,
};

export const useRoutinesStore = create<RoutinesStore>(set => ({
  ...initialState,
  setCurrentRoutine: routine => set({ currentRoutine: routine }),
  setRoutines: routines => set({ routines: routines }),
  resetStore: () => set(initialState),
}));
