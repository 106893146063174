import { TanStackRouterDevtools } from '@tanstack/router-devtools';

export const ErrorPage = ({ error }: { error: Error }) => {
  console.debug('~ caught error', error);

  return (
    <div className="w-screen h-screen flex flex-col gap-12 items-center justify-center">
      <img src="/aira-main-logo.png" alt="Aira logo" className="w-56 h-12" />
      <section className="flex flex-col gap-3 items-center justify-center">
        <h1 className="text-2xl font-bold">Naavik had an execution error!</h1>
        <span className="text-sm text-gray-600">
          Please try again. If persists, contact Aira team.
        </span>
      </section>
      <TanStackRouterDevtools position="bottom-right" />
    </div>
  );
};
