import { Chart } from '@rangpt/components/chart/chart.tsx';
import { ResizableHandle, ResizablePanel } from '@rangpt/components/ui/resizable.tsx';

export function Top() {
  return (
    <>
      <ResizablePanel defaultSize={60} className="w-full h-full max-h-full min-h-md">
        <Chart />
      </ResizablePanel>
      <ResizableHandle />
    </>
  );
}
