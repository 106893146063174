import { v4 as uuidv4 } from 'uuid';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import useSocketStore from '@rangpt/stores/useSocketStore.ts';
import { PromptData } from '@rangpt/types';
import { patchPrompt } from '@rangpt/apis/appHistory.api.ts';
import { useShallow } from 'zustand/react/shallow';
import { CommandInterfaceClient } from '@packages/api';

interface PromptPayload {
  message: string;
  origin?: string;
  blob: string | ArrayBuffer | undefined;
  blobType: string | undefined;
}

const useCommandInterface = () => {
  const { socket, config } = useSocketStore(
    useShallow(state => ({
      socket: state.socket,
      config: state.config,
    })),
  );

  const reset = () => {
    console.debug('~ Command interface tried to reset');
  };

  const commandInterfaceStore = useCommandInterfaceStore();
  const commandIntefaceApi = new CommandInterfaceClient();

  /*
   * @Websocket emitter, already has: timestamp, chat_id, username, and origin.
   *
   * all this can be replaced in args*/
  const emitter = (event: string, args: Record<string, any>) => {
    if (socket) {
      return socket.emit(event, {
        timestamp: Date.now(),
        chat_id: config.chat_id,
        username: config.username,
        origin: 'aira_chat',
        ...args,
      });
    }
  };

  const prompt = ({ message, origin = 'aira_chat' }: PromptPayload) => {
    if (message.length === 0) {
      return;
    }
    const chat_id = config.chat_id ?? uuidv4();
    const prompt_id = uuidv4();

    commandInterfaceStore.addPrompt({
      prompt_id,
      chat_id: chat_id,
      text: message,
      timestamp: Date.now(),
    });

    const index = commandInterfaceStore.chat.findIndex(
      prompt => prompt.prompt_id === prompt_id,
    );

    if (index > -1) {
      commandInterfaceStore.setCurrentPrompt(index);
    }

    emitter('command_interface_prompt', {
      prompt_id,
      text: message.trim(),
      origin,
    });

    commandInterfaceStore.setLoading(true);
  };

  // @TODO: Make the refactor of all tabs hooks

  const like = (index: number) => {
    emitter('like', {
      prompt_id: commandInterfaceStore.chat[index].prompt_id,
    });
    commandInterfaceStore.likePrompt(index);

    patchPrompt({
      id: commandInterfaceStore.chat[index].prompt_id,
      feedback: {
        like: true,
      },
    })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  const dislike = (index: number) => {
    emitter('dislike', {
      prompt_id: commandInterfaceStore.chat[index].prompt_id,
      text: commandInterfaceStore.chat[index].feedback?.text,
    });

    commandInterfaceStore.dislikePrompt(index);

    patchPrompt({
      id: commandInterfaceStore.chat[index].prompt_id,
      feedback: {
        dislike: true,
        text: commandInterfaceStore.chat[index].feedback?.text ?? '',
      },
    })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  const reRun = (index: number, code: string) => {
    emitter('rerun', {
      prompt_id: commandInterfaceStore.chat[index].prompt_id,
      code: code,
    });

    commandInterfaceStore.addCode(index, code);
    commandInterfaceStore.setLoading(true);

    commandInterfaceStore.addLoadingAnswer(
      commandInterfaceStore.chat[index].prompt_id,
    );
  };

  const cleanUpChat = () => {
    emitter('reset', {});
    commandInterfaceStore.clearChat();
    commandInterfaceStore.setLoading(false);
  };

  const pausePromptProcess = () => {
    emitter('stop_prompt', {
      prompt_id:
        commandInterfaceStore.chat[commandInterfaceStore.chat.length - 1]
          .prompt_id,
    });
  };

  const resetSystem = () => {
    emitter('system_reset', {});
    reset();
  };

  const sendCommand = () => {
    const payload = {
      chat: commandInterfaceStore.chat[commandInterfaceStore.chat.length - 1],
      id: config.chat_id,
      currentNode: commandInterfaceStore.currentNode,
      loading: commandInterfaceStore.loading,
      currentPrompt:
        commandInterfaceStore.currentPrompt < 0
          ? 0
          : commandInterfaceStore.currentPrompt,
    };

    commandIntefaceApi
      .saveCommandInterfaceChat(payload)
      .then(res => {
        console.debug(res);
      })
      .catch(e => console.error(e));
  };

  const changeCommandInterfaceChat = (data: PromptData[]) => {
    commandInterfaceStore.setChat(data);
    commandInterfaceStore.setCurrentNode(0);
    commandInterfaceStore.setCurrentPrompt(data.length - 1);
  };

  const lastCommandPromptData = () => {
    return commandInterfaceStore.chat[commandInterfaceStore.chat.length - 1];
  };

  const currentPromptData = () => {
    return commandInterfaceStore.chat[commandInterfaceStore.currentPrompt];
  };

  return {
    chat: commandInterfaceStore.chat,
    currentPrompt: commandInterfaceStore.currentPrompt,
    currentNode: commandInterfaceStore.currentNode,
    loading: commandInterfaceStore.loading,
    config,
    chatLength: commandInterfaceStore.chat.length,
    prompt,
    setCurrentPrompt: commandInterfaceStore.setCurrentPrompt,
    setCurrentNode: commandInterfaceStore.setCurrentNode,
    cleanUpChat,
    feedbackActions: {
      likePrompt: like,
      dislikePrompt: dislike,
      writeFeedback: commandInterfaceStore.writeFeedback,
    },
    reRun,
    pausePromptProcess,
    resetSystem,
    sendCommand,
    changeCommandInterfaceChat,
    lastCommandPromptData,
    currentPromptData,
  };
};

export { useCommandInterface };
