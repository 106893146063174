import { Line } from 'react-chartjs-2';
import { DataPoint } from './types.ts';
import { generateChartOptions } from './chartConfig.ts';
import { useMemo, useState } from 'react';

interface ChartComponentProps {
  data: DataPoint[];
  setTooltipInfo: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      x: number;
      y: number;
      content: string;
    }>
  >;
}

const ChartComponent = ({ data, setTooltipInfo }: ChartComponentProps) => {
  const [showAnnotations, setShowAnnotations] = useState(true);

  const chartData = useMemo(() => {
    const getPointRadiusForUETH = (_: DataPoint, index: number) => {
      const isHighlightedPoint =
        data[index].time === '18:30' || data[index].time === '22:00';
      return isHighlightedPoint ? 4 : 3; // 8px for highlighted point, 3px for others
    };

    return {
      labels: data.map(d => d.time),
      datasets: [
        {
          label: 'PRB Utilization',
          data: data.map(d => d.prbutildl),
          borderColor: 'rgb(63, 81, 181)', // More blue-ish like original
          tension: 0.1,
          yAxisID: 'y',
          pointRadius: data.map((d, i) => getPointRadiusForUETH(d, i)),
          pointHoverRadius: data.map((d, i) => getPointRadiusForUETH(d, i) + 2),
        },
        {
          label: 'UETH PDL',
          data: data.map(d => d.uethpdl),
          borderColor: 'rgb(233, 30, 99)', // More pink/red like original
          tension: 0.1,
          yAxisID: 'y1',
          pointRadius: data.map((d, i) => getPointRadiusForUETH(d, i)),
          pointHoverRadius: data.map((d, i) => getPointRadiusForUETH(d, i) + 2),
        },
        {
          label: 'RRC Mean Connections',
          data: data.map(d => d.rrcmeanconn),
          borderColor: 'rgb(76, 175, 80)', // Green like original
          tension: 0.1,
          yAxisID: 'y2',
        },
        {
          label: 'PRB Utilization Threshold (85%)',
          data: data.map(() => 85),
          borderColor: 'rgb(63, 81, 181)',
          borderDash: [5, 5], // Make it dotted
          borderWidth: 1,
          tension: 0,
          yAxisID: 'y',
          animation: false,
          pointRadius: 0, // Removes the circular points
          pointStyle: false, // Also helps ensure no points are shown
        },
        {
          label: 'UETH PDL Threshold (1000)',
          data: data.map(() => 1000),
          borderColor: 'rgb(233, 30, 99)',
          borderDash: [5, 5],
          borderWidth: 1,
          tension: 0,
          yAxisID: 'y1',
          animation: false,
          pointRadius: 0,
          pointStyle: false,
        },
        {
          label: 'RRC Mean Connections Threshold (75)',
          data: data.map(() => 75),
          borderColor: 'rgb(76, 175, 80)',
          borderDash: [5, 5],
          borderWidth: 1,
          tension: 0,
          yAxisID: 'y2',
          animation: false,
          pointRadius: 0,
          pointStyle: false,
        },
        // {
        //   label: 'Vertical Line 18:30',
        //   data: data.map(d => (d.time === '18:30' ? 100 : -1400)),
        //   borderColor: 'rgb(63, 81, 181)',
        //   borderDash: [5, 5],
        //   borderWidth: 1,
        //   tension: 0,
        //   yAxisID: 'y',
        //   pointRadius: 0,
        //   pointStyle: false,
        // },
        // {
        //   label: 'Vertical Line 22:00',
        //   data: data.map(d => (d.time === '22:00' ? 100 : -1400)),
        //   borderColor: 'rgb(63, 81, 181)',
        //   borderDash: [5, 5],
        //   borderWidth: 1,
        //   tension: 0,
        //   yAxisID: 'y',
        //   pointRadius: 0,
        //   pointStyle: false,
        // },
      ],
    };
  }, [data]);

  const options = generateChartOptions({
    data,
    setTooltipInfo,
    showAnnotations,
    setShowAnnotations,
  });

  return (
    <div className="w-full px-4">
      <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
        <div className="absolute top-0 left-0 w-full h-full">
          <Line
            data={chartData as any}
            options={options as any}
            id="networkChart"
            redraw={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
