import { ReactNode, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../shadcn/ui/dialog.tsx';
import { Button } from '../../shadcn/ui/button.tsx';

interface DefaultModalProps {
  children: ReactNode;
  onConfirm: (...args: unknown[]) => void;
  onCancel?: (...args: unknown[]) => void;
}

export const LogOutModal = ({
  children,
  onConfirm,
  onCancel = () => {},
}: DefaultModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  const handleSave = () => {
    onConfirm();
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px] gap-8">
        <DialogHeader className="flex flex-col items-start gap-1">
          <DialogTitle className="text-center text-md font-normal">
            Are you sure you want to log out?
          </DialogTitle>
        </DialogHeader>
        <section className="w-full flex items-center justify-center gap-8">
          <DialogClose asChild>
            <Button
              style={{ borderColor: '#151515' }}
              className="p-3 w-full self-end text-center rounded-lg bg-transparent shadow-lg text-dark-1 text-xs font-normal pointer-events-auto hover:bg-transparent"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            className="p-3 w-full self-end flex rounded-lg bg-dark-1 text-white text-xs shadow-md pointer-events-auto"
            variant="outline"
            onClick={handleSave}
            type="submit"
          >
            Log Out
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
};
