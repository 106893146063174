import { useCommandInterface, usePromptShadow } from '@rangpt/hooks';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { ResizablePanel } from '@packages/ui/shadcn/ui/resizable.tsx';
import { Input } from './input.tsx';
import { Content } from '@rangpt/components/chat/content.tsx';
import { FeedbackAlert } from '@rangpt/components/chat/feedbackAlert.tsx';

const Container = ({
  chat,
  loading,
  currentPrompt,
  feedbackActions,
  currentPromptData,
}: ReturnType<typeof useCommandInterface>) => {
  // Hooks
  const answersContainerRefs = useRef<HTMLDivElement[]>([]);
  const { ref, indexToShowShadow, setIndexToShowShadow } = usePromptShadow({
    containers: answersContainerRefs,
  });

  const data = currentPromptData();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight });
    }
  }, [ref, data?.answer?.length, loading]);

  useLayoutEffect(() => {
    setTimeout(function () {
      ref.current!.scrollTo({
        top: ref.current!.scrollHeight,
        behavior: 'instant',
      });
    }, 1);
  }, [ref]);

  useMemo(() => {
    if (!ref.current) return;

    if (!answersContainerRefs.current[currentPrompt]) return;

    console.debug('scroll', answersContainerRefs.current[currentPrompt]);

    const fontSize = parseFloat(
      window
        .getComputedStyle(window.document.documentElement)
        .getPropertyValue('font-size'),
    );

    ref.current.scrollTo({
      top:
        answersContainerRefs.current[currentPrompt].offsetTop - fontSize * 3.7,
      behavior: 'smooth',
    });

    setTimeout(() => setIndexToShowShadow(currentPrompt - 1), 700);
  }, [currentPrompt, ref, setIndexToShowShadow]);

  const needFeedback = useMemo(() => {
    if (loading) return false;
    if (!data) return false;
    if ('code' in data && data.code!.length > 0) return !data.feedback;
  }, [loading, data, data?.feedback]);

  return (
    <ResizablePanel
      className="flex flex-col border-r border-[#CBD5E1] max-h-full overflow-hidden min-w-[400px]"
      defaultSize={40}
    >
      <div
        id="chatContainer"
        className="h-full flex flex-col gap-3 overflow-y-scroll scroll-smooth"
        ref={ref}
      >
        <Content
          chat={chat}
          answersContainerRefs={answersContainerRefs}
          indexToShowShadow={indexToShowShadow}
        />
        {needFeedback && (
          <FeedbackAlert
            data={data}
            feedbackActions={feedbackActions}
            currentPrompt={currentPrompt}
          />
        )}
      </div>
      <Input loading={loading} isBlocked={needFeedback} chatRef={ref} />
    </ResizablePanel>
  );
};

export { Container };
