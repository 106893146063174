import { TestTabPayload, WSStatusEvent, WSTestEvents } from '@packages/api';
import { TestTable } from '@app/types';
import { TABLES } from '@app/stores/test.store';
import { UseMutationResult } from '@tanstack/react-query';

interface HandleTestResultProps {
  event: WSTestEvents<TestTable>;
  setTables: (table: TABLES, data: TestTable | null) => void;
  setLoading: (loading: boolean) => void;
  routineId: string;
  saveTestTab: UseMutationResult<unknown, Error, TestTabPayload, unknown>;
}

interface HandleTestCaseResultProps {
  event: WSTestEvents<TestTable>;
  setLoadingResult: (loading: boolean) => void;
  setTables: (table: TABLES, data: TestTable | null) => void;
  runMatchesTest: (table: TestTable) => void;
}

export const handleTestResult = ({
  event,
  // routineId,
  setLoading,
  setTables,
}: // saveTestTab,
HandleTestResultProps) => {
  if (!event.initial || !event.expected) return;

  const initialTable = {
    fields: event.initial.fields,
    actions: event.initial.actions,
  };

  const expectedTable = {
    fields: event.expected.fields,
    actions: event.expected.actions,
  };

  setTables(TABLES.INITIAL, initialTable);
  setTables(TABLES.EXPECTED, expectedTable);

  // saveTestTab.mutate({
  //   id: event.id,
  //   initialTable: initialTable,
  //   expectedTable: null,
  //   routineId,
  // });

  setLoading(false);
};

export const handleTestStatus = (event: WSStatusEvent) => {
  console.debug('~ receive test status event', event);
};

export const handleTestCaseResult = ({
  event,
  setLoadingResult,
  runMatchesTest,
  setTables,
}: HandleTestCaseResultProps) => {
  if (!event.result) {
    console.log('Test case result is null');
    return;
  }

  setLoadingResult(false);
  setTables(TABLES.RESULT, {
    fields: event.result.fields,
    actions: event.result.actions,
  });
  runMatchesTest(event.result);
};
