import { UiStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';
import { useEffect, useRef, useState } from 'react';
import { useSocket } from '@packages/hooks';
import {
  SOCKET_EVENTS,
  WS_STATUS,
  WS_STATUS_SUBJECTS,
  WSKeyPhraseEvent,
  WSStatusEvent,
} from '@packages/api';
import { toast } from 'react-toastify';
import { defaultToastErrorToastOptions } from '@packages/ui';

export function useObjectivesArea({ objectives }: { objectives?: string }) {
  const [text, setText] = useState('');
  const textRef = useRef('');
  const { emitter, socket } = useSocket();
  const {
    keyWordsList,
    addKeyWordList,
    setProcessedObjectives,
    setKeyWordsList,
    setLoadingKeyWords,
    loadingKeyWords,
  } = useObjectivesStore(
    useShallow(state => ({
      keyWordsList: state.keyWords,
      setKeyWordsList: state.setKeyWords,
      addKeyWordList: state.addKeyWordsList,
      setProcessedObjectives: state.setProcessedObjectives,
      loadingKeyWords: state.loadingKeyWords,
      setLoadingKeyWords: state.setLoadingKeyWords,
    })),
  );
  const { addObjectiveChange, setObjectivesChange } = UiStore(
    useShallow(state => ({
      addObjectiveChange: state.addObjectiveChange,
      setObjectivesChange: state.setObjectivesChange,
    })),
  );

  useEffect(() => {
    if (!socket) return;

    if (text.length === 0) {
      if (objectives) {
        setText(objectives);
      }
    }

    socket.on(SOCKET_EVENTS.STATUS, (e: WSStatusEvent) => {
      if (e.subject === WS_STATUS_SUBJECTS.KEY_PHRASE) {
        setLoadingKeyWords(true);
      }
    });

    socket.on(SOCKET_EVENTS.KEY_PHRASES, (e: WSKeyPhraseEvent) => {
      console.debug('~ received key phrases', e);
      setLoadingKeyWords(false);
      if (e.status === WS_STATUS.ERROR) {
        toast.error(
          'Problem analyzing your objectives.',
          defaultToastErrorToastOptions,
        );
      }
      setKeyWordsList(e.list);
      setProcessedObjectives(textRef.current);
    });

    return () => {
      setText('');
      setObjectivesChange([]);
      setLoadingKeyWords(false);
      setProcessedObjectives('');
    };
  }, [objectives]);

  const emitObjectives = (text?: string) => {
    if (text) {
      console.debug('~ saving objectives text', text);
      textRef.current = text;
    }
    emitter(SOCKET_EVENTS.FLOWCHART_OBJECTIVES, {
      objectives: text,
    });
  };

  const redefine = () => {
    setText(objectives ?? '');
  };

  const handleAddSelectedKey = ({
    currentWord,
    newWord,
    position,
  }: {
    currentWord: string;
    newWord: string;
    position: number;
  }) => {
    const lines = text.split('\n');

    const updatedLines = lines.map((line, index) => {
      if (index === position) {
        return line.replace(currentWord, newWord);
      }
      return line;
    });

    const result = updatedLines.join('\n');

    setProcessedObjectives(result);
    setText(result);
  };

  return {
    keyWordsList,
    addKeyWordList,
    addObjectiveChange,
    setProcessedObjectives,
    emitObjectives,
    loadingKeyWords,
    text,
    setText,
    redefine,
    handleAddSelectedKey,
  };
}
