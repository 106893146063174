import {
  Edge,
  Node,
  NodeProps,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from '@xyflow/react';
import { ReactNode } from 'react';

export type NodeData = {
  label: string;
  objectives?: string;
};

export type FlowChartNode = Node & { data: NodeData };
export type FlowChartEdge = Edge & { data?: { label?: string } };

export type KeyWordsList = { key: string; list: string[] }[];

export type FlowChartState = {
  nodes: FlowChartNode[];
  edges: FlowChartEdge[];
  onNodesChange: OnNodesChange<FlowChartNode>;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setNodes: (nodes: FlowChartNode[]) => void;
  setEdges: (edges: Edge[]) => void;
  updateNode: (node: Partial<FlowChartNode>) => void;
  updateEdge: (node: Partial<FlowChartEdge>) => void;
  id: string;
  setId: (id: string) => void;
  resetStore: () => void;
};

export interface ShapesProps extends NodeProps<FlowChartNode> {
  children: ReactNode;
  resized: boolean;
  onResizedChange: () => void;
}

export enum FlowChartStep {
  FLOW_CHART = 'flowchart',
  CODE = 'code',
  TEST = 'test',
}
