import { Popover } from './popover.tsx';
import { ThumbsDown, ThumbsUp } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useState } from 'react';
import { useOutsideClick } from '@rangpt/hooks';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';

interface FeedbackProps {
  loading: boolean;
  feedback?: {
    text?: string;
    like?: boolean;
    dislike?: boolean;
  };
  index: number;
  feedbackActions: {
    likePrompt: (index: number) => void;
    dislikePrompt: (index: number) => void;
    writeFeedback: (value: string, index: number) => void;
  };
  fromTop: boolean;
}

const Container = ({
  loading = false,
  feedback,
  index,
  feedbackActions,
  fromTop,
}: FeedbackProps) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };

  const ref = useOutsideClick<HTMLDivElement>(close);

  if (!feedbackActions) return;
  const { likePrompt, dislikePrompt, writeFeedback } = feedbackActions;
  const feedbackPallet = feedback?.like
    ? { like: 'bg-[#09e6afeb]' }
    : feedback?.dislike
    ? { dislike: 'bg-[#e42429db]' }
    : undefined;

  const like = () => {
    if (open) {
      close();
    }
    likePrompt(index);
  };

  const dislike = () => {
    if (open) {
      close();
      return;
    }
    setOpen(true);
  };

  const submit = (value: string) => {
    writeFeedback(value, index);
    dislikePrompt(index);
  };

  return (
    <section className="flex relative gap-2 h-10" ref={ref}>
      {loading ? (
        <>
          <Skeleton
            style={{ height: '35px', width: '40px' }}
            className="fade-in"
          />
          <Skeleton
            style={{ height: '35px', width: '40px' }}
            className="fade-in"
          />
        </>
      ) : (
        <>
          <Button
            className={`p-2 px-3 flex rounded-[6px] opacity-80 ${feedbackPallet?.dislike} hover:bg-[#e42429db] fade-in border-[#494a4a]`}
            variant="outline"
            onClick={dislike}
          >
            <ThumbsDown color="#494a4a" width={14} height={14} />
          </Button>
          <Button
            className={`p-2 px-3 flex rounded-[6px] opacity-80 ${feedbackPallet?.like} hover:bg-[#09e6afeb] fade-in border-[#494a4a]`}
            variant="outline"
            onClick={like}
          >
            <ThumbsUp color="#494a4a" width={14} height={14} />
          </Button>
        </>
      )}
      {open && (
        <Popover
          close={close}
          submit={submit}
          defaultValue={feedback?.text}
          fromTop={fromTop}
        />
      )}
    </section>
  );
};

export { Container };
