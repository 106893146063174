import { useMemo, useState } from 'react';
import { RotateCw } from 'react-feather';
import { ResizablePanel } from '@rangpt/components/ui/resizable.tsx';
import { Code } from '@rangpt/components/code';
import { Header } from '@rangpt/components/code/header.tsx';
import { Markup } from '@rangpt/components/code/markup.tsx';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { PromptData } from '@rangpt/types/chat.type';
import { EmptyState } from '@rangpt/components/code/emptyState.tsx';

interface CommandInterfaceBottomProps {
  chat: PromptData[];
  currentPrompt: number;
  reRun: (index: number, code: string) => void;
  currentNode: number;
  loading: boolean;
}

export function Bottom({
  chat,
  currentPrompt,
  reRun,
  currentNode,
  loading,
}: Readonly<CommandInterfaceBottomProps>) {
  const [value, setValue] = useState('');

  const currentPromptData = chat[currentPrompt];

  const code = currentPromptData.code!;

  useMemo(() => {
    console.debug(
      '~ bottomContent.tsx: update useMemo with currentPrompt change',
      currentPrompt,
    );
    setValue('');
  }, [currentPrompt]);

  if (!loading && (!code || code.length === 0)) {
    return <EmptyState />;
  }

  const handleRerun = () => {
    if (value === code[currentNode].content) return;

    reRun(currentPrompt, value);
  };
  const updateCode = (e: string) => {
    const content = code[currentNode].content;

    if (e === content) {
      setValue('');
      return;
    }
    setValue(e);
  };

  const rerunButton = () => {
    if (!code) return null;
    if (code[currentNode]?.content === value) return null;

    if (value.length > 1) {
      return (
        <Button
          className="flex rounded-[6px] opacity-80 border-[#CBD5E1] w-10 h-10 p-2 self-center fade-in"
          variant="outline"
          onClick={handleRerun}
          disabled={loading}
        >
          <RotateCw color="#8a8a8e" width={12} height={12} />
        </Button>
      );
    }
    return null;
  };

  return (
    <ResizablePanel
      className="w-full flex basis-1/2 grow-0 overflow-hidden p-4 min-h-sm"
      defaultSize={40}
    >
      <Code.Container>
        <div className="w-full flex items-start justify-between">
          <Header title="Final Code">{rerunButton()}</Header>
        </div>

        {code ? (
          <Markup
            code={code[currentNode]}
            updateCode={updateCode}
            readOnly={false}
          />
        ) : (
          <section className="w-full h-full flex flex-col items-center justify-center gap-3 absolute">
            <img src="/favicon/favicon-32x32.png" alt="" />
            <p className="text-sm text-[#8c95a1]">Waiting for final code</p>
          </section>
        )}
      </Code.Container>
    </ResizablePanel>
  );
}
