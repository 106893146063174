import { useAuth } from '@hooks/useAuth.ts';
import { Navigate } from '@tanstack/react-router';
import { MyDashboard } from '@rangpt/pages/MyDashboard.tsx';

export const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <MyDashboard />;
};
