import { NodeProps } from '@xyflow/react';
import { FlowChartNode, ShapesProps } from '@app/types';
import { flowChartStore } from '@app/stores';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useAutosizeTextArea from '@app/hooks/useAutoResizer.ts';
import { cn } from '@app/lib/utils.ts';
import { ShapeDrawer } from '@components/ShapeDrawer';

export const ShapesHOC = (
  Element: (props: ShapesProps) => JSX.Element,
  props: NodeProps<FlowChartNode>,
) => {
  const { id, data, selected, type } = props;
  const updateNode = flowChartStore(state => state.updateNode);
  const [content, setContent] = useState(data.label);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [resized, setResized] = useState(false);

  useAutosizeTextArea(textAreaRef.current, content);

  useEffect(() => {
    setContent(data.label);
  }, [data]);

  const onResizedChange = () => {
    if (resized) return;
    setResized(true);
  };

  const debounced = useDebouncedCallback<
    ({ label, objectives }: { label?: string; objectives?: string }) => void
  >(({ label, objectives }) => {
    updateNode({
      id,
      data: {
        ...data,
        objectives: objectives ?? data.objectives,
        label: label ?? data.label,
      },
    });
  }, 1000);

  const handleLabelChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;
    setContent(value);
    debounced({ label: value });
  };

  const hasObjectives = data.objectives ? data.objectives.length > 1 : false;

  return (
    <Element {...props} resized={resized} onResizedChange={onResizedChange}>
      <ShapeDrawer.Container selected={selected}>
        <ShapeDrawer.Content
          label={data.label}
          type={type}
          debouncedChange={debounced}
          objectives={data.objectives}
        />
      </ShapeDrawer.Container>
      <section className="w-full max-w-full m-auto px-2 z-10 relative">
        <section className="w-full max-w-full flex items-center gap-1">
          {/*{hasObjectives}*/}
          <textarea
            id="review-text"
            onChange={handleLabelChange}
            className={cn(
              'w-full overflow-hidden max-h-full resize-none border-none bg-transparent outline-none focus:outline-none text-xs mx-auto box-border text-center',
              hasObjectives ? 'text-white' : 'text-dark-1',
            )}
            ref={textAreaRef}
            rows={1}
            value={content}
          />
        </section>
      </section>
    </Element>
  );
};
