import { ReactElement } from 'react';

interface CodeHeaderProps {
  title: string;
  subTitle?: string;
  children?: ReactElement | ReactElement[] | null;
}

const Header = ({
  title = 'Final Code',
  subTitle,
  children,
}: CodeHeaderProps) => {
  return (
    <div className="flex flex-row gap-3 mb-2">
      <section>
        <h2 className="text-xl">
          <span className="fade-in">{title}</span>
        </h2>
        <span className="text-xs text-gray-800 opacity-60">
          <span className="fade-in">{subTitle ?? 'Code Generation'}</span>
        </span>
      </section>
      {children}
    </div>
  );
};

export { Header };
