import { createRootRoute, Outlet } from '@tanstack/react-router';
import { Sidebar } from '@packages/ui';
import { useAuth } from '@packages/hooks';
import { useState } from 'react';
import { SidebarContent } from '@/components/SidebarContent.tsx';
import { ErrorPage } from '@/pages/ErrorPage.tsx';

export const Route = createRootRoute({
  component: Layout,
  errorComponent: ErrorPage,
});

function Layout() {
  const { logOut, isAuthenticated } = useAuth();
  const [open, setOpen] = useState(true);
  const handleSwitchMenu = () => {
    setOpen(!open);
  };

  if (!isAuthenticated) {
    // Here the only route that is accessible is the login page
    return (
      <main className="w-screen h-screen overflow-hidden">
        <Outlet />
      </main>
    );
  }

  return (
    <main className="w-screen h-screen grid main-layout overflow-hidden">
      <Sidebar handleLogOut={logOut} open={open} handleClick={handleSwitchMenu}>
        <SidebarContent isOpen={open} />
      </Sidebar>
      <div className="col-span-1 col-start-2 flex overflow-hidden">
        <Outlet />
      </div>
    </main>
  );
}
