import { useCodeStore } from '@app/stores/code.store.ts';
import { useShallow } from 'zustand/react/shallow';

export const useCodeState = () => {
  return useCodeStore(
    useShallow(state => ({
      code: state.code,
      review: state.review,
      setCode: state.setCode,
      setReview: state.setReview,
    })),
  );
};
