import { useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';

interface TiltChartProps {
  data: Array<{
    datetime: string;
    angle: number;
  }>;
}

export const TiltChartComponent = ({ data }: TiltChartProps) => {
  const chartRef = useRef<any>(null);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const chartData = useMemo(() => {
    return {
      labels: data.map(d => d.datetime.split(' ')[1].slice(0, 5)),
      datasets: [
        {
          label: 'CM (Output)',
          data: data.map(d => d.angle),
          borderColor: 'rgb(255, 159, 64)',
          borderWidth: 2,
          tension: 0.1,
          pointRadius: 3,
          fill: false,
        },
      ],
    };
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
      mode: 'nearest',
      axis: 'x',
      threshold: 10,
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          generateLabels: (_: unknown) => [
            {
              text: 'CM (Output)',
              fillStyle: 'rgb(255, 159, 64)',
              strokeStyle: 'rgb(255, 159, 64)',
              lineWidth: 2,
              hidden: false,
              // This makes it show as a line instead of a box
              pointStyle: false,
            },
          ],
        },
        onClick: null, // This disables the click functionality
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          font: {
            size: 10,
          },
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          padding: 2,
          text: 'Tilt Angle (Degrees)',
        },
        min: 3,
        max: 8,
        ticks: {
          stepSize: 1,
        },
      },
    },
    animation: {
      duration: 20000,
      x: {
        type: 'number',
        easing: 'linear',
        duration: 20000 / data.length,
        from: NaN,
        delay(ctx: any) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * (20000 / data.length);
        },
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: 20000 / data.length,
        from: (ctx: any) => {
          if (ctx.index === 0) {
            return ctx.chart.scales.y.getPixelForValue(0);
          }
          return ctx.chart
            .getDatasetMeta(ctx.datasetIndex)
            .data[ctx.index - 1]?.getProps(['y'], true).y;
        },
        delay(ctx: any) {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0;
          }
          ctx.yStarted = true;
          return ctx.index * (20000 / data.length);
        },
      },
    },
  };

  return (
    <div className="w-full p-2 px-6">
      <div
        className="relative w-full max-h-56"
        style={{ paddingTop: '20.25%' }}
      >
        <div className="absolute top-0 left-0 w-full h-full">
          <Line
            ref={chartRef}
            data={chartData}
            options={options as any}
            key="tilt-chart"
            redraw={true}
          />
        </div>
      </div>
    </div>
  );
};
