import { create } from 'zustand';
import { KeyWordsList } from '@app/types';

type LineBlock = {
  type: 'normal' | 'highlight';
  value: string;
};

export type ProcessedLine = LineBlock[];

// Full processed text is array of lines
type ProcessedObjectives = ProcessedLine[];

interface ObjectivesStoreProps {
  keyWords: KeyWordsList;
  setKeyWords: (keyWords: KeyWordsList) => void;
  loadingKeyWords: boolean;
  setLoadingKeyWords: (loading: boolean) => void;
  addKeyWordsList: (keyWordList: { key: string; list: string[] }) => void;
  processedObjectives: ProcessedObjectives;
  setProcessedObjectives: (text: string) => void;
}

const initialValues = {
  keyWords: [],
  processedObjectives: [],
  loadingKeyWords: false,
};

export const useObjectivesStore = create<ObjectivesStoreProps>((set, get) => ({
  ...initialValues,
  setKeyWords: keys => set({ keyWords: keys }),
  addKeyWordsList: keyWordList =>
    set({ keyWords: [...get().keyWords, keyWordList] }),
  setProcessedObjectives: (text: string) =>
    set(state => {
      const keyWords = state.keyWords;
      if (keyWords.length === 0) return state;

      const processedObjectives: ProcessedLine[] = [];

      console.debug(
        'Processing objectives with keywords:',
        keyWords.map(k => k.key),
      );

      // Split text into lines
      const lines = text.split('\n');

      for (const line of lines) {
        const parts: LineBlock[] = [];
        let lastIndex = 0;

        // Create regex for exact word matches
        const keywordsPattern = keyWords
          .map(k => k.key.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
          .join('|');
        const regex = new RegExp(`\\b(${keywordsPattern})\\b`, 'g');

        console.debug(`Processing line: "${line}"`);
        console.debug('Using pattern:', regex);

        let match;
        while ((match = regex.exec(line)) !== null) {
          // Add text before match
          if (match.index > lastIndex) {
            parts.push({
              type: 'normal',
              value: line.slice(lastIndex, match.index),
            });
          }

          // Add the keyword
          parts.push({
            type: 'highlight',
            value: match[0],
          });

          lastIndex = regex.lastIndex;
          console.debug(`Found match: "${match[0]}" at index ${match.index}`);
        }

        // Add remaining text
        if (lastIndex < line.length) {
          parts.push({
            type: 'normal',
            value: line.slice(lastIndex),
          });
        }

        processedObjectives.push(parts);
      }

      console.debug('Processed result:', processedObjectives);
      return { processedObjectives };
    }),
  setLoadingKeyWords: loading => set({ loadingKeyWords: loading }),
}));
