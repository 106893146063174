import { createFileRoute } from '@tanstack/react-router';
import { FlowChartContainer } from '@packages/appbuilder/pages';
import { Stepper } from '@packages/appbuilder/components/stepper/index.ts';

export const Route = createFileRoute('/app/$routine/flowchart')({
  component: () => (
    <div className="w-full h-full max-h-screen ease-resize">
      <Stepper.Container />
      <FlowChartContainer />
    </div>
  ),
});
