import { Sheet, SheetTrigger } from '@packages/ui/shadcn/ui/sheet.tsx';
import { ReactNode } from 'react';
import { PlusCircle } from 'lucide-react';

interface ShapeDrawerContainerProps {
  selected: boolean | undefined;
  children: ReactNode;
}

export const Container = ({
  selected,
  children,
}: ShapeDrawerContainerProps) => {
  return (
    <Sheet modal={false}>
      <SheetTrigger
        asChild
        className="absolute top-1 right-1 z-20 hidden aria-selected:flex"
        aria-selected={selected}
      >
        <button className="w-fit h-fit text-white rounded-full">
          <PlusCircle width={14} height={14} fill="#404040" />
        </button>
      </SheetTrigger>
      {children}
    </Sheet>
  );
};
