import typingAnimation from '@rangpt/assets/lottie/is-typing.json';
import { formattedDate, removeFirstAndLastLine } from '@rangpt/lib/utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { python } from '@codemirror/lang-python';
import { githubLight } from '@rangpt/styles/codeTheme.ts';
import CodeMirror from '@uiw/react-codemirror';

interface MessageProps {
  timestamp: number;
  text: string;
  isLoading: boolean | undefined;
  isBlocked?: boolean;
}

function isCode(text: string) {
  return String(text).startsWith('```');
}

const Answer = ({ timestamp, text, isLoading, isBlocked }: MessageProps) => {
  return !isLoading ? (
    <div className="w-full p-2 my-1 flex flex-col justify-start items-start gap-3">
      <div className="flex flex-row items-end gap-1">
        <img
          src="/favicon/favicon-32x32.png"
          alt=""
          className={`w-5 h-5 ${isBlocked && 'blink'}`}
        />
        <span className="text-xs text-[#8c95a1]">{formattedDate(timestamp)}</span>
      </div>
      <section
        className={`flex flex-col justify-start items-start gap-22 ${
          isCode(text) ? 'w-full' : 'w-[90%]'
        }`}
      >
        {!isCode(text) ? (
          <p
            className={`text-sm text-[#000] fadein ml-[24px] whitespace-pre-line ${
              isBlocked && 'text-[#8c95a1]'
            }`}
          >
            {text}
          </p>
        ) : (
          <div className="w-full px-2">
            <section className="rounded-[12px] fadein relative w-full text-xs">
              <CodeMirror
                key={timestamp}
                value={removeFirstAndLastLine(text)}
                extensions={[python()]}
                theme={githubLight}
                basicSetup={{ lineNumbers: false, foldGutter: false }}
                readOnly={true}
              />
            </section>
          </div>
        )}
      </section>
    </div>
  ) : (
    <div className="w-full p-2 my-1 flex flex-col justify-start items-start gap-3">
      <div className="flex flex-row justify-end gap-1">
        <img src="/favicon/favicon-32x32.png" alt="" className="w-5 h-5" />
        <Player autoplay loop src={typingAnimation} style={{ height: '20px', width: '30px' }} />
      </div>
    </div>
  );
};

export { Answer };
