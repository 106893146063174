import { useState } from 'react';
import { WS_URL } from '@rangpt/types/config.ts';
import { Chart } from '@rangpt/types/dashboard.types.ts';
import { ChartType } from '@packages/api';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { ErrorLoadingImage } from '@rangpt/components/dashboard/ErrorLoadingImage.tsx';
import { LoadingImage } from '@rangpt/components/dashboard/LoadingImage.tsx';
import { DashChartOptions } from '@rangpt/components/dashboard/DashChartOptions.tsx';

interface ChartsListProps {
  dashboard: Chart[];
  deleteGraph: (index: number) => void;
}

export const ChartList = ({ dashboard, deleteGraph }: ChartsListProps) => {
  const [loadedImages, setLoadedImages] = useState<Record<string, boolean>>({});
  const [errorImages, setErrorImages] = useState<Record<string, boolean>>({});

  const handleImageLoad = (id: string) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true,
    }));
  };

  const handleImageError = (id: string) => {
    setErrorImages(prev => ({
      ...prev,
      [id]: true,
    }));
    setLoadedImages(prev => ({
      ...prev,
      [id]: false,
    }));
  };

  return (
    <div className="w-full h-full flex flex-wrap gap-8 items-start justify-start overflow-y-scroll relative">
      {dashboard.map((graph, index) => {
        const handleDelete = () => {
          deleteGraph(index);
        };

        return (
          <section
            className="max-w-3xl min-w-2xl w-2xl h-[30rem] relative group bg-white p-6 rounded-3xl shadow-sm"
            key={graph.id}
          >
            <DashChartOptions code={graph.code} handleDelete={handleDelete} />

            {errorImages[graph.id] && <ErrorLoadingImage />}

            {!loadedImages[graph.id] && !errorImages[graph.id] && (
              <LoadingImage />
            )}

            {graph.type === ChartType.HTML ? (
              <iframe
                title="html map"
                className={cn(
                  'min-w-xl w-full h-full',
                  loadedImages[graph.id] ? 'opacity-100' : 'opacity-0',
                )}
                src={`${WS_URL}/charts/${graph.id}.html`}
                onLoad={() => handleImageLoad(graph.id)}
                onError={() => handleImageError(graph.id)}
              ></iframe>
            ) : (
              <img
                src={`${WS_URL}/charts/${graph.id}.png`}
                alt={graph.id}
                className={cn(
                  'w-full max-h-full m-auto',
                  loadedImages[graph.id] ? 'opacity-100' : 'opacity-0',
                )}
                onLoad={() => handleImageLoad(graph.id)}
                onError={() => handleImageError(graph.id)}
              />
            )}
          </section>
        );
      })}
    </div>
  );
};
