import {
  ExpectedTablePayload,
  SaveCodePayload,
  TestTabPayload,
} from '@api/types';
import { API_URL, token } from '@api/configs.ts';

export class TestClient {
  private readonly defaultHeaders: HeadersInit;

  constructor() {
    this.defaultHeaders = {
      authorization: token,
      'Content-Type': 'application/json',
    };
  }

  public async saveTestCode(payload: SaveCodePayload) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/app/code`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error: any) {
      throw new Error(`Failed to save flowchart code: ${error.message}`);
    }
  }

  public async saveTestTab(payload: TestTabPayload) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/app/test`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error: any) {
      throw new Error(`Failed to save test tab: ${error.message}`);
    }
  }

  public async saveExpectedTable(payload: ExpectedTablePayload) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/app/test/expected`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error: any) {
      throw new Error(`Failed to save test tab: ${error.message}`);
    }
  }

  private async fetchWithErrorHandling(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
}
