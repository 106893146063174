import { Edit3, MoreVertical, Trash2 } from 'react-feather';
import { useState } from 'react';
import { useOutsideClick } from '@app/hooks';
import { CreateAppIcon } from '@components/icons/createApp.icon.tsx';
import { UseMutationResult } from '@tanstack/react-query';
import { RoutineRes } from '@packages/api';
import { HomeComponents } from '@components/home/index.ts';
import { ModalButton } from '@components/ui/ModalButton.tsx';

interface RoutineOptionsProps {
  id: string;
  name: string;
  selectRoutine: UseMutationResult<RoutineRes, Error, string, unknown>;
  changeRoutineName: (id: string, name: string) => void;
  deleteRoutine: UseMutationResult<
    { message: string; id: string },
    Error,
    string
  >;
  loading: boolean;
}

export const Options = ({
  id,
  name,
  selectRoutine,
  changeRoutineName,
  loading,
  deleteRoutine,
}: RoutineOptionsProps) => {
  const [open, setOpen] = useState(false);

  const handleSelectRoutine = () => selectRoutine.mutate(id);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    deleteRoutine.mutate(id);
  };

  const changeName = (value: string) => changeRoutineName(id, value);

  const handleClose = () => setOpen(false);

  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClose);

  return (
    <div className="relative" ref={outsideClickRef}>
      <button
        className="w-fit bg-transparent p-0 outline-none cursor-pointer"
        onClick={toggleOpen}
      >
        <MoreVertical width={22} height={22} />
      </button>
      <section
        className="absolute flex flex-col w-fit p-2 aria-hidden:hidden gap-1 bg-white items-center justify-center rounded-md shadow-md left-2 z-40"
        aria-hidden={!open}
      >
        <ModalButton onClick={handleSelectRoutine}>
          <CreateAppIcon width={16} height={16} fill="#151515" />
          Edit routine
        </ModalButton>
        <HomeComponents.RenameModal
          onConfirm={changeName}
          name={name}
          loading={loading}
        >
          <ModalButton>
            <Edit3 width={16} height={16} />
            Rename
          </ModalButton>
        </HomeComponents.RenameModal>

        {/* @TODO: We need to change this for the new status */}
        {/*{disabled ? (*/}
        {/*  <Button onClick={handleRoutineStatus}>*/}
        {/*    <Play width={16} height={16} />*/}
        {/*    Enable*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  <Button onClick={handleRoutineStatus}>*/}
        {/*    <X width={16} height={16} />*/}
        {/*    Disabled*/}
        {/*  </Button>*/}
        {/*)}*/}
        <HomeComponents.DeleteModal
          onConfirm={handleDelete}
          name={name}
          loading={deleteRoutine.isPending}
        >
          <ModalButton>
            <Trash2 width={16} height={16} /> Delete
          </ModalButton>
        </HomeComponents.DeleteModal>
      </section>
    </div>
  );
};
