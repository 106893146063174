import { useEffect, useRef } from 'react';

const useOutsideClick = <T>(callback: () => void) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // @ts-ignore
       
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.addEventListener('click', handleClick);
  }, [ref]);

  return ref;
};

export { useOutsideClick };
