import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthUser, validateToken } from '@hooks/utils/validateToken.ts';

export interface AuthContextState {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  logOut: () => void;
  currentUser: AuthUser;
  setCurrentUser: (user: AuthUser) => void;
  loadingTokenUser: boolean;
}

export const AuthContext = createContext<AuthContextState>({
  isAuthenticated: false,
  setIsAuthenticated: () => null,
  logOut: () => null,
  currentUser: null,
  setCurrentUser: () => {},
  loadingTokenUser: false,
});

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
  isAdmin: boolean;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<AuthUser>(null);
  const [loadingTokenUser, setLoadingTokenUser] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    (async () => {
      const timer = Date.now();

      const data = await validateToken({
        setLoadingTokenUser,
        token,
      });

      if (!data || !token) {
        localStorage.removeItem('token');
        setLoadingTokenUser(false);
        setIsAuthenticated(false);
        return;
      }

      setCurrentUser({
        name: data.username,
        isAdmin: data.isAdmin,
        sessionToken: token,
        owner: data.owner!.id,
      });

      // Calculate time difference
      const timeDiff = Date.now() - timer;
      const minimumTime = 1500;

      if (timeDiff >= minimumTime) {
        setLoadingTokenUser(false);
        setIsAuthenticated(true);
      } else {
        // wait 1s
        await new Promise(resolve =>
          setTimeout(resolve, minimumTime - timeDiff),
        );
        setLoadingTokenUser(false);
        setIsAuthenticated(true);
      }
    })();
  }, []);

  const logOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('companyHash');
    localStorage.removeItem('token');
  };

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      logOut,
      currentUser,
      setCurrentUser,
      loadingTokenUser,
    }),
    [isAuthenticated, currentUser, loadingTokenUser, setCurrentUser],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
