import { create } from 'zustand';
import { RowStatus, TestTable } from '@app/types';

interface UpdateRowItemValue {
  fieldName: string;
  rowIndex: number;
  newContent: string;
}

export enum TABLES {
  EXPECTED = 'expected',
  INITIAL = 'initial',
  RESULT = 'result',
}

export interface TestStore {
  tables: {
    expected: TestTable | null;
    initial: TestTable | null;
    result: TestTable | null;
  };
  matches: RowStatus[];
  setTables: (table: TABLES, data: TestTable | null) => void;
  runMatchesTest: (table: TestTable) => void;
  addRow: () => void;
  updateRowItemValue: ({
    fieldName,
    rowIndex,
    newContent,
  }: UpdateRowItemValue) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  loadingResult: boolean;
  setLoadingResult: (loading: boolean) => void;
}

const initialState = {
  tables: {
    expected: null,
    initial: null,
    result: null,
  },
  matches: [],
  loading: false,
  loadingResult: false,
};

export const useTestStore = create<TestStore>((set, get) => ({
  ...initialState,
  setTables: (table, data) =>
    set({ tables: { ...get().tables, [table]: data } }),
  setLoading: loading => set({ loading }),
  setLoadingResult: loadingResult => set({ loadingResult: loadingResult }),
  runMatchesTest: newTable =>
    set(() => {
      // Only expected table will be updated
      const expectedTable = get().tables.expected;
      if (!expectedTable)
        return {
          tables: {
            ...get().tables,
            expected: {
              ...newTable,
              matches: [],
              actions: [],
            },
          },
        };

      const commonFields = Object.keys(expectedTable.fields).filter(key =>
        Object.prototype.hasOwnProperty.call(newTable.fields, key),
      );

      const updatedFields = { ...expectedTable.fields };

      commonFields.forEach(field => {
        updatedFields[field] = expectedTable.fields[field].map(
          (item, index) => {
            if (index < newTable.fields[field].length) {
              return {
                content: item.content,
                match: item.content === newTable.fields[field][index].content,
              };
            }
            return item; // Keep original for extra items in current table
          },
        );
      });

      const newMatches = updatedFields[commonFields[0]].map((_, rowIndex) => {
        if (rowIndex >= newTable.fields[commonFields[0]].length) {
          return { row: rowIndex, status: 'pending' as const };
        }

        const rowMatches = commonFields.every(
          field => updatedFields[field][rowIndex].match === true,
        );

        return { row: rowIndex, status: rowMatches };
      });

      return {
        tables: {
          ...get().tables,
          expected: {
            ...expectedTable,
            fields: updatedFields,
          },
        },
        matches: newMatches,
      };
    }),
  addRow: () =>
    set(state => {
      const expectedTable = get().tables.expected;

      if (!expectedTable) return state; // If there's no table, we can't add a row

      const updatedFields = { ...expectedTable.fields };

      Object.keys(updatedFields).forEach(key => {
        updatedFields[key] = [
          ...updatedFields[key],
          {
            content: '',
            match: null,
          },
        ];
      });

      // Update matches array
      const newMatches = [
        ...get().matches,
        {
          row: get().matches.length,
          status: 'pending',
        } as const,
      ];

      return {
        tables: {
          ...get().tables,
          expected: {
            ...expectedTable,
            fields: updatedFields,
          },
        },
        matches: newMatches,
      };
    }),
  updateRowItemValue: ({ fieldName, rowIndex, newContent }) =>
    set(state => {
      console.debug('is updating');
      const expectedTable = get().tables.expected;
      if (!expectedTable || !expectedTable.fields[fieldName]) return state;

      const updatedFields = { ...expectedTable.fields };
      const fieldArray = [...updatedFields[fieldName]];

      if (rowIndex < 0 || rowIndex >= fieldArray.length) return state;

      fieldArray[rowIndex] = {
        ...fieldArray[rowIndex],
        content: newContent,
        match: null, // Reset match status when content is updated
      };

      updatedFields[fieldName] = fieldArray;

      const updatedMatches = [...get().matches];
      if (updatedMatches[rowIndex]) {
        updatedMatches[rowIndex] = {
          ...updatedMatches[rowIndex],
          status: 'pending', // Set the corresponding match to 'pending'
        };
      }

      return {
        tables: {
          ...get().tables,
          expected: {
            ...expectedTable,
            fields: updatedFields,
          },
        },
        matches: updatedMatches,
      };
    }),
}));
