import { useEffect, useState } from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { DataPoint } from './types.ts';
import { TiltChartComponent } from '@components/TestTab/chart/TiltChart.tsx';
import ChartComponent from '@components/TestTab/chart/ChartComponent.tsx';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const ChartContainer = () => {
  const [data, setData] = useState<DataPoint[]>([]);
  const [tiltData, setTiltData] = useState<
    Array<{ datetime: string; angle: number }>
  >([]);
  const [tooltipInfo, setTooltipInfo] = useState<{
    show: boolean;
    x: number;
    y: number;
    content: string;
  }>({ show: false, x: 0, y: 0, content: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch main chart data
        const response = await fetch('/test-data-sheet2.csv');
        const csvText = await response.text();

        const parsedData = csvText
          .split('\n')
          .slice(1)
          .map(row => {
            const [prbutildl, uethpdl, rrcmeanconn, datetime] = row.split(',');
            const time = datetime.split(' ')[1].slice(0, 5);
            return {
              time,
              prbutildl: parseFloat(prbutildl),
              uethpdl: parseFloat(uethpdl),
              rrcmeanconn: parseInt(rrcmeanconn),
            };
          });

        setData(parsedData);

        // Fetch tilt angle data
        const tiltResponse = await fetch('/tilt-angle.csv');
        const tiltCsvText = await tiltResponse.text();

        const parsedTiltData = tiltCsvText
          .split('\n')
          .slice(1)
          .map(row => {
            const [datetime, angle] = row.split(',');
            return {
              datetime,
              angle: parseFloat(angle),
            };
          });

        setTiltData(parsedTiltData);
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full max-h-full max-w-[80rem] flex flex-col items-start justify-center relative overflow-scroll bg-white rounded-lg shadow-lg">
      {data.length > 0 && (
        <ChartComponent data={data} setTooltipInfo={setTooltipInfo} />
      )}
      {tiltData.length > 0 && <TiltChartComponent data={tiltData} />}
      {tooltipInfo.show && (
        <div
          className="absolute bg-white p-2 rounded shadow-lg border border-gray-200 max-w-56 text-xs"
          style={{
            left: tooltipInfo.x,
            top: tooltipInfo.y - 30,
            zIndex: 1000,
            pointerEvents: 'none',
          }}
        >
          {tooltipInfo.content}
        </div>
      )}
    </div>
  );
};
