import { HTMLAttributes, ReactNode, useRef } from 'react';
import { Button } from '@shadcn/ui/button.tsx';
import { ChevronLeft, ChevronRight, LogOut } from 'react-feather';
import airaTextLogo from '@public/aira-main-logo.png';
import airaNoTextLogo from '@public/aira-no-text-logo.png';
import { cn } from '@shadcn/lib/utils.ts';
import { LogOutModal } from '@ui/sidebar/logOutModal.tsx';

interface SideBarProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ReactNode[];
  handleLogOut: () => void;
  open: boolean;
  handleClick: () => void;
}

/*
 * AIRA Default Sidebar
 * It got be used with a 2 column grid layout, it will occupy first column
 * */
export const Sidebar = ({
  children,
  className,
  handleClick,
  handleLogOut,
  open,
}: SideBarProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const closeSidebar = () => {
    if (ref.current) {
      handleClick();
      if (open) {
        ref.current.style.width = '7rem';
        return;
      }
      ref.current.style.width = '17rem';
    }
  };

  return (
    <div
      className={cn(
        'col-span-1 col-start-1 min-w-6 h-100 shadow-xl z-20 flex flex-col items-start justify-between gap-4 w-64 max-w-72 py-4 px-2 ease-resize overflow-hidden',
        className,
      )}
      ref={ref}
    >
      {/* Using aria-checked as a prop to open or closed */}
      <section
        className={cn(
          'w-full py-6 px-0 flex items-center justify-between self-start',
          open && 'pl-2',
        )}
      >
        {open ? (
          <>
            <img src={airaTextLogo} alt="" className="w-40 h-8" />
            <Button
              variant="outline"
              className="bg-transparent border-none"
              onClick={closeSidebar}
            >
              <ChevronLeft width={18} height={18} />
            </Button>
          </>
        ) : (
          <button
            onClick={closeSidebar}
            className="p-0 border-none w-full fade-in flex gap-1 items-center justify-between"
          >
            <img src={airaNoTextLogo} alt="" className="w-16 h-6" />
            <ChevronRight width={20} height={20} />
          </button>
        )}
      </section>
      <section
        className={cn(
          'w-full h-full flex flex-col items-center justify-start pt-5 px-0 gap-3',
          open && 'px-2 pr-4',
        )}
      >
        {children instanceof Array ? children.map(child => child) : children}
      </section>

      <LogOutModal onConfirm={handleLogOut}>
        <Button
          variant="ghost"
          aria-checked={open}
          className="flex w-full items-center gap-3 justify-center border border-black hover:underline pl-2 hover:bg-transparent transition-all aria-checked:justify-start aria-checked:border-none"
        >
          <LogOut
            width={17}
            height={17}
            aria-checked={open}
            className="fade-in ml-2 aria-checked:ml-0"
          />
          {open ? 'Logout' : null}
        </Button>
      </LogOutModal>
    </div>
  );
};
