import { Handle, NodeResizer, Position } from '@xyflow/react';
import { ShapesProps } from '@app/types';
import { useEffect, useRef } from 'react';
import { cn } from '@app/lib/utils.ts';

export const DiamondShape = ({
  selected,
  children,
  data,
  resized,
  onResizedChange,
}: ShapesProps) => {
  const hasObjectives = data.objectives ? data.objectives.length > 1 : false;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = 'transparent';
    ctx.fillRect(0, 0, width, height);

    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 5;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0.5;

    ctx.fillStyle = hasObjectives ? '#404040' : '#fff'; // Color of the diamond
    ctx.beginPath();
    ctx.moveTo(width / 2, 0); // Top
    ctx.lineTo(width, height / 2); // Right
    ctx.lineTo(width / 2, height); // Bottom
    ctx.lineTo(0, height / 2); // Left
    ctx.closePath();
    ctx.fill();
  }, [hasObjectives]);

  return (
    <section
      className={cn(
        'flex min-w-[122px] min-h-[122px] bg-transparent rounded-md relative',
        resized ? 'w-full h-full' : 'w-[122px] h-[122px]',
      )}
    >
      <canvas
        ref={canvasRef}
        className={cn('block absolute top-0 my-0 mx-auto w-full h-full')}
        style={{ fill: hasObjectives ? '#404040' : '#fff' }}
      />
      <NodeResizer
        color="#ff0071"
        isVisible={selected}
        minWidth={122}
        minHeight={122}
        maxHeight={240}
        maxWidth={240}
        handleClassName="!w-2 !h-2"
        onResize={onResizedChange}
        keepAspectRatio
      />
      {children}
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" />
      <Handle type="target" position={Position.Top} id="top-target" />
      <Handle type="target" position={Position.Bottom} id="bottom-target" />
      <Handle type="source" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Top} id="top" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
    </section>
  );
};
