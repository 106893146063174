import { Bounce, ToastOptions } from 'react-toastify';

export const API_URL: string = import.meta.env.VITE_API_URL;

export const WS_URL: string = import.meta.env.VITE_WS_URL;


export const defaultLightToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light',
  transition: Bounce,
}

export const defaultToastErrorToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light',
  transition: Bounce,
}
