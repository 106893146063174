import { User } from '@packages/api';
import { FormatDateUtils } from '@management/utils/formatDate.ts';
import { TableCell, TableRow } from '@packages/ui/shadcn/ui/table.tsx';
import { Switch } from '@packages/ui/shadcn/ui/switch.tsx';
import { Edit3 } from 'react-feather';
import { AccManagement } from '@management/components/AccountManagement/index.ts';

export const EditUser = () => {
  return (
    <button
      type="button"
      className="border-0 shadow-md rounded-md p-3 hover:cursor-pointer"
    >
      <Edit3 width={12} height={12} />
    </button>
  );
};

export const UserRow = ({
  user,
  onChecked,
  usersActives,
}: {
  user: User;
  onChecked: (username: string) => void;
  usersActives: number;
}) => {
  const formatDate = new FormatDateUtils();

  const handleSwitch = () => {
    onChecked(user.id!);
  };

  return (
    <TableRow>
      <TableCell className="px-0">{user.username}</TableCell>
      <TableCell className="max-w-12 px-0">
        {formatDate.YYYYMMDD(user.createdAt)}
      </TableCell>
      <TableCell className="flex h-full justify-end pr-8 items-center">
        <Switch
          className="data-[state=unchecked]:bg-light-1 data-[state=checked]:bg-green-500 h-4 w-[2.3rem] data-[state=checked]:shadow-inner mt-1"
          id={user.username}
          checked={user.isActive}
          onCheckedChange={handleSwitch}
          disabled={usersActives >= 5 && !user.isActive}
        />
      </TableCell>
      <TableCell className="w-fit max-w-6 pl-8 pr-0 py-1">
        <AccManagement.Modal
          type="UPDATE"
          trigger={<EditUser />}
          userData={user}
        />
      </TableCell>
    </TableRow>
  );
};
