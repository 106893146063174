import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { FlowChartNode, TestTable } from '@app/types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateShapeId(arr: FlowChartNode[]) {
  if (arr.length === 0) return '1';

  return String(Number(arr[arr.length - 1].id) + 1);
}

export function countLines(text: string) {
  // count the number of elements
  const lines = text.split('\n');
  return lines.length;
}

export function getTimeAgo(timestamp: number) {
  const now = Date.now();
  const past = timestamp < 1e12
    ? timestamp * 1000  // Convert seconds to milliseconds
    : new Date(timestamp).getTime();

  const diffInSeconds = Math.floor((now - past) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
  ];

  for (const element of intervals) {
    const interval = element;
    const count = Math.floor(diffInSeconds / interval.seconds);

    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }

  return 'Just now';
}

/**
 * Creates an array of numbers from 0 to n-1.
 * @param n The length of the array to create.
 * @returns An array of numbers from 0 to n-1.
 */
export function createIndexArray(n: number): number[] {
  return Array.from({ length: n }, (_, index) => index);
}

/**
 * Gets the row count from the initial table data.
 * @param initialTable The initial table data.
 * @param fieldNames Array of field names.
 * @returns The number of rows in the table.
 */
export function getRowCount(initialTable: TestTable, fieldNames: string[]): number {
  if (!initialTable?.fields || fieldNames.length === 0) return 0;
  return initialTable.fields[fieldNames[0]].length;
}

/**
 * Creates an array of row indices based on the initial table data.
 * @param initialTable The initial table data.
 * @param fieldNames Array of field names.
 * @returns An array of row indices.
 */
export function createRowIndices(initialTable: TestTable, fieldNames: string[]): number[] {
  const rowCount = getRowCount(initialTable, fieldNames);
  return createIndexArray(rowCount);
}
