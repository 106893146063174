import { DefaultModal } from '@components/ui/DefaultModal.tsx';
import { ReactNode } from 'react';

export const DeleteModal = ({
  onConfirm,
  children,
  name,
  loading,
}: {
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  name: string;
  loading: boolean;
}) => {
  return (
    <DefaultModal
      trigger={children}
      onConfirm={onConfirm}
      title={`Delete: ${name}`}
      confirmText="Delete"
      loading={loading}
    >
      <p>Are you sure you want to delete this routine?</p>
    </DefaultModal>
  );
};
