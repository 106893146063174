import { Checkbox } from '@packages/ui/shadcn/ui/checkbox.tsx';
import { useDebouncedCallback } from 'use-debounce';

interface TablesContentProps {
  item: string;
  table: string;
  isChecked: boolean;
  checkColumn: ({
    tableName,
    itemName,
    isSelected,
  }: {
    tableName: string;
    itemName: string;
    isSelected: boolean;
  }) => void;
  description?: string;
  changeItemDescription: (
    tableName: string,
    itemName: string,
    value: string,
  ) => void;
}

export const TableItem = ({
  item,
  table,
  checkColumn,
  isChecked,
  description,
  changeItemDescription,
}: TablesContentProps) => {
  const handleChecked = (e: boolean | 'indeterminate') => {
    if (e === 'indeterminate') return;

    checkColumn({ tableName: table, itemName: item, isSelected: e });
  };

  const debouncedUpdateDescription = useDebouncedCallback((value: string) => {
    changeItemDescription(table, item, value);
  }, 400);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedUpdateDescription(e.target.value);
  };

  return (
    <div className="w-full flex items-center border-b border-gray-200 last:border-b-0">
      <div className="min-w-[50px] p-4 flex items-center justify-center border-r border-gray-200">
        <Checkbox
          id={item}
          defaultChecked={isChecked}
          checked={isChecked}
          onCheckedChange={handleChecked}
        />
      </div>

      <div className="min-w-fit w-72 overflow-x-auto p-4 border-r border-gray-200">
        <label
          htmlFor={item}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {item}
        </label>
      </div>

      <div className="flex-1 p-4">
        <input
          defaultValue={description}
          onChange={handleChangeValue}
          type="text"
          className="w-full appearance-none bg-transparent border-none focus:outline-none focus:ring-0 p-0 m-0 text-inherit font-inherit"
          placeholder="Enter description"
        />
      </div>
    </div>
  );
};


// <EditDescModal
//   desc={description ?? ''}
//   onConfirm={handleChangeItemDescription}
//   loading={false}
// >
//   <button onClick={e => e.stopPropagation()}>
//     <Edit width={14} height={14} />
//   </button>
// </EditDescModal>

// <TooltipProvider>
//   <Tooltip>
//     <TooltipTrigger asChild>
//       <Info width={14} height={14} />
//     </TooltipTrigger>
//     <TooltipContent className="flex items-center justify-center gap-2">
//       {description ?? 'No description'}
//     </TooltipContent>
//   </Tooltip>
// </TooltipProvider>
