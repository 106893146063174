export async function historyCaller<Res>({
  url,
  opt,
}: {
  url: string;
  opt?: RequestInit;
}): Promise<Res | Record<string, string>> {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  AbortSignal.timeout ??= function timeout(ms) {
    const ctrl = new AbortController();
    setTimeout(() => ctrl.abort(), ms);
    return ctrl.signal;
  };

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await fetch(url, {
      ...opt,
      headers: {
        // @TODO: add auth login received jwt
        Authorization: localStorage.getItem('session_token') ?? '',
        'Content-Type': 'application/json',
        ...opt?.headers,
      },
      signal: AbortSignal.timeout(20000),
    }).then(res => {
      if (res.status === 200) return res.json();
      // @TODO: Logout if invalid token provided
      // if(res.status === 401) {
      //
      //
      // }

      return null;
    });
  } catch (e) {
    //@TODO: Add alert of error saving history
    console.debug(e);

    if (e instanceof Error) {
      return {
        message: e.message,
      };
    }
    return {};
  }
}
