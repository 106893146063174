import { Chart } from '@api/utils.ts';

export interface ClientInfo {
  id: string;
  username: string;
  pubKey: string;
  database: clientDatabase[];
  tables: tables[];
  features: FeatureList[];
  memoryList: string[];
  sessions?: Record<string, string>;
  prompts: Record<string, string>;
}

export type UpdateClientPayload = Partial<{
  id: string;
  username: string;
  features: FeatureList[];
  database: clientDatabase[];
  tables: tables[];
  memoryList: string[];
}>;

export enum FEATURE_TYPE {
  TOOLS = 'TOOLS',
  APP_BUILDER = 'APP_BUILDER',
  TOOL_CREATOR = 'TOOL_CREATOR',
  IMAGE = 'IMAGE',
  INSIGHTS = 'INSIGHTS',
  DASHBOARD = 'DASHBOARD',
}

export interface FeatureList {
  type: FEATURE_TYPE;
  isActive: boolean;
  id: number;
}

export interface Features {
  id?: number;
  type: FEATURE_TYPE;
  limit?: number;
  isActive?: boolean;
}

/*
 * @ Form that has been saved in client history
 * Example:
 * {
 *   database_form_name: "User Form",
 *   username: "john_doe",
 *   email: "john@example.com"
 * }
 */
export type clientDatabase = { database_form_name: string } & Record<
  string,
  string
>;

// @TODO: Table name could change to table_ref
/*
 * @ Tables saved from client history
 * Example:
 * {
 *   table_name: { name: "users", description: "User information" },
 *   username: { description: "User's login name", isSelected: true },
 *   email: { description: "User's email address", isSelected: false }
 * }
 */
export type tables = {
  table_name: {
    name: string;
    description?: string;
  };
} & Record<
  string,
  {
    description?: string;
    isSelected: boolean;
  }
>;

interface ColumnInfo {
  name: string;
  description?: string;
}

export type TablesToSendToWs = {
  [key: string]: { type: string; tables: Record<string, ColumnInfo[]> };
};

/*
 * @ Client form structure
 * Example:
 * {
 *   users: {
 *     username: "john_doe",
 *     email: "john@example.com"
 *   },
 *   products: {
 *     name: "Laptop",
 *     price: "999.99"
 *   }
 * }
 */
export type clientForm = Record<string, Record<string, string>>;

export type WsTables = Record<string, string[]>;

/*
 * @ Form that we receive from WS (Web Service)
 * Example:
 * {
 *   users: [
 *     { field: "username", tooltip: "Enter your username" },
 *     { field: "email", tooltip: "Enter your email address" }
 *   ],
 *   products: [
 *     { field: "name", tooltip: "Enter product name" },
 *     { field: "price", tooltip: "Enter product price" }
 *   ]
 * }
 */
export type defaultForm = Record<string, { field: string; tooltip: string }[]>;

type RowAction = {
  row: number;
  content: string[];
};

export type TestTabTable = {
  actions: RowAction[];
  fields: Record<
    string,
    {
      content: string;
      match: boolean | null;
    }[]
  >;
};

export interface TestTabPayload {
  id: string;
  initialTable: TestTabTable;
  expectedTable: TestTabTable | null;
  resulTable?: null;
  routineId: string;
}

export interface ExpectedTablePayload {
  tableId: string;
  columns: string[];
  cells: {
    content: string;
    column: string;
  }[];
}

export interface SaveCodePayload {
  id: string;
  code: string;
  routineId: string;
}

export interface DashboardPayload {
  charts: Chart[];
}
