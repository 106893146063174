import { API_URL, token } from '@api/configs.ts';
import { Chart } from '@api/utils.ts';

export class DashboardClient {
  private readonly defaultHeaders: HeadersInit;

  constructor(userToken?: string) {
    this.defaultHeaders = {
      authorization: userToken ?? token,
      'Content-Type': 'application/json',
    };
  }

  public async saveChart(chart: Chart) {
    return await this.fetchWithErrorHandling(`${API_URL}/dashboard`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify(chart),
    });
  }

  public async deleteDashboardChart(id: string) {
    return await this.fetchWithErrorHandling(
      `${API_URL}/dashboard/charts/${id}`,
      {
        method: 'DELETE',
        headers: this.defaultHeaders,
      },
    );
  }

  private async fetchWithErrorHandling(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      const result: { error: string } = await response.json();
      throw new Error(result.error);
    }
    return response.json();
  }
}
