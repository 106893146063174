import { CSSProperties } from 'react';

export const CreateAppIcon = ({ width, height, fill }: CSSProperties) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99935 14.3443C2.71068 14.3443 1.66602 13.2996 1.66602 12.011C1.66602 10.7223 2.71068 9.67764 3.99935 9.67764C5.05602 9.67764 5.94868 10.3803 6.23601 11.3443H9.99935V10.011H11.3327V6.17231L9.83735 4.67764H5.99935V6.01097H1.99935V2.01097H5.99935V3.34431H9.83735L11.9993 1.18164L14.828 4.01097L12.666 6.17164V10.011H13.9993V14.011H9.99935V12.6776H6.23601C5.94935 13.6416 5.05602 14.3443 3.99935 14.3443ZM3.99935 11.011C3.44735 11.011 2.99935 11.459 2.99935 12.011C2.99935 12.563 3.44735 13.011 3.99935 13.011C4.55135 13.011 4.99935 12.563 4.99935 12.011C4.99935 11.459 4.55135 11.011 3.99935 11.011ZM12.666 11.3443H11.3327V12.6776H12.666V11.3443ZM11.9993 3.06831L11.0567 4.01097L11.9993 4.95364L12.942 4.01097L11.9993 3.06831ZM4.66602 3.34431H3.33268V4.67764H4.66602V3.34431Z"
      fill={fill}
    />
  </svg>
);
