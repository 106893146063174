import { tables } from '@packages/api';

export function mergeObjects(
  obj1: Record<string, string[]>,
  obj2: Record<string, string[]>,
): Record<string, Record<string, boolean>> {
  const result: Record<string, Record<string, boolean>> = {};

  // Process obj1 (base case)
  for (const [key, value] of Object.entries(obj1)) {
    result[key] = {};
    for (const person of value) {
      result[key][person] = false;
    }
  }

  // Process obj2 (marked cases)
  for (const [key, value] of Object.entries(obj2)) {
    if (!(key in result)) {
      result[key] = {};
    }
    for (const person of value) {
      result[key][person] = true;
    }
  }

  return result;
}

export const mergeCSVTablesToTables = (
  existingTables: tables[],
  newTables: Record<
    string,
    {
      description?: string;
      isSelected: boolean;
    }
  >[],
) => {
  return existingTables.map(existingTable => {
    const matchingNewTable = newTables.find(
      newTable => newTable.name === existingTable.name,
    );

    if (matchingNewTable) {
      return {
        ...existingTable,
      };
    }

    return existingTable;
  });
};

export function separateMerged(
  mergedObjects: Record<string, Record<string, boolean>>,
): {
  obj1: Record<string, string[]>;
  obj2: Record<string, string[]>;
} {
  const obj1: Record<string, string[]> = {};
  const obj2: Record<string, string[]> = {};

  for (const [key, value] of Object.entries(mergedObjects)) {
    obj1[key] = [];

    for (const [subKey, isMarked] of Object.entries(value)) {
      // Always add to obj1 (base object)
      obj1[key].push(subKey);

      // Only add to obj2 if marked as true
      if (isMarked) {
        if (!obj2[key]) obj2[key] = [];
        obj2[key].push(subKey);
      }
    }

    // Remove empty arrays from obj2 only
    if (obj2[key] && obj2[key].length === 0) delete obj2[key];
  }

  return { obj1, obj2 };
}
