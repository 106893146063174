import { InsightsObj, Tools } from '@rangpt/types';
import { create } from 'zustand';
import { SessionProps } from '@rangpt/stores/useSocketStore.ts';

interface SessionStore {
  tools: Tools | null;
  setTools: (tools: string) => void;
  insights: Record<string, InsightsObj[]> | null;
  setInsights: (insights: any) => void;
  session: SessionProps;
  clearSessions: () => void;
  addSession: ({
    session,
    order,
  }: {
    session: SessionProps['currentSession'];
    order?: 'START' | 'END';
  }) => void;
  removeSession: (id: string) => void;
  updateSessionName: ({ name, id }: { name: string; id: string }) => void;
  changeCurrentSession: (index: string) => void;
}

const useSessionStore = create<SessionStore>(set => ({
  tools: null,
  setTools: json => set({ tools: JSON.parse(json) }),
  insights: null,
  setInsights: insights => set(() => ({ insights })),
  session: {
    currentSession: {} as SessionProps['currentSession'],
    sessionList: [],
  },
  clearSessions: () =>
    set(() => ({
      session: {
        currentSession: {} as SessionProps['currentSession'],
        sessionList: [],
      },
    })),
  addSession: ({ session, order = 'START' }) =>
    set(state => {
      const existsInSession = state.session.sessionList.find(item => item.id === session.id);

      if (existsInSession) {
        console.debug('session exists', existsInSession);
        return state;
      }

      if (order === 'START') {
        return {
          session: {
            currentSession: state.session.currentSession,
            sessionList: [session, ...state.session.sessionList],
          },
        };
      }
      return {
        session: {
          currentSession: state.session.currentSession,
          sessionList: [...state.session.sessionList, session],
        },
      };
    }),
  removeSession: id =>
    set(state => ({
      session: {
        currentSession: state.session.currentSession,
        sessionList: state.session.sessionList.filter(session => session.id !== id),
      },
    })),
  updateSessionName: ({ name, id }) =>
    set(state => ({
      session: {
        currentSession: state.session.currentSession,
        sessionList: state.session.sessionList.map(session => {
          if (session.id === id) {
            return {
              id: session.id,
              name,
              date: session.date,
            };
          }

          return session;
        }),
      },
    })),
  changeCurrentSession: index =>
    set(state => ({
      session: {
        currentSession:
          state.session.sessionList.find(session => session.id === index) ??
          state.session.currentSession,
        sessionList: [...state.session.sessionList],
      },
    })),
}));

export default useSessionStore;
