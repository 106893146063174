import { Button, ButtonProps } from '@packages/ui/shadcn/ui/button.tsx';
import { LoadingSpinner } from '@rangpt/components/ui/loadingSpinner.tsx';
import React from 'react';

interface ActionButtonProps extends ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
}

function ActionButton({
  children,
  loading,
  onClick,
  disabled,
}: Readonly<ActionButtonProps>) {
  return (
    <Button
      className="flex w-fit gap-3 rounded-[0.3rem] bg-slate-700 text-sm transition-all duration-700 ease-out
      disabled:border ring-inset disabled:ring-gray-300 disabled:opacity-1 disabled:bg-transparent disabled:text-gray-400"
      variant="default"
      onClick={onClick}
      disabled={loading ? true : disabled}
    >
      {children}
      {loading ? <LoadingSpinner width="16" height="16" /> : <></>}
    </Button>
  );
}

export { ActionButton };
