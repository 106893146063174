import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  ReactFlowState,
  useStore,
} from '@xyflow/react';
import { FlowChartEdge } from '@app/types';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { flowChartStore } from '@app/stores';

export type GetSpecialPathParams = {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY }: GetSpecialPathParams,
  offset: number,
): [path: string, labelX: number, labelY: number] => {
  const midPointX = (sourceX + targetX) / 2;
  const midPointY = (sourceY + targetY) / 2;

  const p1X = sourceX;
  const p1Y = sourceY;
  const p2X = midPointX;
  const p2Y = midPointY - offset;
  const p3X = targetX;
  const p3Y = targetY;

  const path = `M ${p1X} ${p1Y} L ${p2X} ${p2Y} L ${p3X} ${p3Y}`;

  return [path, midPointX, p2Y];
};

// @TODO: refactor this component. Code is great, but can be improved, and remove some complexity.

export const InputEdge = ({
  id,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  selected,
  data,
  markerStart,
  markerEnd,
  style,
  source,
  target,
}: EdgeProps<FlowChartEdge>) => {
  const store = flowChartStore();

  const [content, setContent] = useState(data?.label);
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  const isBiDirectionEdge = useStore((s: ReactFlowState) => {
    const edgeExists = s.edges.some(e => {
      return (
        (e.source === target && e.target === source) ||
        (e.target === source && e.source === target)
      );
    });

    return edgeExists;
  });

  useEffect(() => {
    if (!span.current) return;
    setWidth(span.current.offsetWidth);
  }, [content]);

  const debounced = useDebouncedCallback<(value: string) => void>(value => {
    store.updateEdge({
      id,
      data: { label: value },
    });
  }, 1000);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setContent(evt.target.value);
    debounced(evt.target.value);
  };

  const edgePathParams = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  };

  let path = '';
  let labelX = 0;
  let labelY = 0;

  if (isBiDirectionEdge) {
    [path, labelX, labelY] = getSpecialPath(
      edgePathParams,
      sourceX < targetX ? 25 : -25,
    );
  } else {
    [path, labelX, labelY] = getSmoothStepPath(edgePathParams);
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        style={style}
        markerStart={markerStart}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        {!data?.label && !selected ? null : (
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
            className="absolute w-fit h-fit z-10 rounded-sm text-[0.65rem] pointer-events-all bg-white py-0.5 px-1"
          >
            <span
              className="absolute -z-50 whitespace-pre opacity-0 text-[0.65rem]"
              ref={span}
            >
              {content}
            </span>
            <input
              type="text"
              className="min-w-4 outline-none text-[0.65rem]"
              onChange={changeHandler}
              style={{ width }}
              defaultValue={content}
            />
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};
