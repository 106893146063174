import { formattedDate } from '@rangpt/lib/utils.ts';
import { PromptData } from '@rangpt/types';
import { User } from 'react-feather';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';

interface PromptProps {
  promptData: PromptData;
  index: number;
  showShadow: boolean;
}

const Prompt = ({ promptData, index, showShadow }: PromptProps) => {
  return (
    <div
      className={cn(
        'w-full self-end text-end p-4 flex flex-col z-10 sticky-prompt sh prompt-bg',
        showShadow && 'shadow-prompt',
      )}
    >
      <section className="flex flex-row items-center p-1 gap-2 self-end">
        <button className="text-xs text-[#8c95a1] bg-[#f9f9f9] border border-[#e2e8f0] p-1 rounded">
          Prompt {index}
        </button>
        <p className="text-xs text-[#8c95a1]">
          {formattedDate(promptData.prompt.timestamp)}
        </p>
        <User width={16} height={16} />
      </section>
      <p className="text-sm text-[#2d2e2f] code flex flex-row items-center self-end py-2 mr-7">
        {promptData.prompt.content}
      </p>
    </div>
  );
};

export { Prompt };
