// @ts-nocheck
// Configurações das escalas
import { AnimationContext, DataPoint } from './types.ts';

interface GenerateChartOptionsProps {
  data: DataPoint[];
  setTooltipInfo: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      x: number;
      y: number;
      content: string;
    }>
  >;
  showAnnotations: boolean;
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>;
}

export const generateChartOptions = ({
  data,
  showAnnotations,
}: GenerateChartOptionsProps) => {
  if (data.length === 0) {
    return {};
  }

  const scales = {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        text: '(%)',
      },
      min: 40,
      max: 100,
      ticks: {
        stepSize: 10,
      },
    },
    y1: {
      type: 'linear',
      display: false,
      position: 'right',
      title: {
        display: true,
        text: 'UETH PDL',
      },
      min: 500,
      max: 3500,
      ticks: {
        stepSize: 500,
        align: 'center',
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    y2: {
      type: 'linear',
      display: false,
      position: 'right',
      title: {
        display: true,
        text: 'RRC Mean Connections',
      },
      min: 20,
      max: 110,
      ticks: {
        stepSize: 10,
        align: 'start',
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      min: 0,
      max: data.length - 1,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
        lineWidth: 0.5,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        autoSkip: true,
        font: {
          size: 10,
        },
      },
    },
  };

  const annotation = {
    annotations: {
      // point1830: {
      //   type: 'point',
      //   // xValue: data.findIndex(d => d.time === '18:30'),
      //   //           yValue: data.find(d => d.time === '18:30')?.uethpdl,
      //   xValue: 74,
      //   yValue: 46,
      //   backgroundColor: 'rgba(255, 99, 132, 0.25)',
      //   radius: 8,
      //   borderWidth: 2,
      //   borderColor: 'red',
      //   cursor: 'pointer',
      //   drawTime: 'afterDraw',
      //   z: 100,
      //   enter: ({ element }: { element: { x: number; y: number } }) => {
      //     setTooltipInfo({
      //       show: true,
      //       x: Math.floor(element.x),
      //       y: Math.floor(element.y) + 40,
      //       content:
      //         'Interference on iDAS cell detected, down tilting neighbor cells',
      //     });
      //     return true;
      //   },
      //   leave: () => {
      //     setTooltipInfo(prev => ({ ...prev, show: false }));
      //     return true;
      //   },
      // },
      // point1830_2: {
      //   type: 'point',
      //   // xValue: data.findIndex(d => d.time === '18:30'),
      //   //           yValue: data.find(d => d.time === '18:30')?.uethpdl,
      //   xValue: 74,
      //   yValue: 87,
      //   backgroundColor: 'rgba(255, 99, 132, 0.25)',
      //   radius: 8,
      //   borderWidth: 2,
      //   borderColor: 'red',
      //   cursor: 'pointer',
      //   drawTime: 'afterDraw',
      //   z: 100,
      //   enter: ({ element }: { element: { x: number; y: number } }) => {
      //     setTooltipInfo({
      //       show: true,
      //       x: Math.floor(element.x),
      //       y: Math.floor(element.y) + 40,
      //       content:
      //         'Interference on iDAS cell detected, down tilting neighbor cells',
      //     });
      //     return true;
      //   },
      //   leave: () => {
      //     setTooltipInfo(prev => ({ ...prev, show: false }));
      //     return true;
      //   },
      // },
      // point2200: {
      //   type: 'point',
      //   // xValue: data.findIndex(d => d.time === '22:00'),
      //   // yValue: data.find(d => d.time === '22:00')?.uethpdl,
      //   xValue: 88,
      //   yValue: 57,
      //   backgroundColor: 'rgba(141, 242, 168, 0.25)',
      //   radius: 8,
      //   borderWidth: 2,
      //   borderColor: 'green',
      //   drawTime: 'afterDraw',
      //   cursor: 'pointer',
      //   z: 100,
      //   enter: ({ element }: { element: { x: number; y: number } }) => {
      //     setTooltipInfo({
      //       show: true,
      //       x: Math.floor(element.x),
      //       y: Math.floor(element.y) + 40,
      //       content:
      //         'Interference is back to normal, up tilting the neighbor cells.',
      //     });
      //     return true;
      //   },
      //   leave: () => {
      //     setTooltipInfo(prev => ({ ...prev, show: false }));
      //     return true;
      //   },
      // },
      // point2200_2: {
      //   type: 'point',
      //   // xValue: data.findIndex(d => d.time === '22:00'),
      //   // yValue: data.find(d => d.time === '22:00')?.uethpdl,
      //   xValue: 88,
      //   yValue: 72,
      //   backgroundColor: 'rgba(141, 242, 168, 0.25)',
      //   radius: 8,
      //   borderWidth: 2,
      //   borderColor: 'green',
      //   drawTime: 'afterDraw',
      //   cursor: 'pointer',
      //   z: 100,
      //   enter: ({ element }: { element: { x: number; y: number } }) => {
      //     setTooltipInfo({
      //       show: true,
      //       x: Math.floor(element.x),
      //       y: Math.floor(element.y) + 40,
      //       content:
      //         'Interference is back to normal, up tilting the neighbor cells.',
      //     });
      //     return true;
      //   },
      //   leave: () => {
      //     setTooltipInfo(prev => ({ ...prev, show: false }));
      //     return true;
      //   },
      // },
      // verticalLine: {
      //   type: 'line',
      //   value: '18:30',
      //   borderColor: 'rgb(63, 81, 181)',
      //   borderDash: [5, 5],
      //   borderWidth: 1,
      //   drawTime: 'afterDraw',
      //   scaleID: 'x',
      //   mode: 'vertical',
      // },
      // verticalLine2: {
      //   type: 'line',
      //   value: '22:00',
      //   borderColor: 'rgb(63, 81, 181)',
      //   borderDash: [5, 5],
      //   borderWidth: 1,
      //   drawTime: 'afterDraw',
      //   scaleID: 'x',
      //   mode: 'vertical',
      // },
    },
  };

  // Configurações dos plugins
  const plugins = {
    legend: {
      position: 'top' as const,
      labels: {
        filter: legendItem => {
          return [
            'PRB Utilization',
            'UETH PDL',
            'RRC Mean Connections',
          ].includes(legendItem.text);
        },
      },
    },
    title: {
      display: true,
      text: 'PM KPIs(Input)',
    },
    annotation: showAnnotations ? annotation : {},
    tooltip: {
      // callbacks: {
      // label: context => {
      //   if (context.dataset.label === 'PRB Utilization') {
      //     return [
      //       `${context.dataset.label}: ${context.parsed.y}`,
      //       'Interference on iDAS cell detected, down tilting neighbor cells',
      //     ];
      //   }
      //   return `${context.dataset.label}: ${context.parsed.y}`;
      // },
      // },
    },
  };

  // Configurações de animação
  const animation = {
    duration: 20000,
    onComplete: (ctx: { initial: boolean; currentStep: number }) => {
      console.debug('completed animation', ctx);
    },
    x: {
      type: 'number',
      easing: 'linear',
      duration: 20000 / data.length,
      from: NaN,
      delay(ctx: AnimationContext) {
        if (ctx.type !== 'data' || ctx.xStarted) {
          return 0;
        }
        ctx.xStarted = true;
        return ctx.index * (20000 / data.length);
      },
    },
    y: {
      type: 'number',
      easing: 'linear',
      duration: 20000 / data.length,
      from: (ctx: AnimationContext) => {
        if (ctx.index === 0) {
          return ctx.chart.scales[ctx.dataset.yAxisID].getPixelForValue(0);
        }
        return ctx.chart
          .getDatasetMeta(ctx.datasetIndex)
          .data[ctx.index - 1]?.getProps(['y'], true).y;
      },
      delay(ctx: AnimationContext) {
        if (ctx.type !== 'data' || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * (20000 / data.length);
      },
    },
  };

  return {
    responsive: true,
    plugins,
    animation,
    // annotation,
    scales,
    interaction: {
      intersect: true,
      mode: 'nearest',
      axis: 'x',
      threshold: 10,
    },
  };
};
