import { ResizablePanel } from '@packages/ui/shadcn/ui/resizable.js';

export interface LoadingContainerProps {
  text: string;
}

export const LoadingContainer = ({ text }: LoadingContainerProps) => {
  return (
    <ResizablePanel
      defaultSize={50}
      className="min-w-[400px] w-full h-full p-8 gap-6 bg-light-3 fade-in"
    >
      <div className="w-full h-full flex flex-col items-center justify-center">
        <img
          src="/load-code.gif"
          alt="Loading Code"
          className="w-12 h-12"
        />
        <p className="text-dark-1 text-md">{text}</p>
      </div>
    </ResizablePanel>
  );
};
