import { Share2 } from 'react-feather';
import { Check } from 'lucide-react';
import { useState } from 'react';

const CopyChartURL = ({ link }: { link: string }) => {
  const [copied, setCopied] = useState(false);
  // const addRef = useRef<HTMLDivElement>(null);
  // const addedRef = useRef<HTMLDivElement>(null);
  // const nodeRef = copied ? addRef : addedRef;

  const copy = () => {
    if (!link) return;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(
        () => {
          setCopied(true);
          // console.log('Content copied to clipboard');
        },
        () => {
          // console.error('Failed to copy');
        },
      );
    } else {
      setCopied(true);
      const textArea = document.createElement('textarea');
      textArea.value = link;

      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();
      textArea.focus();

      try {
        document.execCommand('copy');
      } catch (error) {
        // console.error(error);
      } finally {
        textArea.remove();
      }
    }

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <button
      type="button"
      className={`fade-in relative flex items-center justify-center w-fit self-end ${
        copied ? 'gap-2' : 'gap-1'
      } rounded-md bg-white py-2 px-4 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:leading-6 cursor-pointer disabled:bg-gray-100 disabled:shadow-neutral-200`}
      onClick={copy}
    >
      {/*<SwitchTransition mode="out-in">*/}
      {/*  <CSSTransition*/}
      {/*    key={copied ? 'Selected' : 'select'}*/}
      {/*    nodeRef={nodeRef}*/}
      {/*    addEndListener={(done: () => void) => {*/}
      {/*      nodeRef.current?.addEventListener('transitionend', done, false);*/}
      {/*    }}*/}
      {/*    classNames="slide"*/}
      {/*  >*/}
      {/*    /!*<span ref={nodeRef} className="text-left text-gray-900 text-sm w-9/10">*!/*/}
      {/*    /!*  {copied ? 'Copied' : 'Share'}*!/*/}
      {/*    /!*</span>*!/*/}
      {/*  </CSSTransition>*/}
      {/*</SwitchTransition>*/}
      {copied ? (
        <Check width={16} height={16} />
      ) : (
        <Share2 width={16} height={16} />
      )}
    </button>
  );
};

export { CopyChartURL };
