import { useClientStore } from '@management/stores';
import { Formatter } from '@management/utils/DataFormatter.ts';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { ClientClient, UpdateClientPayload } from '@packages/api';
import { toast } from 'react-toastify';
import {
  defaultLightToastOptions,
  defaultToastErrorToastOptions,
} from '@management/configs.ts';
import { useAuth, useSocket } from '@packages/hooks';
import { useShallow } from 'zustand/react/shallow';
import { useCallback } from 'react';

export function useMemoryManagement() {
  const { currentUser } = useAuth();
  const { memoryList, setMemoryList } = useClientStore(
    useShallow(state => ({
      memoryList: state.memoryList,
      setMemoryList: state.setMemoryList,
    })),
  );
  const { socket, chat_id } = useSocket();

  const formatter = new Formatter();
  const clientAPI = new ClientClient();
  const queryClient = new QueryClient();

  const updateClient = useMutation(
    {
      mutationFn: (payload: UpdateClientPayload) =>
        clientAPI.update({
          id: currentUser!.owner,
          ...payload,
        }),
      onSuccess: () => {
        emitMemoryList(memoryList);
        toast.success('Client Database Updated!', defaultLightToastOptions);
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  const emitMemoryList = useCallback(
    (list: string[]) => {
      if (!socket) return;

      console.debug('emitting');

      socket.emit('knowledge_base', {
        chat_id,
        timestamp: Date.now(),
        memory_list: list,
      });
    },
    [socket, chat_id],
  );

  const deleteItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const index = Number(e.currentTarget.id);

    const list = memoryList.filter((_, i) => i !== index);

    updateClient.mutate({
      memoryList: list,
    });

    setMemoryList(list);
  };

  const addItem = (value: string) => {
    if (memoryList.includes(value)) return;

    const list = memoryList.concat(value);

    setMemoryList(list);

    updateClient.mutate({
      memoryList: list,
    });
  };

  const clearMemory = () => {
    if (memoryList.length === 0) return;

    setMemoryList([]);
    updateClient.mutate({
      memoryList: [],
    });
  };

  const handleLoadCsv = useCallback(
    (csv: string) => {
      const list = formatter.csvToMemoryList(csv);

      if (!list) return;

      const value = useClientStore.getState().memoryList;

      const concatenatedArr = list.concat(value);

      const uniqueItemsArr = [...new Set(concatenatedArr)];

      updateClient.mutate({
        memoryList: uniqueItemsArr,
      });

      setMemoryList(uniqueItemsArr);

      emitMemoryList(uniqueItemsArr);
    },
    [emitMemoryList, setMemoryList, updateClient],
  );

  return {
    memoryList,
    setMemoryList,
    deleteItem,
    addItem,
    clearMemory,
    handleLoadCsv,
  };
}
