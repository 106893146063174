import { Input } from '@shadcn/ui/input.tsx';
import { Button } from '@shadcn/ui/button.tsx';
import { FormEvent } from 'react';

interface LoginFormProps {
  submit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  error: Error | null;
}

export const LoginForm = ({ submit, isLoading, error }: LoginFormProps) => {
  return (
    <form onSubmit={submit} className="w-full flex flex-col gap-5">
      <div className="flex justify-center items-center w-full">
        <Input
          className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-3 placeholder:text-xs"
          id="user"
          name="user"
          placeholder="Email"
          type="text"
          autoCapitalize="none"
          autoComplete="user"
          autoCorrect="off"
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-col items-start relative">
        <section className="w-full flex justify-center items-center gap-3">
          <Input
            className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-3 placeholder:text-xs"
            id="pass"
            name="pass"
            placeholder="Password"
            type="password"
            autoCapitalize="none"
            autoCorrect="off"
            disabled={isLoading}
          />
        </section>
        {error && (
          <p className="text-xs text-red-600 absolute left-1 top-full mt-2">
            {error.message}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center justify-end w-full gap-3 mt-4">
        {/* <button.tsx
          className="ml-auto border-0 bg-transparent p-0 w-fit text-dark-1 disabled:text-gray-400"
          type="button.tsx"
          disabled={true}
        >
          <span className="text-xs font-medium">I forgot my password</span>
        </button.tsx> */}
        <Button
          className="p-3 w-full self-end flex rounded-lg bg-dark-300 hover:bg-dark-1 text-white text-xs font-bold shadow-md pointer-events-auto"
          variant="outline"
          disabled={isLoading}
        >
          Sign In
        </Button>
      </div>
    </form>
  );
};
