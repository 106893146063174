import { Navigate, Outlet } from '@tanstack/react-router';
import { useAuth } from '@packages/hooks';

export const AppBuilderLayout = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
