import { ButtonHTMLAttributes, forwardRef } from 'react';
import { cn } from '@app/lib/utils.ts';

export const ModalButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, hidden, ...props }, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      className={cn(
        'min-w-fit w-32 flex items-center justify-start gap-2 rounded-lg bg-light-3 text-xs font-light p-3 text-nowrap',
        className,
        hidden ? 'hidden' : '',
      )}
    >
      {children}
    </button>
  );
});

ModalButton.displayName = 'ModalButton';
