import { FEATURE_TYPE } from './client.types.ts';
import { User } from './user.types.ts';

export enum ChartType {
  CHART = 'CHART',
  HTML = 'HTML',
}

export interface LoginRes {
  message: string;
  owner?: string;
  user: string;
  features: {
    id?: number;
    type: FEATURE_TYPE;
    limit?: number;
    isActive?: boolean;
  }[];
  token: string;
  dbInfo: Record<string, string>;
}

export type ChartsList = {
  id: string;
  user: User;
  type: ChartType;
  url: string;
  code: string;
  updatedAt: Date;
  createdAt: Date;
}[];
