import { EditorView } from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DefaultCodeContainer } from '@components/CodeConsole/containers/DefaultContainer.tsx';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { LoadingContainer } from '@components/CodeConsole';
import { LockedFlowChart } from '@components/CodeConsole/LockedFlowChart.tsx';
import { useCodeDeveloping } from '@app/hooks';
import { useLocation } from '@tanstack/react-router';

interface CodeContainerProps {
  title?: string;
  description?: string;
}

export const CodeContainer = ({
  title = 'Code Generated',
  description,
}: CodeContainerProps) => {
  const location = useLocation();
  const currentRoute = location.pathname.split('/').filter(Boolean).pop() ?? '';
  const {
    code,
    reviewCode,
    testCode,
    updateCodeOnHistory,
  } = useCodeDeveloping();
  const loading = code.loading;

  const [value, setValue] = useState(code.content ?? '');
  const [isOnFlowChart, setIsOnFlowChart] = useState(false);

  const debounced = useDebouncedCallback<(value: string) => void>(value => {
    setValue(value);
    updateCodeOnHistory(value);
  }, 300);

  useEffect(() => {
    if (!code.content) return;
    if (value.length === 0) setValue(code.content);
  }, [code, code.content]);

  if (loading) return <LoadingContainer text="Generating code..." />;

  if (!code.content) return null;

  const isOnDevelopment = currentRoute === 'development';

  const actionsLookUp = {
    review: () => reviewCode(value),
    development: () => testCode(value),
    // @TODO: implement deploy
  };

  const handleClick = actionsLookUp[currentRoute as keyof typeof actionsLookUp];

  const handleSwitch = () => {
    setIsOnFlowChart(!isOnFlowChart);
  };

  if (isOnFlowChart) {
    return (
      <LockedFlowChart id="left-panel">
        <Button
          variant="outline"
          className="outline-none bg-dark-1 text-white text-xs py-3 hover:shadow-md absolute top-8 right-8 z-50"
          onClick={handleSwitch}
        >
          Switch to Code
        </Button>
      </LockedFlowChart>
    );
  }

  return (
    <DefaultCodeContainer
      title={title}
      description={description ?? ''}
      content={code.content}
      updatedAt={code.updatedAt}
      editor={{
        value,
        onChange: debounced,
        readOnly: false,
        extensions: [python(), EditorView.lineWrapping],
      }}
      id={isOnDevelopment ? 'right-panel' : 'left-panel'}
      order={isOnDevelopment ? 2 : 1}
    >
      {isOnDevelopment ? (
        <Button
          variant="outline"
          className="outline-none bg-dark-1 text-white text-xs py-3 hover:shadow-md"
          onClick={handleClick}
        >
          Test Application
        </Button>
      ) : (
        <Button
          variant="outline"
          className="outline-none bg-dark-1 text-white text-xs py-3 hover:shadow-md"
          onClick={handleSwitch}
        >
          Switch to flowchart
        </Button>
      )}
    </DefaultCodeContainer>
  );
};
