import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { useOutsideClick } from '@rangpt/hooks';

interface PromptSelectProps {
  promptsList: (number | number[])[];
  setCurrentPrompt: (index: number) => void;
  currentPrompt: number;
  setCurrentNode: (index: number) => void;
  currentNode: number;
}

const PromptSelect = ({
  setCurrentPrompt,
  currentPrompt,
  promptsList,
  setCurrentNode,
  currentNode,
}: PromptSelectProps) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);

  const clickCallback = () => {
    setSelectIsOpen(false);
  };

  const ref = useOutsideClick<HTMLDivElement>(clickCallback);
  const openOrCloseSelect = () => {
    setSelectIsOpen(!selectIsOpen);
  };

  const setChart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const classList = e.currentTarget.classList;
    if (classList[classList.length - 1] === 'node') {
      setCurrentNode(Number(e.currentTarget.id));
    } else {
      setCurrentPrompt(Number(e.currentTarget.id));
      setCurrentNode(0);
    }
    setSelectIsOpen(false);
  };

  const prompts = () => {
    return promptsList.map((prompt, index) => {
      if (typeof prompt === 'number') {
        return (
          <li
            key={prompt}
            className="text-gray-900 relative select-none py-1 px-3 cursor-pointer border-b last:border-none border-[#F1F5F9]"
            id="datalist-option-0"
          >
            <button
              className="font-normal block truncate w-full text-start text-sm"
              id={String(prompt)}
              onClick={setChart}
            >
              Prompt {prompt + 1}
            </button>
          </li>
        );
      }
      return prompt.map(node => {
        if (node === 0) {
          return (
            <li
              key={index}
              className="text-gray-900 relative select-none py-1 px-3 cursor-pointer border-b last:border-none border-[#F1F5F9]"
              id="datalist-option-0"
            >
              <button
                className="font-normal block truncate w-full text-start text-sm"
                id={String(index)}
                onClick={setChart}
              >
                Prompt {index + 1}
              </button>
            </li>
          );
        }
        return (
          <li
            key={`${index}.${node}`}
            className="text-gray-900 relative select-none py-1 px-3 cursor-pointer border-b last:border-none border-[#F1F5F9]"
            id="datalist-option-0"
          >
            <button
              className="font-normal block truncate w-full text-start text-sm node"
              id={String(node)}
              onClick={setChart}
            >
              Prompt {index + 1}.{node}
            </button>
          </li>
        );
      });
    });
  };

  const selectedPrompt = () => {
    if (currentNode > 0) {
      return `Prompt ${currentPrompt + 1}.${currentNode}`;
    }
    return `Prompt ${currentPrompt + 1}`;
  };

  return (
    <div className="relative w-full flex-none" ref={ref}>
      <button
        type="button"
        className="relative flex w-full flex-col items-center justify-between rounded-md bg-white py-2 px-4 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:leading-6 cursor-pointer"
        aria-expanded="true"
        aria-labelledby="datalist-label"
        onClick={openOrCloseSelect}
      >
        <div className="flex flex-row w-36">
          <span className="flex-1 block truncate text-sm text-gray-900">{selectedPrompt()}</span>
          <span className="pointer-events-none flex items-center ">
            <ChevronDown width={18} height={18} />
          </span>
        </div>
      </button>
      {selectIsOpen && (
        <ul
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg shadow-[#0000001E] ring-1 ring-black ring-opacity-5 focus:outline-none animate-height-down"
          tabIndex={-1}
          onBlur={openOrCloseSelect}
        >
          {prompts()}
        </ul>
      )}
    </div>
  );
};

export { PromptSelect };
