import { historyCaller } from '@rangpt/apis/configs/fetch.ts';
import { API_URL } from '@rangpt/types/config.ts';
import { FullAppHistory, updatePromptPayload } from '@rangpt/apis/api.types.ts';
import { Prompt } from '@rangpt/types';
import { token } from '@packages/api';

const url = `${API_URL}/session`;

const defaultHeader: HeadersInit = {
  authorization: token,
  'Content-Type': 'application/json',
}

export async function getNewestHistory(username: string) {
  const opt = {
    method: 'GET',
    headers: defaultHeader,
  };

  const res = await historyCaller({ url: `${url}?username=${username}`, opt });

  if (!res)
    return ({
      id: null,
    } as unknown) as FullAppHistory;

  return res as FullAppHistory;
}

export async function getSessionById(id: string) {
  const opt = {
    method: 'GET',
    headers: defaultHeader
  };

  const res = await historyCaller({ url: `${url}/${id}`, opt });

  if (!res)
    return ({
      id: null,
    } as unknown) as FullAppHistory;

  return res as FullAppHistory;
}

export async function patchPrompt(prompt: updatePromptPayload) {
  const opt = {
    method: 'PATCH',
    body: JSON.stringify(prompt),
    headers: defaultHeader
  };

  return ((await historyCaller({ url: `${API_URL}/prompt`, opt })) as Prompt) || null;
}

export async function updateSession(payload: any) {
  const opt = {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: defaultHeader,
  };

  return await historyCaller({ url: `${url}`, opt });
}

export async function postCodeReview({
  payload,
}: {
  payload: Record<string, string | number | boolean>;
}) {
  const opt = {
    headers: defaultHeader,
    method: 'POST',
    body: JSON.stringify(payload),
  };

  return (await historyCaller({ url: `${API_URL}/chat/review`, opt })) as Record<
    string,
    string | boolean
  >;
}

export async function createSession(payload: any) {
  const opt = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: defaultHeader,
  };

  return await historyCaller({ url: `${url}`, opt });
}
