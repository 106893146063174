import { memo, useRef } from 'react';
import { SearchCodeIcon, Trash2Icon } from 'lucide-react';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { ObjectivesTextArea } from '@components/ObjectivesArea/ObjectivesTextArea.tsx';
import { ObjectivesOverlayDiv } from '@components/ObjectivesArea/ObjectivesOverlayDiv.tsx';
import { useObjectivesArea } from '@app/hooks/useAppDevelopment/useObjectivesArea.ts';

interface ObjectivesTextAreaProps {
  objectives: string | undefined;
  debouncedChange: ({
    label,
    objectives,
  }: {
    label?: string;
    objectives?: string;
  }) => void;
}

function ObjectivesAreaContainer({
  objectives,
  debouncedChange,
}: ObjectivesTextAreaProps) {
  const spanRef = useRef<HTMLSpanElement>(null);
  const {
    text,
    setText,
    redefine,
    handleAddSelectedKey,
    loadingKeyWords,
    emitObjectives,
  } = useObjectivesArea({
    objectives,
  });

  const save = () => {
    debouncedChange({ objectives: text });
  };

  const deleteObjectives = () => {
    debouncedChange({ objectives: undefined });
    setText('');
  };

  return (
    <div className="w-full flex flex-col gap-2">
      {loadingKeyWords && (
        <section className="w-full h-6 p-2 rounded-md text-xs flex gap-2 items-center justify-end text-gray-500">
          <SearchCodeIcon className="w-4 h-4 animate-[orbit_1.5s_linear_infinite]" />
          Analyzing key phrases...
        </section>
      )}
      <section className="w-full relative overflow-scroll">
        <span
          className="absolute text-xs text-gray-500 top-3 left-4 z-20"
          ref={spanRef}
        >
          Objectives
        </span>
        <button
          className="absolute top-3 right-4 cursor-pointer z-20"
          onClick={deleteObjectives}
        >
          <Trash2Icon className="h-4 w-4" />
        </button>
        <ObjectivesOverlayDiv handleAddSelectedKey={handleAddSelectedKey} />
        <ObjectivesTextArea
          setText={setText}
          text={text}
          spanRef={spanRef}
          emitObjectives={emitObjectives}
        />
      </section>

      <section className="w-full flex items-center justify-between gap-8">
        <Button
          variant="outline"
          className="flex items-center gap-2 p-3 w-full self-end text-center rounded-lg bg-white border border-dark-300 text-xs font-medium max-w-[170px]"
          type="button"
          onClick={redefine}
          disabled={text.length === 0}
        >
          Reset
        </Button>
        <Button
          variant="outline"
          className="p-3 w-full self-end flex rounded-lg bg-dark-300 text-white transition-colors text-xs max-w-[170px]"
          onClick={save}
        >
          Save changes
        </Button>
      </section>
    </div>
  );
}

export const Container = memo(ObjectivesAreaContainer);
