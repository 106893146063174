import { LoadingContainer } from '@components/CodeConsole/containers/loadingContainer.tsx';
import { ResizablePanel } from '@packages/ui/shadcn/ui/resizable.tsx';
import { useTestTab } from '@app/hooks/useTestTab.ts';
import { ChartContainer } from '@components/TestTab/chart/Container.tsx';

export const TestContainer = () => {
  const {
    loading,
    initialTable,
    expectedTable,
    // resultTable,
    // updateRowItemValue,
    // visibleTables,
    // filterTable,
  } = useTestTab();

  if (loading) {
    return (
      <ResizablePanel
        defaultSize={50}
        className="min-w-[400px] w-full flex flex-col items-center justify-start p-8 gap-6 bg-light-3 fade-in"
      >
        <LoadingContainer text="Generating Test Cases" />
      </ResizablePanel>
    );
  }

  if (!initialTable || !expectedTable) return null;

  // const filteredInitialTable = filterTable(initialTable)!;
  // const filteredExpectedTable = filterTable(expectedTable)!;
  // const filteredResultTable = filterTable(resultTable);

  return (
    <ResizablePanel
      defaultSize={50}
      className="min-w-[400px] w-full flex flex-col items-center justify-start p-8 gap-4 bg-light-3 fade-in"
      id="right-panel"
      order={2}
    >
      {/*<TestHeader />*/}

      <header className="flex w-full items-center justify-between">
        <section className="flex w-full flex-col gap-2">
          <h4 className="text-xl">Tilt Demo - Test Results</h4>
          {/*<span className="text-xs text-gray-800 opacity-60">*/}
          {/*  See the Chart*/}
          {/*</span>*/}
        </section>
      </header>

      <ChartContainer />
      {/* @TODO: Table and tests cases are disabled for the 7th demo */}
      {/*{Object.keys(filteredInitialTable.fields).length > 0 && (*/}
      {/*  <TableContainer*/}
      {/*    initialTable={filteredInitialTable}*/}
      {/*    expectedTable={filteredExpectedTable}*/}
      {/*    resultTable={filteredResultTable}*/}
      {/*    visibleTables={visibleTables}*/}
      {/*    updateRowItemValue={updateRowItemValue}*/}
      {/*  />*/}
      {/*)}*/}
    </ResizablePanel>
  );
};
