import { Home } from './home.tsx';
import { MyDashboard } from './MyDashboard.tsx';
import { EditMode } from './editMode.tsx';
import { Insights } from './insights.tsx';
import { RangptLayout } from './RangptLayout.tsx';

const Pages = {
  Home,
  MyDashboard,
  EditMode,
  Insights,
};

export { Pages, RangptLayout };
