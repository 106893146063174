import { useShallow } from 'zustand/react/shallow';
import { TestStore, useTestStore } from '@app/stores/test.store.ts';
import { useSocket } from '@packages/hooks';
import { useEffect, useMemo } from 'react';
import { TestTable } from '@app/types';
import {
  SOCKET_EVENTS,
  TestClient,
  TestTabPayload,
  WSTestEvents,
} from '@packages/api';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  defaultLightToastOptions,
  defaultToastErrorToastOptions,
} from '@packages/ui';
import { useRoutinesStore } from '@app/stores/routine.store.ts';
import {
  handleTestCaseResult,
  handleTestResult,
  handleTestStatus,
} from '@app/hooks/useAppDevelopment/utils.ts';
import { UiStore } from '@app/stores';

const testStoreSelector = (state: TestStore) => ({
  loading: state.loading,
  tables: state.tables,
  setTables: state.setTables,
  setLoading: state.setLoading,
  runMatchesTest: state.runMatchesTest,
  matches: state.matches,
  loadingResult: state.loadingResult,
  setLoadingResult: state.setLoadingResult,
  updateRowItemValue: state.updateRowItemValue,
});

export const useTestTab = () => {
  const {
    loading,
    tables,
    setTables,
    setLoading,
    runMatchesTest,
    matches,
    loadingResult,
    setLoadingResult,
    updateRowItemValue,
  } = useTestStore(useShallow(state => testStoreSelector(state)));
  const { visibleTables, selectedColumns, setSelectedColumns } = UiStore(
    useShallow(state => ({
      visibleTables: state.visibleTables,
      selectedColumns: state.selectedColumns,
      setSelectedColumns: state.setSelectedColumns,
    })),
  );
  const routineId = useRoutinesStore(state => state.currentRoutine.id);
  // const flowChartId = flowChartStore(state => state.id);

  const { socket } = useSocket();

  const testApi = new TestClient();
  const queryClient = new QueryClient();

  const columns = useMemo(() => {
    return Object.keys(tables.initial?.fields ?? []).map(
      (fieldName, index) => ({
        name: fieldName,
        id: index + 1, // Using index + 1 for id
      }),
    );
  }, [tables.initial]);

  useEffect(() => {
    if (selectedColumns.length === 0)
      setSelectedColumns(columns.map(col => col.id));

    socket?.on(SOCKET_EVENTS.TEST_APPLICATION_RESULT, event =>
      handleTestResult({
        event,
        setTables,
        setLoading,
        routineId,
        saveTestTab,
      }),
    );

    socket?.on(SOCKET_EVENTS.STATUS, handleTestStatus);

    socket?.on(
      SOCKET_EVENTS.TEST_CASE_RESULT,
      (event: WSTestEvents<TestTable>) =>
        handleTestCaseResult({
          event,
          setTables,
          runMatchesTest,
          setLoadingResult,
        }),
    );

    return () => {
      socket?.off(SOCKET_EVENTS.TEST_APPLICATION_RESULT);

      socket?.off(SOCKET_EVENTS.STATUS, handleTestStatus);

      socket?.off(SOCKET_EVENTS.TEST_CASE_RESULT);
    };
  }, [socket, columns]);

  const saveTestTab = useMutation(
    {
      mutationFn: (payload: TestTabPayload) => testApi.saveTestTab(payload),
      onSuccess: data => {
        toast.success('Test table saved!', defaultLightToastOptions);
        console.debug('~ saving test table', data);

        handleSaveExpectedData();
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  // @TODO: bring back save expected Data
  // const saveExpectedData = useMutation(
  //   {
  //     mutationFn: (payload: ExpectedTablePayload) =>
  //       testApi.saveExpectedTable(payload),
  //     onSuccess: data => {
  //       // toast.success('Test table saved!', defaultLightToastOptions);
  //       console.debug('~ saving expected table', data);
  //     },
  //   },
  //   queryClient,
  // );

  const handleSaveExpectedData = () => {
    if (!tables.expected) return;

    // const payload = transformToExpectedTablePayload(
    //   tables.expected.fields,
    //   flowChartId,
    // );

    // saveExpectedData.mutate(payload);
  };

  /**
   * Filters table fields based on selected columns
   */
  const filterTable = (table: TestTable | null): TestTable | null => {
    if (!table) return null;

    // Get mapping of column names to their IDs
    const columnNameToId = new Map(columns.map(col => [col.name, col.id]));

    const filteredFields = Object.fromEntries(
      Object.entries(table.fields).filter(([fieldName]) => {
        // Get column ID for this field
        const columnId = columnNameToId.get(fieldName) ?? -1;
        // Keep field if its column is selected
        return selectedColumns.includes(columnId);
      }),
    );

    return {
      ...table,
      fields: filteredFields,
    };
  };

  return {
    visibleTables,
    loading,
    initialTable: tables.initial,
    expectedTable: tables.expected,
    resultTable: tables.result,
    loadingResult,
    matches,
    updateRowItemValue,
    filterTable,
  };
};
