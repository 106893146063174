import { Chat } from '@rangpt/components/chat';
import {
  ResizableHandle,
  ResizablePanelGroup,
} from '@rangpt/components/ui/resizable';
import { HomeComponents } from '@rangpt/components/home';
import { useCommandInterface } from '@rangpt/hooks';
import { EmptyStateCommandInterface } from '@rangpt/components/CommandInterface/CommandInterfaceEmptyState.tsx';

export function Home() {
  const commandInterface = useCommandInterface();

  if (commandInterface.chatLength === 0) {
    return <EmptyStateCommandInterface />;
  }

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="col-span-2 row-span-1 flex flex-row"
    >
      <Chat.Container {...commandInterface} />
      <ResizableHandle />
      <HomeComponents.Content>
        <HomeComponents.Top />
        <HomeComponents.Bottom {...commandInterface} />
      </HomeComponents.Content>
    </ResizablePanelGroup>
  );
}
