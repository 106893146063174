import { Routine } from './Routine';
import { SearchRoutine } from './SearchRoutine';
import { CreateRoutineModal } from './CreateRoutine';
import { RenameModal } from './RenameModal';
import { DeleteModal } from './DeleteModal';

export const HomeComponents = {
  Routine,
  SearchRoutine,
  CreateRoutineModal,
  RenameModal,
  DeleteModal,
};
