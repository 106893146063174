import { create } from 'zustand';
import { newTimestamp } from '@rangpt/lib/utils.ts';
import { Chart } from '@rangpt/types/dashboard.types.ts';

interface DashboardStore {
  addGraph: (chart: Chart) => void;
  updateGraphCache: (id: string, blob: string) => void;
  dashboard: Chart[];
  setDashboard: (dashboard: Chart[]) => void;
  changePosition: (from: number, to: number) => void;
  removeGraph: (index: number) => void;
  replaceGraph: (index: number, graph: Partial<Chart>) => void;
  cleanUpDashboard: () => void;
  updateTime: number;
  setUpdateTimer: (time: number) => void;
  resetStore: () => void;
}

const initialState = {
  dashboard: [],
  updateTime: 3000,
};

const useDashboardStore = create<DashboardStore>((set, get) => ({
  ...initialState,
  addGraph: chart =>
    set(state => {
      const dashboard = [...get().dashboard];
      if (dashboard.find(item => item.id === chart.id)) return state;
      return { dashboard: [chart, ...dashboard] };
    }),
  updateGraphCache: (id, blob) =>
    set(() => {
      const dashboard = [...get().dashboard];

      const graph = dashboard.find(chart => chart.id === id);

      if (!graph) return { dashboard };

      const index = dashboard.indexOf(graph);

      dashboard[index].cachedChart = blob;

      return { dashboard };
    }),
  setDashboard: dashboard => set({ dashboard }),
  setUpdateTimer: timer => set({ updateTime: timer }),
  changePosition: (from, to) =>
    set(() => {
      const dashboard = [...get().dashboard];

      [dashboard[from], dashboard[to]] = [dashboard[to], dashboard[from]];

      return { dashboard };
    }),
  cleanUpDashboard: () =>
    set(() => ({
      dashboard: [],
      updated: newTimestamp(),
    })),
  removeGraph: index =>
    set(() => {
      const dashboard = [...get().dashboard];

      dashboard.splice(index, 1);

      return { dashboard };
    }),
  replaceGraph: (index, graph) =>
    set(state => {
      const dashboard = [...get().dashboard];

      const itemFilterInDash = dashboard.filter(item => item.id === graph.id);

      if (itemFilterInDash.length > 0) {
        const item = dashboard.indexOf(itemFilterInDash[0]);
        state.changePosition(index, item);
        return { dashboard };
      }

      dashboard[index] = { ...dashboard[index], ...graph };

      return { dashboard };
    }),
  resetStore: () => set(initialState),
}));

export default useDashboardStore;
