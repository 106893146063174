import { CheckIcon } from '@rangpt/components/ui/checkIcon.tsx';
import { CopyIcon } from '@rangpt/components/ui/copy.tsx';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { DefaultResponse, Response } from '@rangpt/types';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { githubLight } from '@rangpt/styles/codeTheme.ts';
import { useCopy, useShadow } from '@rangpt/hooks';

interface CodeMarkupProps {
  code: Response & DefaultResponse;
  readOnly?: boolean;
  updateCode?: (value: string) => void;
}

const Markup = ({ code, readOnly = true, updateCode }: CodeMarkupProps) => {
  const { scrollListener, ref } = useShadow(code);
  const { handleCopy, copying, nodeRef } = useCopy(code.content);

  return (
    <div
      key={code.id}
      className="relative w-full max-h-full overflow-hidden fade-in rounded-lg code-markup"
    >
      <button
        className="absolute text-black top-0 right-1 text-xs flex gap-1 z-10 items-center backdrop-blur-sm rounded-lg"
        onClick={handleCopy}
      >
        {copying ? <CheckIcon /> : <CopyIcon />}
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={copying ? 'Copied' : 'Copy'}
            nodeRef={nodeRef}
            addEndListener={(done: () => void) => {
              nodeRef.current?.addEventListener('transitionend', done, false);
            }}
            classNames="slide"
          >
            <span ref={nodeRef} className="w-[45px] text-left">
              {copying ? 'Copied' : 'Copy'}
            </span>
          </CSSTransition>
        </SwitchTransition>
      </button>
      <CodeMirror
        className="w-full max-h-full overflow-y-scroll scroll-bar-none"
        onScroll={scrollListener}
        value={code.content}
        ref={ref}
        extensions={[python(), EditorView.lineWrapping]}
        theme={githubLight}
        basicSetup={{ lineNumbers: false, foldGutter: false }}
        readOnly={readOnly}
        onChange={updateCode}
        maxWidth="100%"
      />
    </div>
  );
};

export { Markup };
