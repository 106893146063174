import { ReactNode, useState } from 'react';
import { Input } from '@packages/ui/shadcn/ui/input.tsx';
import { DefaultModal } from '@components/ui/DefaultModal.tsx';

export const CreateRoutineModal = ({
  onConfirm,
  children,
  description = 'Create a new routine with the default flowchart.',
  loading,
}: {
  onConfirm: (name: string) => void;
  children: ReactNode;
  description?: string;
  loading: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');

  const redefine = () => {
    if (inputValue.length === 0) return;

    onConfirm(inputValue);
    setInputValue('');
  };

  return (
    <DefaultModal
      title="New routine"
      description={description}
      trigger={children}
      onConfirm={redefine}
      loading={loading}
      confirmText="Create"
    >
      {/* @TODO: add default flowchart and empty flowchart options */}
      <section className="w-full relative">
        <Input
          id="name"
          placeholder="Routine name"
          className="w-full outline-none focus:outline-none ring-0 focus:ring-0 focus-visible:ring-0 !focus:ring-offset-0 text-xs"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </section>
    </DefaultModal>
  );
};
