import { ReactNode } from 'react';
import { cn, getTimeAgo } from '@app/lib/utils.ts';
import { UseMutationResult } from '@tanstack/react-query';
import { RoutineRes } from '@packages/api';

interface RoutineProps {
  children: ReactNode;
  name: string;
  // status: RoutineStatus;
  lastUpdate: number;
  id: string;
  selectRoutine: UseMutationResult<RoutineRes, Error, string, unknown>;
  isCurrentRoutine?: boolean;
}

export const Routine = ({
  children,
  lastUpdate,
  // status,
  name,
  id,
  selectRoutine,
  isCurrentRoutine,
}: RoutineProps) => {
  const className = cn(
    'w-fit h-fit p-7 flex items-center gap-12 bg-white rounded-lg shadow-sm',
    // status === RoutineStatus.DISABLED ? 'opacity-50' : '',
  );

  const handleSelectRoutine = () => selectRoutine.mutate(id);

  return (
    <div className={className}>
      {/* Routine */}
      <div
        role="button"
        className="w-full flex flex-col gap-1 items-start relative cursor-pointer"
        onClick={handleSelectRoutine}
        tabIndex={0}
      >
        <h2 className="font-normal">{name}</h2>
        {/*<span className="text-xs text-light-low">{status}</span>*/}
        <span className="text-xs text-light-low">{getTimeAgo(lastUpdate)}</span>
        <span className="text-xs text-red-400 text-nowrap absolute top-full mt-1 left-0">
          {isCurrentRoutine ? 'Active Routine' : ''}
        </span>
      </div>

      {/* Options */}
      {children}
    </div>
  );
};
