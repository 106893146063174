import AiraFavicon from '@public/aira-favicon.png';

interface FadeIconLoadingProps {
  text: string;
}

export function FadeIconLoading({ text }: Readonly<FadeIconLoadingProps>) {
  return (
    <div className="w-full h-full py-4 flex flex-col gap-2 items-center justify-center min-w-3xl bg-neutral-100">
      <div className="w-12 h-12 [animation:_heartbeat_1.6s_ease-in-out_infinite]">
        <img src={AiraFavicon} alt="" className="w-full h-full" />
      </div>
      <span className="text-xs text-[#8c95a1]">{text}</span>
    </div>
  );
}
