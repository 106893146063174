import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import { useAuth } from '@hooks/useAuth.ts';

export const Route = createFileRoute('/rangpt')({
  component: RangptLayout,
});

function RangptLayout() {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  // if (resetting) {
  //   return (
  //     <main className="h-full w-full flex items-center justify-center">
  //       <ResetContainer />
  //     </main>
  //   );
  // }

  return (
    <main className="h-screen max-h-screen w-full overflow-y-hidden">
      {/*<Header logOut={logOut} />*/}
      <Outlet />
    </main>
  )
}
