import { ReactNode } from 'react';

interface EmptyStateContainerProps {
  children?: ReactNode;
}

const Welcome = ({ children }: EmptyStateContainerProps) => {
  return (
    <div className="flex flex-col gap-1 w-full h-full justify-center items-center border border-[#CBD5E1] rounded-md">
      {children}
    </div>
  );
};

export { Welcome };
