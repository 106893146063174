import { Socket } from 'socket.io-client';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

export interface SocketConfigs {
  type?: 'query' | 'control';
  model?: 'gpt4';
  chat_id: string;
  username: string | null;
  database: Record<string, string> | null;
}

export interface SessionProps {
  sessionList: {
    id: string;
    name: string | null;
    date: string;
  }[];
  currentSession: {
    id: string;
    name: string | null;
    date: string;
  };
}

export type SetForceReconnect = (value: boolean) => void;

export type SetConfig = (value: SocketConfigs) => void;

interface UseSocket {
  socket: Socket | null;
  setSocket: (socket: Socket) => void;
  // disconnect: () => void;
  setForceReconnect: SetForceReconnect;
  forceReconnect: boolean;
  config: SocketConfigs;
  setConfig: SetConfig;
}

const defaultChatId = uuidv4();

const useSocketStore = create<UseSocket>(set => ({
  // disconnect: () =>
  //   set(state => {
  //     if (state.socket) {
  //       state.socket.disconnect();
  //     }
  //     return state;
  //   }),
  socket: null,
  setSocket: socket =>
    set(state => {
      state.socket = socket;
      return state;
    }),
  forceReconnect: false,
  setForceReconnect: (forceReconnect: boolean) =>
    set(() => ({ forceReconnect })),
  config: {
    type: 'query',
    model: 'gpt4',
    chat_id: defaultChatId,
    username: null,
    database: null,
  },
  setConfig: newConfig =>
    set(state => ({ config: { ...state.config, ...newConfig } })),
}));

export default useSocketStore;
