export const AuthProgress = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="absolute inset-0 flex flex-col gap-6 items-center justify-center bg-light-5">
        <img
          src="/aira-main-logo.png"
          alt="Aira logo"
          className="w-56 h-12 object-contain"
        />

        <div className="w-12 h-12 border-4 border-gray border-t-transparent rounded-full animate-spin mt-2" />
      </div>
    </div>
  );
};
