import { useEffect, useRef } from 'react';
import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { DefaultResponse, Response } from '@rangpt/types';

const useShadow = (code: Response & DefaultResponse) => {
  const ref = useRef<ReactCodeMirrorRef>(null);
  useEffect(() => {
    if (!ref.current) return;
    const ro = new ResizeObserver(entries => {
      if (ref.current) {
        if (!ref.current.view || !ref.current.editor) return;
        for (const entry of entries) {
          const editor = ref.current.editor;
          const markupIsBigger = ref.current.view?.contentHeight > entry.contentRect.height;
          if (markupIsBigger) {
            if (editor.classList.contains('overflow-shadow')) return;
            editor.classList.add('overflow-shadow');
            return;
          }

          //if it doesn't have, we don't need to remove
          if (!editor.classList.contains('overflow-shadow')) return;
          editor.classList.remove('overflow-shadow');
        }
      }
    });

    ro.observe(ref.current.editor!);

    setTimeout(() => {
      if (!ref.current) return;
      if (!ref.current.view || !ref.current.editor) return;
      const editor = ref.current.editor;
      const markupIsBigger = ref.current.view?.contentHeight > ref.current.editor?.offsetHeight + 5;
      if (markupIsBigger) {
        if (editor.classList.contains('overflow-shadow')) return;
        editor.classList.add('overflow-shadow');
      }
    }, 10);

    // return ref.current!.scrollHeight > ref.current!.offsetHeight;

    return () => {
      if (!ref.current?.editor) return;
      ro.unobserve(ref.current.editor);
    };
  }, [ref, code]);

  const scrollListener = () => {
    if (!ref.current) return;
    if (!ref.current.view || !ref.current.editor) return;
    const editor = ref.current.editor;

    const scrolledBottom = editor.scrollTop + editor.offsetHeight + 1 >= editor.scrollHeight;
    if (scrolledBottom) {
      //if it doesn't have, we don't need to remove
      if (!editor.classList.contains('overflow-shadow')) return;
      editor.classList.remove('overflow-shadow');
      return;
    }
    if (editor.classList.contains('overflow-shadow')) return;
    editor.classList.add('overflow-shadow');
  };

  return { ref, scrollListener };
};

export { useShadow };
