import { ReactNode, useRef } from 'react';
import CodeMirror, {
  Extension,
  ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { ThumbsDown, ThumbsUp } from 'react-feather';
import { githubLight } from '@app/lib/editorTheme.ts';
import { ResizablePanel } from '@packages/ui/shadcn/ui/resizable.tsx';
import { getTimeAgo } from '@app/lib/utils.ts';

export interface DefaultCodeContainerProps {
  title: string;
  description: string;
  content: string;
  children?: ReactNode;
  updatedAt: number | null;

  editor: {
    value: string;
    onChange?: (value: string) => void;
    readOnly?: boolean;
    extensions?: Extension[];
  };
  id?: string;
  order?: number;
}

/* Default container to Code / Review Data / Test Result.

 * editor: Refers to codeMirror default component props

  children: Any additional buttons or components to be displayed in the header */
export const DefaultCodeContainer = ({
  title,
  description,
  children,
  content,
  editor,
  updatedAt,
  id,
  order = 1,
}: DefaultCodeContainerProps) => {
  const ref = useRef<ReactCodeMirrorRef>(null);

  return (
    <ResizablePanel
      defaultSize={50}
      className="min-w-[400px] flex flex-col items-center justify-start p-8 gap-6 bg-light-3 fade-in"
      id={id}
      order={order}
    >
      <header className="w-full flex items-center justify-between">
        <section className="flex flex-col gap-2">
          <h4 className="text-xl">{title}</h4>
          <span className="text-xs text-gray-800 opacity-60">
            {description}
          </span>
          <span className="text-xs text-gray-800 opacity-60">
            {updatedAt ? `updated ${getTimeAgo(updatedAt)}` : ''}
          </span>
        </section>
        <section className="flex gap-2 items-center">
          <Button
            className={`px-3 py-3 flex rounded-[6px] opacity-80 hover:bg-[#e42429db] fade-in border-[#494a4a]`}
            variant="outline"
            // onClick={dislike}
          >
            <ThumbsDown color="#494a4a" width={16} height={16} />
          </Button>
          <Button
            className={`px-3 py-3 flex rounded-[6px] opacity-80 hover:bg-[#09e6afeb] fade-in border-[#494a4a]`}
            variant="outline"
            // onClick={like}
          >
            <ThumbsUp color="#494a4a" width={16} height={16} />
          </Button>
          {children}
        </section>
      </header>
      <div className="w-full max-h-[calc(100%-50px)] h-[calc(100%-50px)] flex-grow overflow-hidden pb-8">
        <CodeMirror
          defaultValue={content}
          {...editor}
          ref={ref}
          theme={githubLight}
          basicSetup={{ lineNumbers: true, foldGutter: false }}
          className="w-full max-h-[calc(100%-50px)] h-[calc(100%-50px)] overflow-y-auto"
          width="100%"
          height="95%"
          maxHeight="100%"
          maxWidth="100%"
        />
      </div>
    </ResizablePanel>
  );
};
