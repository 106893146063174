import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { FlowChartEdge, FlowChartNode, FlowChartState } from '@app/types';
import { generateShapeId } from '@app/lib/utils';
import { useSocket } from '@packages/hooks';
import {
  Connection,
  Edge,
  EdgeChange,
  NodeChange,
  OnDelete,
} from '@xyflow/react';
import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRoutines } from '@app/hooks/useRoutines.ts';

const flowchartStoreSelector = (state: FlowChartState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  id: state.id,
  updateNode: state.updateNode,
});

export const useFlowChart = () => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setNodes,
    setEdges,
    id,
    updateNode,
  } = flowChartStore(useShallow(flowchartStoreSelector));
  const { updateRoutine, currentRoutine, setCurrentRoutine } = useRoutines();

  // @TODO: refactor this because of objectives drawer usage
  useEffect(() => {
    if (currentRoutine.name) {
      const currentFlowChart = {
        nodes,
        edges,
      };
      if (currentRoutine.flowChart !== currentFlowChart) {
        console.debug('~ flowchart hook, updating flowchart info');
        setNodes(currentRoutine.flowChart.nodes);
        setEdges(currentRoutine.flowChart.edges);
      }
    }
  }, [currentRoutine]);

  const { socket, chat_id } = useSocket();

  const emit = () => {
    if (socket) {
      socket.emit('flow_chart', {
        chat_id: chat_id,
        flow_chart_id: id,
        flow_chart_payload: {
          nodes,
          edges,
        },
        origin: 'app_builder',
      });
    }
  };

  const handleAddNode = (args: Partial<FlowChartNode>) => {
    setNodes([
      ...nodes,
      {
        ...args,
        id: generateShapeId(nodes),
        style: { color: '#000' },
        position: { x: 0, y: 0 },
      } as FlowChartNode,
    ]);
  };

  const handleDelete: OnDelete<FlowChartNode, FlowChartEdge> = (_: {
    nodes: FlowChartNode[];
    edges: FlowChartEdge[];
  }) => {
    console.debug('Deleted', _);
  };

  const debouncedApiUpdated = useDebouncedCallback((node?: FlowChartNode) => {
    console.debug('running debounced api updated');
    setCurrentRoutine({
      ...currentRoutine,
      flowChart: {
        nodes: node ? [...nodes, node] : nodes,
        edges: edges,
      },
    });

    if (!currentRoutine.name) return;

    updateRoutine.mutate({
      id: currentRoutine.id,
      flowChart: {
        nodes: node ? [...nodes, node] : nodes,
        edges,
      },
    });
  }, 500);

  const handleOnNodesChange = useCallback(
    (changes: NodeChange<FlowChartNode>[]) => {
      onNodesChange(changes);

      debouncedApiUpdated();
    },
    [onNodesChange, debouncedApiUpdated],
  );

  const handleOnEdgesChange = useCallback(
    (changes: EdgeChange<Edge>[]) => {
      onEdgesChange(changes);
      debouncedApiUpdated();
    },
    [onEdgesChange, debouncedApiUpdated],
  );

  const handleUpdateNode = useCallback(
    (node: Partial<FlowChartNode>) => {
      updateNode(node);

      if (!currentRoutine.name) return;

      const updatedNodes = nodes.map(fcNode =>
        fcNode.id === node.id ? { ...fcNode, ...node } : fcNode,
      );

      updateRoutine.mutate({
        id: currentRoutine.id,
        flowChart: {
          nodes: updatedNodes,
          edges,
        },
      });
    },
    [updateNode],
  );

  const handleOnConnect = useCallback(
    (connection: Connection) => {
      console.debug(connection);
      onConnect(connection);
    },
    [onConnect, debouncedApiUpdated],
  );

  return {
    nodes,
    edges,
    onNodesChange: handleOnNodesChange,
    onEdgesChange: handleOnEdgesChange,
    onConnect: handleOnConnect,
    handleAddNode,
    emit,
    handleDelete,
    updateNode: handleUpdateNode,
    currentRoutine,
  };
};
