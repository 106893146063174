import { useEffect, useMemo } from 'react';
import { EditModeHeader, EditOptions } from '@rangpt/components/editMode';
import { useNavigate } from '@tanstack/react-router';
import { WS_URL } from '@rangpt/types/config.ts';
import useDashboard from '@rangpt/hooks/useDashboard.ts';

const EditMode = () => {
  const { dashboard } = useDashboard();
  const navigate = useNavigate();
  const grid = useMemo(() => {
    if (dashboard.length === 1) {
      return 'w-full h-full relative flex flex-col col-span-2 row-span-2 flex justify-center items-center p-8';
    }
    if (dashboard.length === 2) {
      return 'w-full h-full relative flex flex-col col-span-2 row-span-1 flex justify-center items-center border-b border-[#CBD5E1] p-4';
    }
    if (dashboard.length >= 3) {
      return 'w-full h-full relative flex flex-col row-span-1 col-span-1 flex justify-center items-center border-b border-l border-[#CBD5E1] p-4';
    }
  }, [dashboard.length]);

  useEffect(() => {
    if (dashboard.length === 0) {
      navigate({
        to: '/dashboard',
      });
    }
  }, []);

  return (
    <main className="grid grid-layout h-screen max-h-screen w-full overflow-y-hidden">
      <EditModeHeader />
      <div className="row-span-2 col-span-2 grid grid-rows-2 grid-cols-2 fade-in">
        {dashboard.map((graph, index) => {
          const firstItemOfTree = dashboard.length === 3 && index === 0;
          const style =
            (firstItemOfTree ? 'col-span-2 row-span-1 ' : '') + grid;
          return (
            <section className={style} key={graph.id}>
              <EditOptions />
              <img
                src={
                  graph.cachedChart
                    ? graph.cachedChart
                    : `${WS_URL}/listeners/${graph.id}?${new Date().getTime()}`
                }
                alt={graph.id}
                className="w-auto max-h-9/10 m-auto"
              />
            </section>
          );
        })}
      </div>
    </main>
  );
};

export { EditMode };
