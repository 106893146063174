import React from 'react';
import { useAccManagement } from '@management/hooks';
import { User } from '@packages/api';
import { TableHead } from '@packages/ui/shadcn/ui/table.js';

type SortableField = 'username' | 'createdAt' | 'isActive';
type SortDirection = 'asc' | 'desc';

interface TableColumnHeaderProps {
  title: string;
  field?: SortableField;
}

export const TableColumnHeader = ({ title, field }: TableColumnHeaderProps) => {
  const { setUsers, users } = useAccManagement();
  const [currentSort, setCurrentSort] = React.useState<{
    field: SortableField;
    direction: SortDirection;
  }>({
    field: 'createdAt',
    direction: 'asc',
  });

  const isSortable = field != null;
  const isCurrentSortField = field === currentSort.field;

  const handleOrder = () => {
    if (!isSortable || !users) return;

    const newDirection: SortDirection =
      isCurrentSortField && currentSort.direction === 'asc' ? 'desc' : 'asc';

    setCurrentSort({
      field,
      direction: newDirection,
    });

    const sortedUsers = [...users].sort((a: User, b: User) => {
      const aValue = a[field];
      const bValue = b[field];

      if (newDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });

    setUsers(sortedUsers);
  };

  // const getIconColor = () => {
  //   if (!isSortable) return 'text-light-1';
  //   return isCurrentSortField ? 'text-dark-1' : 'text-light-1';
  // };

  return (
    <TableHead className="px-0">
      <button
        className={`w-full flex gap-1 items-center ${
          isSortable
            ? 'cursor-pointer hover:text-gray-700'
            : 'cursor-default flex justify-end pr-6'
        }`}
        onClick={handleOrder}
        disabled={!isSortable}
      >
        <span className="mr-1">{title}</span>
        {isSortable && (
          <div className="relative flex items-center">
            {isCurrentSortField && (
              <span className="text-xs">
                {currentSort.direction === 'asc' ? '↑' : '↓'}
              </span>
            )}
          </div>
        )}
      </button>
    </TableHead>
  );
};
