import { XCircleIcon } from 'lucide-react';

export const ErrorLoadingImage = () => (
  <div className="absolute inset-0 w-full h-full bg-light-3 rounded-lg flex flex-col items-center justify-center gap-6">
    <XCircleIcon className="w-12 h-12" color="#F87171" />

    <p className="text-gray-500 text-center text-sm">
      Failed to render the chart. Please try again in a few moments.
    </p>
  </div>
);
