const LoadingSpinner = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    className="circle"
    width={width ?? '20'}
    height={height ?? '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_303_212)">
      <circle cx="10" cy="10" r="9" stroke="#E7E7E7" strokeWidth="2" />
      <mask id="path-2-inside-1_303_212" fill="white">
        <path d="M10 0.996165C10 0.445998 10.4471 -0.00513987 10.9945 0.0495761C12.1387 0.163938 13.2572 0.475068 14.2999 0.97165C15.643 1.61135 16.8266 2.54269 17.7641 3.69778C18.7017 4.85287 19.3698 6.20261 19.7196 7.64862C19.9912 8.77111 20.0657 9.92966 19.9423 11.0729C19.8832 11.6199 19.3498 11.9647 18.8114 11.8515C18.273 11.7384 17.9348 11.2099 17.9803 10.6616C18.0509 9.81047 17.985 8.95135 17.7831 8.11709C17.503 6.95917 16.9681 5.87835 16.2173 4.95339C15.4665 4.02843 14.5188 3.28265 13.4432 2.7704C12.6683 2.40133 11.8411 2.16019 10.9936 2.05421C10.4477 1.98595 10 1.54633 10 0.996165Z" />
      </mask>
      <path
        d="M10 0.996165C10 0.445998 10.4471 -0.00513987 10.9945 0.0495761C12.1387 0.163938 13.2572 0.475068 14.2999 0.97165C15.643 1.61135 16.8266 2.54269 17.7641 3.69778C18.7017 4.85287 19.3698 6.20261 19.7196 7.64862C19.9912 8.77111 20.0657 9.92966 19.9423 11.0729C19.8832 11.6199 19.3498 11.9647 18.8114 11.8515C18.273 11.7384 17.9348 11.2099 17.9803 10.6616C18.0509 9.81047 17.985 8.95135 17.7831 8.11709C17.503 6.95917 16.9681 5.87835 16.2173 4.95339C15.4665 4.02843 14.5188 3.28265 13.4432 2.7704C12.6683 2.40133 11.8411 2.16019 10.9936 2.05421C10.4477 1.98595 10 1.54633 10 0.996165Z"
        stroke="#4B5563"
        strokeWidth="2"
        mask="url(#path-2-inside-1_303_212)"
      />
    </g>
    <defs>
      <clipPath id="clip0_303_212">
        <rect width={width ?? '20'} height={height ?? '20'} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { LoadingSpinner };
