import { SheetContent, SheetTitle } from '@packages/ui/shadcn/ui/sheet.tsx';
import { ChangesMade } from '@components/ObjectivesArea/ChangesMade.tsx';
import { ChangeEvent } from 'react';
import { ObjectivesArea } from '@components/ObjectivesArea';

interface ContentProps {
  label: string;
  objectives?: string;
  type: string;
  debouncedChange: ({
    label,
    objectives,
  }: {
    label?: string;
    objectives?: string;
  }) => void;
}

const shapeIndexing: Record<string, string> = {
  circle: 'Start/End',
  square: 'Process',
  diamond: 'Decision',
  rectangle: 'Loop',
};

export const Content = ({
  label,
  objectives,
  type,
  debouncedChange,
}: ContentProps) => {
  const handleDebouncedChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedChange({ label: e.target.value });
  };

  return (
    <SheetContent
      className="right-0 bottom-0 min-w-400px w-[25%] h-[calc(100%-6.04rem)] bg-neutral-100 flex flex-col gap-9 items-start justify-start py-6 px-4"
      aria-describedby={undefined}
      onInteractOutside={() => undefined}
    >
      <div className="flex flex-col items-start justify-start gap-4">
        <SheetTitle className="text-lg text-dark-300 flex items-center gap-1">
          Set Objective -{' '}
          <span className="text-xs text-gray-500">{shapeIndexing[type]}</span>
        </SheetTitle>
        <span className="text-wrap text-sm text-gray-600">
          This objectives will be used on the code generation. Set your
          objectives, select the key phrases and see values options.
        </span>
      </div>

      <section className="relative w-full">
        <input
          type="text"
          id="label"
          name="label"
          defaultValue={label}
          onChange={handleDebouncedChange}
          className="rounded-lg bg-neutral-200 px-4 py-5 pb-1 w-full text-dark-300 focus:outline-none"
        />
        <label
          htmlFor="label"
          className="text-xs text-gray-500 absolute top-1 left-4"
        >
          Process name
        </label>
      </section>

      <ObjectivesArea.Container
        objectives={objectives}
        debouncedChange={debouncedChange}
      />

      <ChangesMade />
    </SheetContent>
  );
};
