import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { WS_URL } from '../types/config.ts';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formattedDate(timestamp: number) {
  if (timestamp === 0) return '';
  const date = new Date(timestamp);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}, ${day} of ${month}`;
}

export function transformDateToMMDDYYYYWithTime(dateString: string) {
  const date = new Date(dateString);

  // Extract the date components
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Format the date and time as MM-DD-YYYY, HH:MM
  return `${month}-${day}-${year}, ${hours}:${minutes}`;
}

export const newTimestamp = () => Math.floor(Date.now() / 1000);

export async function chartToListener(payload: {
  chat_id: string;
  code_id: string;
  prompt_id: string;
  text: string;
  timestamp: number;
}) {
  return await fetch(`${WS_URL}/listeners`, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then<Record<string, unknown>>(resp => resp.json());
}

export function removeString(string: string, stringToRemove: string) {
  if (string.includes(stringToRemove)) {
    return string.replace(stringToRemove, '');
  }
  return string;
}

export function countLines(text: string) {
  // count the number of elements
  const lines = text.split('\n');
  return lines.length;
}

export function removeFirstAndLastLine(str: string) {
  const firstIndex = str.indexOf('\n');
  const lastIndex = str.lastIndexOf('\n');

  return str.substring(firstIndex + 1, lastIndex);
}
