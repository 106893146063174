import { ReactElement } from 'react';
import { Switch } from '@packages/ui/shadcn/ui/switch.tsx';

interface FeatureCardProps {
  icon: ReactElement;
  type: string;
  name: string;
  isActive: boolean;
  onChecked: (checked: boolean) => void;
}

export const FeatureCard = ({
  icon,
  type,
  name,
  isActive,
  onChecked,
}: FeatureCardProps) => {
  return (
    <div
      className="flex flex-col items-center justify-center px-4 py-6 gap-6 w-36 min-h-36 bg-white rounded-md shadow-lg"
      key={type}
    >
      <section className="flex flex-col items-center justify-center gap-2">
        {icon}
        <span className="text-sm">{name}</span>
      </section>
      <Switch
        className="data-[state=unchecked]:bg-light-1 data-[state=checked]:bg-green-500 h-4 w-[2.3rem] data-[state=checked]:shadow-inner mt-1"
        id={type}
        name={type}
        checked={isActive}
        onCheckedChange={onChecked}
      />
    </div>
  );
};
