/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as RangptImport } from './routes/rangpt'
import { Route as ManagementImport } from './routes/management'
import { Route as AppImport } from './routes/app'
import { Route as IndexImport } from './routes/index'
import { Route as RangptIndexImport } from './routes/rangpt/index'
import { Route as ManagementIndexImport } from './routes/management/index'
import { Route as LoginIndexImport } from './routes/login/index'
import { Route as DashboardIndexImport } from './routes/dashboard/index'
import { Route as AppIndexImport } from './routes/app/index'
import { Route as RangptInsightsImport } from './routes/rangpt/insights'
import { Route as ManagementMemoryImport } from './routes/management/memory'
import { Route as ManagementDatabaseImport } from './routes/management/database'
import { Route as ManagementCapabilityImport } from './routes/management/capability'
import { Route as AppRoutineFlowchartImport } from './routes/app/$routine/flowchart'
import { Route as AppRoutineCodeImport } from './routes/app/$routine/code'
import { Route as AppRoutineCodeTestImport } from './routes/app/$routine/code/test'
import { Route as AppRoutineCodeReviewImport } from './routes/app/$routine/code/review'
import { Route as AppRoutineCodeDevelopmentImport } from './routes/app/$routine/code/development'

// Create/Update Routes

const RangptRoute = RangptImport.update({
  id: '/rangpt',
  path: '/rangpt',
  getParentRoute: () => rootRoute,
} as any)

const ManagementRoute = ManagementImport.update({
  id: '/management',
  path: '/management',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/app',
  path: '/app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const RangptIndexRoute = RangptIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => RangptRoute,
} as any)

const ManagementIndexRoute = ManagementIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ManagementRoute,
} as any)

const LoginIndexRoute = LoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => rootRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => rootRoute,
} as any)

const AppIndexRoute = AppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRoute,
} as any)

const RangptInsightsRoute = RangptInsightsImport.update({
  id: '/insights',
  path: '/insights',
  getParentRoute: () => RangptRoute,
} as any)

const ManagementMemoryRoute = ManagementMemoryImport.update({
  id: '/memory',
  path: '/memory',
  getParentRoute: () => ManagementRoute,
} as any)

const ManagementDatabaseRoute = ManagementDatabaseImport.update({
  id: '/database',
  path: '/database',
  getParentRoute: () => ManagementRoute,
} as any)

const ManagementCapabilityRoute = ManagementCapabilityImport.update({
  id: '/capability',
  path: '/capability',
  getParentRoute: () => ManagementRoute,
} as any)

const AppRoutineFlowchartRoute = AppRoutineFlowchartImport.update({
  id: '/$routine/flowchart',
  path: '/$routine/flowchart',
  getParentRoute: () => AppRoute,
} as any)

const AppRoutineCodeRoute = AppRoutineCodeImport.update({
  id: '/$routine/code',
  path: '/$routine/code',
  getParentRoute: () => AppRoute,
} as any)

const AppRoutineCodeTestRoute = AppRoutineCodeTestImport.update({
  id: '/test',
  path: '/test',
  getParentRoute: () => AppRoutineCodeRoute,
} as any)

const AppRoutineCodeReviewRoute = AppRoutineCodeReviewImport.update({
  id: '/review',
  path: '/review',
  getParentRoute: () => AppRoutineCodeRoute,
} as any)

const AppRoutineCodeDevelopmentRoute = AppRoutineCodeDevelopmentImport.update({
  id: '/development',
  path: '/development',
  getParentRoute: () => AppRoutineCodeRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/app': {
      id: '/app'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/management': {
      id: '/management'
      path: '/management'
      fullPath: '/management'
      preLoaderRoute: typeof ManagementImport
      parentRoute: typeof rootRoute
    }
    '/rangpt': {
      id: '/rangpt'
      path: '/rangpt'
      fullPath: '/rangpt'
      preLoaderRoute: typeof RangptImport
      parentRoute: typeof rootRoute
    }
    '/management/capability': {
      id: '/management/capability'
      path: '/capability'
      fullPath: '/management/capability'
      preLoaderRoute: typeof ManagementCapabilityImport
      parentRoute: typeof ManagementImport
    }
    '/management/database': {
      id: '/management/database'
      path: '/database'
      fullPath: '/management/database'
      preLoaderRoute: typeof ManagementDatabaseImport
      parentRoute: typeof ManagementImport
    }
    '/management/memory': {
      id: '/management/memory'
      path: '/memory'
      fullPath: '/management/memory'
      preLoaderRoute: typeof ManagementMemoryImport
      parentRoute: typeof ManagementImport
    }
    '/rangpt/insights': {
      id: '/rangpt/insights'
      path: '/insights'
      fullPath: '/rangpt/insights'
      preLoaderRoute: typeof RangptInsightsImport
      parentRoute: typeof RangptImport
    }
    '/app/': {
      id: '/app/'
      path: '/'
      fullPath: '/app/'
      preLoaderRoute: typeof AppIndexImport
      parentRoute: typeof AppImport
    }
    '/dashboard/': {
      id: '/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof rootRoute
    }
    '/login/': {
      id: '/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginIndexImport
      parentRoute: typeof rootRoute
    }
    '/management/': {
      id: '/management/'
      path: '/'
      fullPath: '/management/'
      preLoaderRoute: typeof ManagementIndexImport
      parentRoute: typeof ManagementImport
    }
    '/rangpt/': {
      id: '/rangpt/'
      path: '/'
      fullPath: '/rangpt/'
      preLoaderRoute: typeof RangptIndexImport
      parentRoute: typeof RangptImport
    }
    '/app/$routine/code': {
      id: '/app/$routine/code'
      path: '/$routine/code'
      fullPath: '/app/$routine/code'
      preLoaderRoute: typeof AppRoutineCodeImport
      parentRoute: typeof AppImport
    }
    '/app/$routine/flowchart': {
      id: '/app/$routine/flowchart'
      path: '/$routine/flowchart'
      fullPath: '/app/$routine/flowchart'
      preLoaderRoute: typeof AppRoutineFlowchartImport
      parentRoute: typeof AppImport
    }
    '/app/$routine/code/development': {
      id: '/app/$routine/code/development'
      path: '/development'
      fullPath: '/app/$routine/code/development'
      preLoaderRoute: typeof AppRoutineCodeDevelopmentImport
      parentRoute: typeof AppRoutineCodeImport
    }
    '/app/$routine/code/review': {
      id: '/app/$routine/code/review'
      path: '/review'
      fullPath: '/app/$routine/code/review'
      preLoaderRoute: typeof AppRoutineCodeReviewImport
      parentRoute: typeof AppRoutineCodeImport
    }
    '/app/$routine/code/test': {
      id: '/app/$routine/code/test'
      path: '/test'
      fullPath: '/app/$routine/code/test'
      preLoaderRoute: typeof AppRoutineCodeTestImport
      parentRoute: typeof AppRoutineCodeImport
    }
  }
}

// Create and export the route tree

interface AppRoutineCodeRouteChildren {
  AppRoutineCodeDevelopmentRoute: typeof AppRoutineCodeDevelopmentRoute
  AppRoutineCodeReviewRoute: typeof AppRoutineCodeReviewRoute
  AppRoutineCodeTestRoute: typeof AppRoutineCodeTestRoute
}

const AppRoutineCodeRouteChildren: AppRoutineCodeRouteChildren = {
  AppRoutineCodeDevelopmentRoute: AppRoutineCodeDevelopmentRoute,
  AppRoutineCodeReviewRoute: AppRoutineCodeReviewRoute,
  AppRoutineCodeTestRoute: AppRoutineCodeTestRoute,
}

const AppRoutineCodeRouteWithChildren = AppRoutineCodeRoute._addFileChildren(
  AppRoutineCodeRouteChildren,
)

interface AppRouteChildren {
  AppIndexRoute: typeof AppIndexRoute
  AppRoutineCodeRoute: typeof AppRoutineCodeRouteWithChildren
  AppRoutineFlowchartRoute: typeof AppRoutineFlowchartRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppIndexRoute: AppIndexRoute,
  AppRoutineCodeRoute: AppRoutineCodeRouteWithChildren,
  AppRoutineFlowchartRoute: AppRoutineFlowchartRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface ManagementRouteChildren {
  ManagementCapabilityRoute: typeof ManagementCapabilityRoute
  ManagementDatabaseRoute: typeof ManagementDatabaseRoute
  ManagementMemoryRoute: typeof ManagementMemoryRoute
  ManagementIndexRoute: typeof ManagementIndexRoute
}

const ManagementRouteChildren: ManagementRouteChildren = {
  ManagementCapabilityRoute: ManagementCapabilityRoute,
  ManagementDatabaseRoute: ManagementDatabaseRoute,
  ManagementMemoryRoute: ManagementMemoryRoute,
  ManagementIndexRoute: ManagementIndexRoute,
}

const ManagementRouteWithChildren = ManagementRoute._addFileChildren(
  ManagementRouteChildren,
)

interface RangptRouteChildren {
  RangptInsightsRoute: typeof RangptInsightsRoute
  RangptIndexRoute: typeof RangptIndexRoute
}

const RangptRouteChildren: RangptRouteChildren = {
  RangptInsightsRoute: RangptInsightsRoute,
  RangptIndexRoute: RangptIndexRoute,
}

const RangptRouteWithChildren =
  RangptRoute._addFileChildren(RangptRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/app': typeof AppRouteWithChildren
  '/management': typeof ManagementRouteWithChildren
  '/rangpt': typeof RangptRouteWithChildren
  '/management/capability': typeof ManagementCapabilityRoute
  '/management/database': typeof ManagementDatabaseRoute
  '/management/memory': typeof ManagementMemoryRoute
  '/rangpt/insights': typeof RangptInsightsRoute
  '/app/': typeof AppIndexRoute
  '/dashboard': typeof DashboardIndexRoute
  '/login': typeof LoginIndexRoute
  '/management/': typeof ManagementIndexRoute
  '/rangpt/': typeof RangptIndexRoute
  '/app/$routine/code': typeof AppRoutineCodeRouteWithChildren
  '/app/$routine/flowchart': typeof AppRoutineFlowchartRoute
  '/app/$routine/code/development': typeof AppRoutineCodeDevelopmentRoute
  '/app/$routine/code/review': typeof AppRoutineCodeReviewRoute
  '/app/$routine/code/test': typeof AppRoutineCodeTestRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/management/capability': typeof ManagementCapabilityRoute
  '/management/database': typeof ManagementDatabaseRoute
  '/management/memory': typeof ManagementMemoryRoute
  '/rangpt/insights': typeof RangptInsightsRoute
  '/app': typeof AppIndexRoute
  '/dashboard': typeof DashboardIndexRoute
  '/login': typeof LoginIndexRoute
  '/management': typeof ManagementIndexRoute
  '/rangpt': typeof RangptIndexRoute
  '/app/$routine/code': typeof AppRoutineCodeRouteWithChildren
  '/app/$routine/flowchart': typeof AppRoutineFlowchartRoute
  '/app/$routine/code/development': typeof AppRoutineCodeDevelopmentRoute
  '/app/$routine/code/review': typeof AppRoutineCodeReviewRoute
  '/app/$routine/code/test': typeof AppRoutineCodeTestRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/app': typeof AppRouteWithChildren
  '/management': typeof ManagementRouteWithChildren
  '/rangpt': typeof RangptRouteWithChildren
  '/management/capability': typeof ManagementCapabilityRoute
  '/management/database': typeof ManagementDatabaseRoute
  '/management/memory': typeof ManagementMemoryRoute
  '/rangpt/insights': typeof RangptInsightsRoute
  '/app/': typeof AppIndexRoute
  '/dashboard/': typeof DashboardIndexRoute
  '/login/': typeof LoginIndexRoute
  '/management/': typeof ManagementIndexRoute
  '/rangpt/': typeof RangptIndexRoute
  '/app/$routine/code': typeof AppRoutineCodeRouteWithChildren
  '/app/$routine/flowchart': typeof AppRoutineFlowchartRoute
  '/app/$routine/code/development': typeof AppRoutineCodeDevelopmentRoute
  '/app/$routine/code/review': typeof AppRoutineCodeReviewRoute
  '/app/$routine/code/test': typeof AppRoutineCodeTestRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/app'
    | '/management'
    | '/rangpt'
    | '/management/capability'
    | '/management/database'
    | '/management/memory'
    | '/rangpt/insights'
    | '/app/'
    | '/dashboard'
    | '/login'
    | '/management/'
    | '/rangpt/'
    | '/app/$routine/code'
    | '/app/$routine/flowchart'
    | '/app/$routine/code/development'
    | '/app/$routine/code/review'
    | '/app/$routine/code/test'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/management/capability'
    | '/management/database'
    | '/management/memory'
    | '/rangpt/insights'
    | '/app'
    | '/dashboard'
    | '/login'
    | '/management'
    | '/rangpt'
    | '/app/$routine/code'
    | '/app/$routine/flowchart'
    | '/app/$routine/code/development'
    | '/app/$routine/code/review'
    | '/app/$routine/code/test'
  id:
    | '__root__'
    | '/'
    | '/app'
    | '/management'
    | '/rangpt'
    | '/management/capability'
    | '/management/database'
    | '/management/memory'
    | '/rangpt/insights'
    | '/app/'
    | '/dashboard/'
    | '/login/'
    | '/management/'
    | '/rangpt/'
    | '/app/$routine/code'
    | '/app/$routine/flowchart'
    | '/app/$routine/code/development'
    | '/app/$routine/code/review'
    | '/app/$routine/code/test'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  ManagementRoute: typeof ManagementRouteWithChildren
  RangptRoute: typeof RangptRouteWithChildren
  DashboardIndexRoute: typeof DashboardIndexRoute
  LoginIndexRoute: typeof LoginIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  ManagementRoute: ManagementRouteWithChildren,
  RangptRoute: RangptRouteWithChildren,
  DashboardIndexRoute: DashboardIndexRoute,
  LoginIndexRoute: LoginIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/app",
        "/management",
        "/rangpt",
        "/dashboard/",
        "/login/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/app": {
      "filePath": "app.tsx",
      "children": [
        "/app/",
        "/app/$routine/code",
        "/app/$routine/flowchart"
      ]
    },
    "/management": {
      "filePath": "management.tsx",
      "children": [
        "/management/capability",
        "/management/database",
        "/management/memory",
        "/management/"
      ]
    },
    "/rangpt": {
      "filePath": "rangpt.tsx",
      "children": [
        "/rangpt/insights",
        "/rangpt/"
      ]
    },
    "/management/capability": {
      "filePath": "management/capability.tsx",
      "parent": "/management"
    },
    "/management/database": {
      "filePath": "management/database.tsx",
      "parent": "/management"
    },
    "/management/memory": {
      "filePath": "management/memory.tsx",
      "parent": "/management"
    },
    "/rangpt/insights": {
      "filePath": "rangpt/insights.tsx",
      "parent": "/rangpt"
    },
    "/app/": {
      "filePath": "app/index.tsx",
      "parent": "/app"
    },
    "/dashboard/": {
      "filePath": "dashboard/index.tsx"
    },
    "/login/": {
      "filePath": "login/index.tsx"
    },
    "/management/": {
      "filePath": "management/index.tsx",
      "parent": "/management"
    },
    "/rangpt/": {
      "filePath": "rangpt/index.tsx",
      "parent": "/rangpt"
    },
    "/app/$routine/code": {
      "filePath": "app/$routine/code.tsx",
      "parent": "/app",
      "children": [
        "/app/$routine/code/development",
        "/app/$routine/code/review",
        "/app/$routine/code/test"
      ]
    },
    "/app/$routine/flowchart": {
      "filePath": "app/$routine/flowchart.tsx",
      "parent": "/app"
    },
    "/app/$routine/code/development": {
      "filePath": "app/$routine/code/development.tsx",
      "parent": "/app/$routine/code"
    },
    "/app/$routine/code/review": {
      "filePath": "app/$routine/code/review.tsx",
      "parent": "/app/$routine/code"
    },
    "/app/$routine/code/test": {
      "filePath": "app/$routine/code/test.tsx",
      "parent": "/app/$routine/code"
    }
  }
}
ROUTE_MANIFEST_END */
