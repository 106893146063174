import { KeyList } from '@components/ObjectivesArea/KeysList.tsx';
import { memo, useState } from 'react';
import { useOutsideClick } from '@app/hooks';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { KeyWordsList } from '@app/types';
import { randomKeyColor } from '@app/utils/randomKeyColor.ts';

interface KeywordButtonProps {
  word: string;
  handleAddSelectedKey: (_: {
    currentWord: string;
    newWord: string;
    position: number;
  }) => void;
  lineIndex: number;
  addObjectiveChange: (change: string) => void;
  addKeyWordList: (newKeyWord: KeyWordsList[number]) => void;
  keyWordsList: KeyWordsList;
}

export const HighlightBtn = memo(
  ({
    word,
    handleAddSelectedKey,
    lineIndex,
    addObjectiveChange,
    addKeyWordList,
    keyWordsList,
  }: KeywordButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useOutsideClick<HTMLButtonElement>(() =>
      setIsOpen(false),
    );
    const keyMatch = keyWordsList.find(key => key.key === word);
    const colors = randomKeyColor();

    return (
      <button
        ref={buttonRef}
        className={cn(
          'relative z-20 px-0.5 -ml-0.5 rounded-md text-sm ',
          isOpen && 'border border-blue-500',
        )}
        style={{
          background: colors.bg,
          color: colors.text,
        }}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {word}
        {keyMatch && (
          <KeyList isOpen={isOpen}>
            {keyMatch.list.map(option => {
              const handleSelectKey = () => {
                const currentWord = word;
                // Check if selected option exists as a key
                const existingKeyword = keyWordsList.find(
                  item => item.key === option,
                );

                if (existingKeyword) {
                  // If exists, just update the text
                  handleAddSelectedKey({
                    currentWord,
                    newWord: option,
                    position: lineIndex,
                  });
                } else {
                  // If doesn't exist, create new keyword with same list
                  addKeyWordList({
                    key: option,
                    list: [
                      currentWord,
                      ...keyMatch.list.filter(word => word !== option),
                    ],
                  });
                  handleAddSelectedKey({
                    currentWord,
                    newWord: option,
                    position: lineIndex,
                  });
                }

                addObjectiveChange(
                  `The word ${currentWord} was replaced with ${option}`,
                );

                setIsOpen(false);
              };

              return (
                <button
                  key={option}
                  className="w-full p-2 text-xs text-dark-300 bg-gray-100 rounded-lg transition-colors duration-150 text-left whitespace-normal"
                  onClick={handleSelectKey}
                >
                  {option}
                </button>
              );
            })}
          </KeyList>
        )}
      </button>
    );
  },
);
