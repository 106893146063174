import { Loader2 } from 'lucide-react';
import { Button, ButtonProps } from '@packages/ui/shadcn/ui/button.tsx';

interface DatabaseDefaultButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText?: string;
}

export const DatabaseDefaultButton = ({
  isLoading,
  loadingText,
  disabled,
  children,
  hidden,
  ...props
}: DatabaseDefaultButtonProps) => {
  if (hidden) return null;
  const ButtonText = () => {
    if (isLoading) {
      return (
        <span className="flex gap-2 items-center">
          <Loader2 className=" h-4 w-4 animate-spin" />
          {loadingText ?? 'Saving'}
        </span>
      );
    }

    return <span>{children}</span>;
  };

  return (
    <Button
      variant="outline"
      className="w-fit text-xs bg-dark-1 text-light-3 rounded-md shadow-md border border-dark-1"
      disabled={disabled || isLoading}
      {...props}
    >
      {ButtonText()}
    </Button>
  );
};
