import { create } from 'zustand';
import { User } from '@packages/api/types';

interface AccManagementStoreState {
  users: User[];
  setUsers: (users: User[]) => void;
}

const useAccManagementStore = create<AccManagementStoreState>(set => ({
  users: [],
  setUsers: (users: User[]) => set({ users }),
}));

export default useAccManagementStore;
