import { Container } from './container.tsx';
import { Answer } from './answer.tsx';
import { Input } from './input.tsx';
import { Prompt } from './prompt.tsx';

const Chat = {
  Container,
  Answer,
  Input,
  Prompt,
};

export { Chat };
