import { StateCreator } from 'zustand';
import {
  BuildersData,
  PromptData,
  ResponseType,
  SocketEvents,
  Test,
} from '@rangpt/types';

export interface DefaultChatSliceProps<ChatType> {
  chat: ChatType[];
  currentPrompt: number;
  setCurrentPrompt: (index: number) => void;
  addPrompt: (
    event: SocketEvents,
    blob?: string | ArrayBuffer,
    name?: string | undefined,
  ) => void;
  likePrompt: (index: number) => void;
  dislikePrompt: (index: number) => void;
  writeFeedback: (value: string, index: number) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  setChat: (chat: PromptData[] | BuildersData[]) => void;
}

export interface BuilderSlice {
  chat: BuildersData[];
  updateReviewResults: (payload: Test) => void;
  updateCode: (value: string) => void;
  buildLoading: boolean;
  addResponse: (
    event: SocketEvents,
    type: ResponseType,
    isFinal?: boolean,
  ) => void;
  setBuildLoading: (status: boolean) => void;
  buildCreated: boolean;
  setBuildCreated: (status: boolean) => void;
  clearChat: () => void;
}

export const defaultChatSlice: StateCreator<
  DefaultChatSliceProps<PromptData | BuildersData>,
  [],
  [],
  DefaultChatSliceProps<PromptData | BuildersData>
> = set => ({
  chat: [],
  currentPrompt: 0,
  loading: false,
  setChat: chat => set(() => ({ chat })),
  addPrompt: event =>
    set(state => {
      const hasPrompt = state.chat.find(
        prompt => prompt.prompt_id === event.prompt_id,
      );

      if (hasPrompt) {
        return state;
      }

      const arr = state.chat;

      arr.push({
        code: undefined,
        chat_id: event.chat_id,
        prompt_id: event.prompt_id,
        prompt: {
          content: event.text,
          timestamp: event.timestamp,
          prompt_timestamp: Date.now(),
        },
        answer: [
          {
            id: '',
            type: 'answer',
            content: '',
            timestamp: 0,
            isLoading: true,
          },
        ],
      });

      return { chat: arr };
    }),
  likePrompt: (index: number) =>
    set(state => {
      const arr = state.chat;

      const prompt = arr[index];

      if (prompt.feedback?.dislike) {
        prompt.feedback.dislike = false;
        prompt.feedback.text = undefined;
      }

      // so if he doesnt want to provide a feedback now
      if (prompt.feedback?.like) {
        prompt.feedback = {};
      }

      prompt.feedback = {
        like: true,
      };

      return { chat: arr };
    }),
  dislikePrompt: index =>
    set(state => {
      const arr = state.chat;

      const prompt = arr[index];

      if (prompt.feedback?.like) {
        prompt.feedback.like = false;
      }

      if (prompt.feedback?.like) {
        prompt.feedback = {};
      }

      prompt.feedback = { ...prompt.feedback, dislike: true };

      return { chat: arr };
    }),
  writeFeedback: (value, index) =>
    set(state => {
      const arr = state.chat;

      const prompt = arr[index];

      prompt.feedback = { ...prompt.feedback, text: value };

      return { chat: arr };
    }),
  setLoading: status => set({ loading: status }),
  setCurrentPrompt: (index: number) =>
    set(() => ({
      currentPrompt: index,
    })),
});

export const builderSlice: StateCreator<
  DefaultChatSliceProps<BuildersData> & BuilderSlice,
  [],
  [],
  BuilderSlice
> = set => ({
  chat: [],
  buildLoading: false,
  setBuildLoading: (status: boolean) => set(() => ({ buildLoading: status })),
  buildCreated: false,
  setBuildCreated: (status: boolean) => set(() => ({ buildCreated: status })),
  clearChat: () =>
    set(() => ({
      chat: [],
      currentPrompt: 0,
      currentNode: 0,
      buildCreated: false,
      buildLoading: false,
      loading: false,
    })),
  addResponse: (event: SocketEvents, type: ResponseType, isFinal = false) =>
    set(state => {
      const arr = state.chat;
      const prompt = arr.find(prompt => prompt.prompt_id === event.prompt_id);
      if (!prompt) return state;

      const response = {
        id: event.id!,
        type: type,
        content: event.text ?? event.chart_id,
        // timestamp: event.timestamp, @NOTE: For now we have a problem in the API with timestamp generation
        timestamp: Date.now(),
        isLoading: false,
      };

      if (type === 'answer') {
        // checking for duplicates
        const duplicatedRes = prompt[type]?.find(r => r.id === response.id);

        console.debug('on answer update', duplicatedRes);

        if (duplicatedRes) return state;

        const currentPrompt = prompt[type];
        if (!currentPrompt) return state;

        const lastResponseIndex = currentPrompt.length - 1;
        if (currentPrompt[lastResponseIndex]?.isLoading) {
          currentPrompt[lastResponseIndex] = response;
          if (!isFinal) {
            currentPrompt.push({
              id: '',
              type: type,
              content: '',
              timestamp: 0,
              isLoading: true,
            });
          }

          return { chat: arr, loading: !isFinal };
        }
      }

      if (type === 'test') {
        console.debug('receive test');
        prompt[type] = {
          id: event.id!,
          type: type,
          content: event.text,
          timestamp: event.timestamp,
          loading: false,
        };

        return { chat: arr };
      }

      if (type === 'build') {
        console.debug('receive build');

        prompt[type] = {
          id: event.id!,
          message: event.success_message ?? event.error_message,
          success: !!event.success_message,
          timestamp: event.timestamp,
        };

        return { chat: arr };
      }

      if (type === 'code') {
        prompt[type] = response as any;
      }

      return { chat: arr };
    }),
  updateCode: (value: string) =>
    set(state => {
      const lastIndex = state.chat.length - 1;
      const code = state.chat[lastIndex].code;

      if (code) {
        code.content = value;
      }

      return state;
    }),

  updateReviewResults: (payload: any) =>
    set(state => {
      const arr = state.chat;

      arr[state.currentPrompt].test = payload;

      return { chat: arr };
    }),
});
