import { FormEvent, useEffect } from 'react';
import { useAuth } from '@packages/hooks';
import { Navigate } from '@tanstack/react-router';
import { AuthProgress, Login } from '@packages/ui';
import { LoginRes } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { UserClient } from '@api/client';
import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useRoutinesStore } from '@app/stores/routine.store.ts';
import useDashboardStore from '@rangpt/stores/useDashboardStore.ts';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';

export const LoginPage = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    loadingTokenUser,
  } = useAuth();
  const { resetsTore: resetFlowChart } = flowChartStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetRoutines } = useRoutinesStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetDashboard } = useDashboardStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  const { resetsTore: resetChat } = useCommandInterfaceStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  useEffect(() => {
    resetRoutines();
    resetFlowChart();
    resetDashboard();
    resetChat();
  }, []);

  const userAPI = new UserClient();

  const loginMutation = useMutation({
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => userAPI.login({ username, password }),
    onSuccess: (data: LoginRes) => {
      if (data.owner) {
        localStorage.setItem('owner', data.owner);
      } else {
        localStorage.removeItem('owner');
      }
      if (data.token) {
        localStorage.removeItem('token');
        localStorage.setItem('token', data.token);
      }
      // @TODO: maybe add data validation here, as this state needs all of this info
      setCurrentUser({
        name: data.user,
        isAdmin: !!data.owner,
        sessionToken: data.token,
        owner: data.owner ?? '',
      });
      setTimeout(() => {
        setIsAuthenticated(true);
      }, 500);
    },
  });

  if (loadingTokenUser) {
    return <AuthProgress />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData) as { user: string; pass: string };

    loginMutation.mutate({ username: data.user.trim(), password: data.pass });
  };

  return (
    <Login.Container>
      <Login.Form
        submit={submit}
        error={loginMutation.error}
        isLoading={loginMutation.isPending}
      />
    </Login.Container>
  );
};
