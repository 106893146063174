const EditOptions = () => {
  // const { dashboard } = useDashboard();
  // const [gridOpen, setGridOpen] = useState(false);
  //
  // const clickOutsideCallback = () => {
  //   setGridOpen(false);
  // };
  //
  // const ref = useOutsideClick<HTMLButtonElement>(clickOutsideCallback);
  //
  // const deleteGraph = () => {
  //   deleteDashboardChart(dashboard.charts[index].chart_id)
  //     .then(res => console.log(res))
  //     .catch(err => console.log(err));
  //   // removeGraph(index);
  // };
  //
  // const manageGrid = () => {
  //   setGridOpen(!gridOpen);
  // };
  //
  // return (
  //   <section className="flex gap-2 p-1 absolute right-2 top-2">
  //     {/*<ChangePrompt chat={chat} index={index} />*/}
  //     {dashboard.charts.length > 1 && (
  //       <section className="relative w-fit">
  //         <button
  //           className="rounded-md bg-white w-full h-full p-2 px-3 ring-1 ring-inset ring-gray-300 focus:outline-none cursor-pointer"
  //           onClick={manageGrid}
  //           ref={ref}
  //         >
  //           <Grid width={16} height={16} />
  //         </button>
  //         {gridOpen && (
  //           <SetGrid
  //             graphs={dashboard.charts}
  //             length={dashboard.charts.length}
  //             graphIndex={index}
  //             setGridOpen={setGridOpen}
  //           />
  //         )}
  //       </section>
  //     )}
  //     <button
  //       className="rounded-md bg-white p-2 px-3 ring-1 ring-inset ring-gray-300 focus:outline-none cursor-pointer"
  //       onClick={deleteGraph}
  //     >
  //       <Trash width={16} height={16} />
  //     </button>
  //   </section>
  // );
  return <></>;
};

export { EditOptions };
