import { create } from 'zustand';
import { clientDatabase, FeatureList, tables } from '@packages/api';

interface UseClientStoreState {
  features: FeatureList[];
  setFeatures: (features: FeatureList[]) => void;
  tables: tables[];
  setTables: (tables: tables[]) => void;
  databaseInfo: clientDatabase[];
  setDatabaseInfo: (databaseInfo: clientDatabase[]) => void;
  memoryList: string[];
  setMemoryList: (memoryList: string[]) => void;
  isAllColumnsChecked: boolean;
  setIsAllColumnsChecked: (isAllColumnsChecked: boolean) => void;
}

/*
 * This store represents Client state data (as in history) and is in sync with data format of back-end
 * */
export const useClientStore = create<UseClientStoreState>(set => ({
  features: [],
  setFeatures: features => set({ features }),
  tables: [],
  setTables: tables => set({ tables }),
  databaseInfo: [],
  setDatabaseInfo: databaseInfo => set({ databaseInfo }),
  memoryList: [],
  setMemoryList: (memoryList: string[]) =>
    set({
      memoryList,
    }),
  isAllColumnsChecked: false,
  setIsAllColumnsChecked: (isAllColumnsChecked: boolean) =>
    set({ isAllColumnsChecked }),
}));
