import { SocketStore } from './stores';
import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { WS_URL } from '@packages/api';
import { useAuth } from './useAuth.ts';

interface UseSocket {
  socket: Socket | null;
  socketExecLoading: boolean;
  setSocketExecLoading: (exec: boolean) => void;
  chat_id: string;
  emitter: (event: string, payload: Record<string, unknown>) => void;
}

const removeFalseAndNull = (obj: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== false && v !== null),
  );

export const useSocket = (backoffice = false): UseSocket => {
  const {
    socket,
    setSocket,
    socketExecLoading,
    setSocketExecLoading,
    chat_id,
  } = SocketStore();

  const { currentUser } = useAuth();

  useEffect(() => {
    if (socket) return;

    const ws: Socket = io(WS_URL, {
      reconnectionDelayMax: 2000,
      reconnectionAttempts: 2,
      path: '/socket.io',
      query: removeFalseAndNull({
        backoffice,
        chat_id,
        username: currentUser?.name,
      }),
    });

    ws.on('connect', function () {
      console.log('Connected to the server!');
    });

    ws.on('disconnect', function () {
      console.log('Disconnected to the server!');
    });

    setSocket(ws);
  }, []);

  function emitter(event: string, payload: Record<string, unknown>) {
    socket?.emit(event, {
      chat_id: chat_id,
      ...payload,
    });
  }

  return {
    socket,
    socketExecLoading,
    setSocketExecLoading,
    chat_id,
    emitter,
  };
};
