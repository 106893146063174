import { create } from 'zustand';
import { UIStateStore, VisualizationModeEnum } from '@app/types/ui.types.ts';

const uiStoreInitialData = {
  flowChartStep: null,
  isCodeEnabled: false,
  currentFeedback: null,
  visibleTables: {
    initial: true,
    expected: true,
  },
  visibleTestCases: {
    pass: true,
    fail: true,
  },
  selectedColumns: [],
  visualizationMode: {
    cases: false,
    table: true,
  },
  objectivesChangesMade: [],
};

export const UiStore = create<UIStateStore>((set, get) => ({
  ...uiStoreInitialData,
  setFlowChartStep: step => set({ flowChartStep: step }),
  setIsCodeEnabled: isCodeEnabled => set({ isCodeEnabled: isCodeEnabled }),
  setCurrentFeedback: feedback => set({ currentFeedback: feedback }),
  setVisibleTables: visibleTables => set(() => ({ visibleTables })),
  setVisibleTestCases: visibleTestCases => set(() => ({ visibleTestCases })),
  setSelectedColumns: selectedColumns => set(() => ({ selectedColumns })),
  visualizationModeSwitch: (mode: VisualizationModeEnum) =>
    set({
      visualizationMode: {
        cases: mode === VisualizationModeEnum.CASES,
        table: mode === VisualizationModeEnum.TABLE,
      },
    }),
  addObjectiveChange: (change: string) =>
    set(() => {
      const currentArr = get().objectivesChangesMade;

      if (currentArr.length < 6) {
        return { objectivesChangesMade: [change, ...currentArr] };
      }

      // Remove last item by creating new array without it
      const newArr = currentArr.slice(0, -1);

      // Add new item at start
      return { objectivesChangesMade: [change, ...newArr] };
    }),
  setObjectivesChange: changes => set({ objectivesChangesMade: changes }),
}));
