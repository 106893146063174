import { UpdateUserPayload } from '@packages/api';

const isObject = (object: any) =>
  typeof object === 'object' && !Array.isArray(object);

export const removeEmptyFieldsFromObject = (object: UpdateUserPayload) => {
  if (!isObject(object)) return;

  const newObj = {};
  Object.keys(object).forEach(key => {
    // @ts-ignore
    if (object[key]) {
      // @ts-ignore
      newObj[key] = object[key];
    }
  });

  return newObj;
};
