const colorPairs = [
  { text: '#3C82F6', bg: '#EFF6FF' },
  { text: '#12B981', bg: '#EFF6FF' },
  { text: '#F97315', bg: '#EFF6FF' },
];

export const randomKeyColor = () => {
  const randomIndex = Math.floor(Math.random() * colorPairs.length);

  return colorPairs[randomIndex];
};
