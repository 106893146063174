import { UiStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useEffect, useState } from 'react';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';

export const ChangesMade = () => {
  const [wordsToShow, setWordsToShow] = useState<string[]>([]);
  const { objectivesChangesMade } = UiStore(
    useShallow(state => ({
      objectivesChangesMade: state.objectivesChangesMade,
    })),
  );
  const { keyWordsList } = useObjectivesStore(
    useShallow(state => ({
      keyWordsList: state.keyWords,
    })),
  );

  useEffect(() => {
    // Transform keyWordsList into single array of strings
    const allWords = keyWordsList.reduce<string[]>((acc, item) => {
      return [...acc, item.key, ...item.list];
    }, []);

    setWordsToShow(allWords);
  }, [keyWordsList]);

  return (
    <section className="w-full h-full p-4 border rounded-md mb-8 gap-2">
      <h3 className="text-sm mb-2 max-h-full overflow-scroll text-gray-500">
        Changes made
      </h3>
      {objectivesChangesMade.length === 0 && (
        <section className="w-full h-full flex items-center justify-center">
          <span className="text-sm text-dark-1">No changes yet.</span>
        </section>
      )}
      {objectivesChangesMade.map(change => (
        <p key={change}>
          {change.split(' ').map((word, index) => {
            const isKeyword = wordsToShow.includes(word);

            return (
              <>
                {isKeyword ? (
                  <span className="text-sm rounded-md bg-blue-50 text-blue-400">
                    {word}
                  </span>
                ) : (
                  <span className="text-sm">{word}</span>
                )}
                {index < change.split(' ').length - 1 && ' '}
              </>
            );
          })}
        </p>
      ))}
    </section>
  );
};
