const WelcomeIcon = () => (
  <svg
    width="100"
    height="101"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="100" height="100" rx="10" fill="#E02227" fillOpacity="0.1" />
    <g clipPath="url(#clip0_303_2023)">
      <path
        d="M64.666 32.38L67.206 33.278L68.052 35.816C68.188 36.224 68.572 36.5 69.002 36.5C69.432 36.5 69.814 36.224 69.952 35.816L70.794 33.29L73.32 32.448C73.728 32.312 74.004 31.93 74.004 31.5C74.004 31.07 73.728 30.688 73.32 30.552L70.794 29.71L69.952 27.184C69.678 26.368 68.328 26.368 68.054 27.184L67.216 29.698L64.704 30.484C64.294 30.612 64.01 30.988 64.002 31.42C63.994 31.852 64.26 32.238 64.666 32.38Z"
        fill="black"
      />
      <path
        d="M73.372 64.572L68.056 62.446L65.93 57.13C65.63 56.37 64.376 56.37 64.074 57.13L61.95 62.436L56.648 64.442C56.266 64.588 56.01 64.95 56.002 65.358C55.994 65.766 56.236 66.14 56.614 66.298L61.944 68.544L64.074 73.87C64.224 74.25 64.594 74.498 65.002 74.498C65.41 74.498 65.78 74.248 65.93 73.87L68.056 68.554L73.372 66.428C73.752 66.276 74 65.908 74 65.5C74 65.092 73.752 64.724 73.372 64.572ZM66.914 66.858C66.66 66.96 66.458 67.162 66.358 67.416L65 70.808L63.642 67.416C63.544 67.168 63.35 66.97 63.102 66.866L59.692 65.428L63.068 64.15C63.33 64.05 63.538 63.846 63.642 63.586L65 60.194L66.358 63.586C66.46 63.84 66.66 64.042 66.914 64.144L70.306 65.502L66.914 66.858Z"
        fill="black"
      />
      <path
        d="M63.87 51.418C65.164 51.004 66 49.858 66 48.5C66 47.142 65.164 45.996 63.846 45.574L52.312 42.19L48.956 30.672C48.56 29.372 47.424 28.52 46.066 28.502C44.734 28.558 43.552 29.306 43.108 30.628L39.69 42.188L28.17 45.546C26.87 45.942 26.018 47.078 26 48.436C25.982 49.794 26.804 50.95 28.126 51.394L39.69 54.812L43.082 66.372C43.496 67.666 44.642 68.502 46 68.502C47.358 68.502 48.504 67.666 48.926 66.348L52.312 54.814L63.846 51.43L63.87 51.422V51.418ZM63.27 49.51L51.226 53.046C50.898 53.142 50.644 53.398 50.548 53.724L47.016 65.76C46.796 66.446 46.184 66.5 46.002 66.5C45.82 66.5 45.208 66.446 44.996 65.784L41.456 53.726C41.36 53.4 41.106 53.144 40.78 53.048L28.73 49.486C28.046 49.258 28.002 48.644 28.004 48.462C28.006 48.28 28.068 47.668 28.746 47.462L40.78 43.956C41.108 43.862 41.364 43.606 41.46 43.28L45.022 31.23C45.246 30.562 45.836 30.504 46.032 30.504H46.044C46.226 30.506 46.838 30.568 47.044 31.246L50.55 43.278C50.646 43.606 50.9 43.862 51.228 43.958L63.264 47.49C63.952 47.71 64.004 48.322 64.004 48.504C64.004 48.686 63.952 49.292 63.274 49.514L63.27 49.51Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_303_2023">
        <rect width="48" height="48" fill="white" transform="translate(26 26.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { WelcomeIcon };
