import { Input as FileInput } from '@packages/ui/shadcn/ui/input.tsx';

import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { UploadIcon } from 'lucide-react';

export const Upload = ({
  handleLoadCsv,
}: {
  handleLoadCsv: (csv: string) => void;
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();

    reader.onload = e => {
      if (!e.target?.result) return;
      const file = acceptedFiles[0];

      const fileType = file.type.split('/').pop();

      if (fileType === 'csv') {
        acceptedFiles[0].text().then(text => {
          handleLoadCsv(text);
        });
      }
    };

    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const { getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  return (
    <section>
      <label
        htmlFor="csv"
        className="cursor-pointer flex gap-2 text-white text-xs items-center rounded-md bg-dark-1 px-4 h-[2.07rem] shadow-md"
      >
        Add your CSV
        <UploadIcon width={16} height={16} color="#fff" />
      </label>

      <FileInput
        id="csv"
        type="file"
        disabled={false}
        className="w-2 hidden text-sm text-stone-500 file:hidden"
        {...getInputProps({
          onClick: e => {
            const { currentTarget } = e || {};
            currentTarget.value = '';
          },
        })}
      />
    </section>
  );
};
