import { API_URL } from '../configs';
import { RoutinePayload, RoutineRes, RoutinesList } from '@api/types';

interface Routine {
  auth: string;
}

export class RoutineClient {
  public defaultHeaders: Record<string, string>;

  constructor({ auth }: Routine) {
    this.defaultHeaders = {
      authorization: auth,
      'Content-Type': 'application/json',
    };
  }

  public getRoutine(id: string): Promise<RoutineRes> {
    return fetch(`${API_URL}/app/routine/${id}`, {
      headers: this.defaultHeaders,
      method: 'GET',
    }).then<RoutineRes>(res => {
      if (!res.ok) {
        if (res.status === 401) {
          throw new Error('Routine not found!');
        }

        throw new Error('Failed to get routine!');
      }

      return res.json();
    });
  }

  public listRoutines() {
    return fetch(`${API_URL}/app/list/routines`, {
      method: 'GET',
      headers: this.defaultHeaders,
    }).then<RoutinesList>(res => {
      if (!res.ok) {
        throw new Error('Failed to get routines!');
      }

      return res.json();
    });
  }

  public createRoutine(payload: RoutinePayload) {
    return fetch(`${API_URL}/app/routine`, {
      headers: this.defaultHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    }).then(res => {
      if (!res.ok) {
        throw new Error('Failed to create routine!');
      }

      return res.json();
    });
  }

  public updateRoutine(payload: Partial<RoutinePayload>) {
    return fetch(`${API_URL}/app/routine`, {
      headers: this.defaultHeaders,
      method: 'PATCH',
      body: JSON.stringify(payload),
    }).then(res => {
      if (!res.ok) {
        throw new Error('Failed to update routine!');
      }

      console.debug(res);

      return res.json();
    });
  }

  public deleteRoutine(id: string) {
    return fetch(`${API_URL}/app/routine/${id}`, {
      method: 'DELETE',
      headers: this.defaultHeaders,
    }).then<{ message: string; id: string }>(res => {
      if (!res.ok) {
        throw new Error('Failed to delete routine!');
      }
      return res.json();
    });
  }
}
