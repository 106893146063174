import { Fragment, memo } from 'react';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';
import { LineOverlay } from '@components/ObjectivesArea/lineOverlay.tsx';
import { useShallow } from 'zustand/react/shallow';
import { UiStore } from '@app/stores';

interface HandleAddSelectKey {
  currentWord: string;
  newWord: string;
  position: number;
}

export function OverlayDivComponent({
  handleAddSelectedKey,
}: {
  handleAddSelectedKey: (_: HandleAddSelectKey) => void;
}) {
  const {
    processedObjectives,
    keyWordsList,
    addKeyWordList,
  } = useObjectivesStore(
    useShallow(state => ({
      processedObjectives: state.processedObjectives,
      keyWordsList: state.keyWords,
      addKeyWordList: state.addKeyWordsList,
    })),
  );

  if (!processedObjectives) {
    return null;
  }

  const addObjectiveChange = UiStore(state => state.addObjectiveChange);

  return (
    <div className="absolute top-0 w-full h-[calc(100%-1rem)] bg-neutral-200 rounded-md p-4 pt-10 text-sm outline-none focus:outline-none overflow-scroll">
      {processedObjectives.map((line, index) => (
        <Fragment key={index}>
          <LineOverlay
            line={line}
            lineIndex={index}
            handleAddSelectedKey={handleAddSelectedKey}
            keyWordsList={keyWordsList}
            addKeyWordList={addKeyWordList}
            addObjectiveChange={addObjectiveChange}
          />
          <br />
        </Fragment>
      ))}
    </div>
  );
}

export const ObjectivesOverlayDiv = memo(OverlayDivComponent);
