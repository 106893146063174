import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { ReactNode } from 'react';

interface KeySelectProps {
  isOpen: boolean;
  children: ReactNode;
}

export const KeyList = ({ isOpen, children }: KeySelectProps) => {
  return (
    <div
      className={cn(
        'absolute left-0 mt-1 min-w-32 max-w-64 max-h-60 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-30 overflow-auto',
        'transition-all duration-200 ease-in-out',
        isOpen
          ? 'opacity-100 translate-y-0'
          : 'opacity-0 translate-y-1 pointer-events-none',
      )}
    >
      <div className="p-2 overflow-y-scroll max-h-full flex flex-col gap-2 break-words z-50">
        {children}
      </div>
    </div>
  );
};
