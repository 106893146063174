import { create } from 'zustand';
import { CodeConsoleStoreState, DefaultCodeStepsProps } from '@app/types';

const codeStepsInitialData: DefaultCodeStepsProps = {
  content: null,
  updatedAt: null,
  loading: false,
};

export const useCodeStore = create<CodeConsoleStoreState>(set => ({
  code: codeStepsInitialData,
  setCode: code => set({ code }),
  review: codeStepsInitialData,
  setReview: review => set({ review }),
}));
