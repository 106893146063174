import {
  clientDatabase,
  tables,
  TablesToSendToWs,
  WsTables,
} from '@packages/api/types';

interface FormatDatabaseTables {
  currentDatabase: string;
  form: clientDatabase;
}

interface FormatTablesToSelectedColumns {
  database_type: string;
  database_name: string;
  tables: tables[];
}

export class Formatter {
  public databaseInfo({ currentDatabase, form }: FormatDatabaseTables) {
    // Remove spaces from the string
    const strWithoutSpaces = form.tables.replace(/\s/g, '');

    return {
      type: currentDatabase,
      tables: strWithoutSpaces.split(','),
      host: form.host,
      database: form.database,
      port: form.port,
      user: form.user,
      password: form.password,
    };
  }

  public webSocketTables(tables: WsTables) {
    return Object.entries(tables).map(([tableName, columns]) => {
      const result = {
        table_name: {
          name: tableName,
          description: undefined,
        },
      } as tables;

      columns.forEach(column => {
        result[column] = {
          description: undefined,
          isSelected: false,
        };
      });

      return result;
    });
  }

  public CsvToTables(tables: tables[], csvData: string) {
    const lines = csvData.trim().split('\n');
    const dataLines = lines.slice(1);

    dataLines.forEach(line => {
      const [db_column, description] = line.split(',');

      // Iterate through each object in the tables array
      tables.forEach(table => {
        if (table.hasOwnProperty(db_column)) {
          table[db_column].description = description.trim();
        }
      });
    });

    return tables.map(({ ['table_name']: _, ...rest }) => rest);
  }

  public csvToMemoryList(csvContent: string) {
    const lines = csvContent.split('\n');

    // Validate the first line
    if (lines[0].trim() !== 'knowledge_base' || lines[0].includes(',')) {
      console.log('wrong format');
      return null;
    }

    // Process the rest of the lines
    return lines
      .slice(1)
      .map(line => line.trim())
      .filter(line => line !== '');
  }

  public tablesToSelectedColumns({
    database_type,
    database_name,
    tables,
  }: FormatTablesToSelectedColumns): TablesToSendToWs {
    const result: TablesToSendToWs = {
      [database_name]: {
        type: database_type,
        tables: {},
      },
    };

    tables.forEach(data => {
      const tableName = data.table_name.name;

      // Filter keys with isSelected === true and create ColumnInfo objects
      const selectedColumns = Object.entries(data)
        // @ts-ignore
        .filter(([key, value]) => key !== 'table_name' && value.isSelected)
        .map(([key, value]) => ({
          name: key,
          description: value.description,
        }));

      // Assign the filtered columns to the result under the table_name.name
      if (selectedColumns.length > 0) {
        result[database_name].tables[tableName] = selectedColumns;
      }
    });

    return result;
  }
}
