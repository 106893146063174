import { create } from 'zustand';
import { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

interface SocketStoreState {
  socket: Socket | null;
  setSocket: (socket: Socket) => void;
  socketExecLoading: boolean;
  setSocketExecLoading: (exec: boolean) => void;
  chat_id: string;
}

export const SocketStore = create<SocketStoreState>(set => ({
  socket: null,
  setSocket: socket => set({ socket }),
  socketExecLoading: false,
  setSocketExecLoading: exec => set({ socketExecLoading: exec }),
  chat_id: uuidv4(),
}));
