import { useEffect, useState } from 'react';
import { Link, useLocation } from '@tanstack/react-router';
import {
  ChevronRight,
  Command,
  Database,
  FileMinus,
  Grid,
  Settings,
  Tool,
  Users,
} from 'react-feather';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';

type RouteHandlerProps = {
  isActive: boolean;
  onClick: (route: string) => void;
  isOpen: boolean;
  pathname?: string;
};

const SidebarButton = ({
  children,
  isActive,
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { isActive?: boolean }) => (
  <span
    className={cn(
      'flex items-center justify-start text-sm font-medium gap-4 p-4 w-full rounded-lg bg-transparent text-dark-300 text-nowrap cursor-pointer',
      isActive && 'border border-dark-1',
      className,
    )}
    {...props}
  >
    {children}
  </span>
);

const DashboardRoute = ({ isActive, onClick, isOpen }: RouteHandlerProps) => {
  const handleNestedClick = () => {
    onClick('dashboard');
  };

  return (
    <SidebarButton isActive={isActive} onClick={handleNestedClick}>
      <Grid className={cn('w-5 h-5', !isOpen && 'm-auto')} />
      {isOpen && <span>My Dashboard</span>}
    </SidebarButton>
  );
};

const AppBuilderRoute = ({ isActive, onClick, isOpen }: RouteHandlerProps) => {
  // const { currentRoutine } = useRoutines();
  const handleNestedClick = () => {
    onClick('app');
  };

  return (
    <div>
      <SidebarButton
        isActive={isActive}
        onClick={handleNestedClick}
        className="cursor-pointer"
      >
        <Tool className={cn('w-5 h-5', !isOpen && 'm-auto')} />
        {isOpen && <span>App Builder</span>}
      </SidebarButton>
      {/*{isActive && isOpen && (*/}
      {/*  <div className="ml-9 mt-2">*/}
      {/*    <Link*/}
      {/*      to="/app/$routine/flowchart"*/}
      {/*      params={{ routine: currentRoutine.id }}*/}
      {/*    >*/}
      {/*      {({ isActive: isFlowchartActive }) => (*/}
      {/*        <SidebarButton isActive={isFlowchartActive}>*/}
      {/*          <span>Flowchart</span>*/}
      {/*        </SidebarButton>*/}
      {/*      )}*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

const CommandInterfaceRoute = ({
  isActive,
  onClick,
  isOpen,
}: RouteHandlerProps) => {
  const handleNestedClick = () => {
    onClick('rangpt');
  };

  return (
    <SidebarButton isActive={isActive} onClick={handleNestedClick}>
      <Command className={cn('w-5 h-5', !isOpen && 'm-auto')} />
      {isOpen && <span>Command Interface</span>}
    </SidebarButton>
  );
};

const ManagementRoute = ({
  isActive,
  onClick,
  isOpen,
  pathname,
}: RouteHandlerProps) => {
  const handleNestedClick = () => {
    onClick('management');
  };

  const pathnameExactly = pathname === '/management';

  return (
    <div>
      <SidebarButton isActive={false} onClick={handleNestedClick}>
        <Users className={cn('w-5 h-5', !isOpen && 'm-auto')} />
        {isOpen && <span>Management</span>}
      </SidebarButton>
      {isActive && isOpen && (
        <div className="ml-9 mt-2 space-y-1">
          <Link to="/management" from="/">
            {({ isActive }) => (
              <SidebarButton>
                {isActive && pathnameExactly ? (
                  <ChevronRight className="w-5 h-5" />
                ) : (
                  <Users className="w-4 h-4" />
                )}
                <span>Accounts</span>
              </SidebarButton>
            )}
          </Link>
          <Link to="/management/database">
            {({ isActive }) => (
              <SidebarButton>
                {isActive ? (
                  <ChevronRight className="w-5 h-5" />
                ) : (
                  <Database className="w-4 h-4" />
                )}
                <span>Database</span>
              </SidebarButton>
            )}
          </Link>
          <Link to="/management/capability">
            {({ isActive }) => (
              <SidebarButton>
                {isActive ? (
                  <ChevronRight className="w-5 h-5" />
                ) : (
                  <Settings className="w-5 h-4" />
                )}
                <span>Capability</span>
              </SidebarButton>
            )}
          </Link>
          <Link to="/management/memory">
            {({ isActive }) => (
              <SidebarButton>
                {isActive ? (
                  <ChevronRight className="w-5 h-5" />
                ) : (
                  <FileMinus className="w-5 h-4" />
                )}
                <span>Memory</span>
              </SidebarButton>
            )}
          </Link>
        </div>
      )}
    </div>
  );
};

export const SidebarContent = ({ isOpen }: { isOpen: boolean }) => {
  const { pathname } = useLocation();

  const [activeRoute, setActiveRoute] = useState('dashboard');

  useEffect(() => {
    const firstPath = pathname.split('/')[1];
    setActiveRoute(firstPath);
  }, [pathname]);

  const handleClick = (route: string) => {
    setActiveRoute(route);
  };

  return (
    <div className="w-full">
      <Link to="/dashboard" from="/">
        <DashboardRoute
          isActive={activeRoute === 'dashboard'}
          onClick={handleClick}
          isOpen={isOpen}
        />
      </Link>
      <Link to="/app" from="/">
        <AppBuilderRoute
          isActive={activeRoute === 'app'}
          onClick={handleClick}
          isOpen={isOpen}
        />
      </Link>
      <Link to="/rangpt" from="/">
        <CommandInterfaceRoute
          isOpen={isOpen}
          isActive={activeRoute === 'rangpt'}
          onClick={handleClick}
        />
      </Link>
      <ManagementRoute
        isActive={activeRoute === 'management'}
        onClick={handleClick}
        isOpen={isOpen}
        pathname={pathname}
      />
    </div>
  );
};
