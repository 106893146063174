import { Answer } from '@rangpt/components/chat/answer.tsx';
import { Feedback } from '@rangpt/components/feedback';
import { PromptData } from '@rangpt/types';

interface FeedbackAlertProps {
  data: PromptData;
  currentPrompt: number;
  feedbackActions: {
    likePrompt: (index: number) => void;
    dislikePrompt: (index: number) => void;
    writeFeedback: (value: string, index: number) => void;
  };
}

export const FeedbackAlert = ({ data, currentPrompt, feedbackActions }: FeedbackAlertProps) => {
  const answersLength = data.answer!.length;

  return (
    <div className="flex gap-2 items-center justify-start">
      <section className="">
        <Answer
          key="feedback"
          text="You should give a feedback at like/dislike buttons to proceed."
          timestamp={data.answer![answersLength - 1].timestamp + 2}
          isLoading={false}
          isBlocked={true}
        />
      </section>

      <Feedback.Container
        loading={false}
        feedback={data.feedback}
        index={currentPrompt}
        feedbackActions={feedbackActions}
        fromTop={true}
      />
    </div>
  );
};
