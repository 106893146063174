import { useSocket } from '@packages/hooks';
import { WSCodeEvents } from '@app/types';
import { flowChartStore, UiStore } from '@app/stores';
import { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'react-toastify';
import { useCodeState } from '@app/hooks/useAppDevelopment/useCodeState.ts';
import { defaultToastErrorToastOptions } from '@packages/ui';
import {
  SaveCodePayload,
  SOCKET_EVENTS,
  TestClient,
  WS_STATUS_SUBJECTS,
  WSStatusEvent,
} from '@packages/api';
import { useTestStore } from '@app/stores/test.store.ts';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { useRoutinesStore } from '@app/stores/routine.store.ts';

export const useCodeDeveloping = () => {
  const { socket, emitter } = useSocket();

  const { code, review, setCode, setReview } = useCodeState();
  const id = flowChartStore(state => state.id);
  const setLoading = useTestStore(state => state.setLoading);
  const setCurrentFeedback = UiStore(state => state.setCurrentFeedback);
  const routineId = useRoutinesStore(state => state.currentRoutine.id);
  const navigate = useNavigate();

  const testApi = new TestClient();
  const queryClient = new QueryClient();

  function cleanStoreForNewCode() {
    setCode({
      content: null,
      loading: true,
      updatedAt: null,
    });
    setReview({
      content: null,
      loading: false,
      updatedAt: null,
    });
  }

  const saveCodeMutation = useMutation(
    {
      mutationFn: (payload: SaveCodePayload) => testApi.saveTestCode(payload),
      onSuccess: data => {
        console.debug('~ saveCodeMutation', data);
      },
    },
    queryClient,
  );

  useEffect(() => {
    if (!socket) return;

    socket?.on(SOCKET_EVENTS.FLOW_CHART_CODE, (event: WSCodeEvents) => {
      if (!event.code) {
        console.error('Received code event, but no code', event);
        return;
      }

      setCode({
        content: event.code,
        loading: false,
        updatedAt: event.timestamp,
      });

      saveCodeMutation.mutate({
        id,
        code: event.code,
        routineId,
      });
    });

    socket?.on(SOCKET_EVENTS.STATUS, (event: WSStatusEvent) => {
      if (event.subject === WS_STATUS_SUBJECTS.CODE) {
        if (event.status === 'pending') {
          setCurrentFeedback(null);
          cleanStoreForNewCode();
          return;
        }
        if (event.status === 'error') {
          setCurrentFeedback(event.reason);
          // @Todo: check what to do when error is received;
          if (event.subject === 'code') {
            setCode({
              content: null,
              loading: false,
              updatedAt: event.timestamp,
            });
            navigate({
              to: '/app/$routine/flowchart',
              params: { routine: id },
            });
          }

          toast.error(event.reason, defaultToastErrorToastOptions);
        }
      }
    });

    return () => {
      socket.off(SOCKET_EVENTS.FLOW_CHART_CODE);
      socket.off(SOCKET_EVENTS.STATUS);
    };
  }, [socket, id, routineId]);

  const reviewCode = (currentCode: string) => {
    const shouldUpdateCode = code.content !== currentCode;

    setCode({
      content: currentCode,
      loading: false,
      updatedAt: shouldUpdateCode ? new Date().getTime() : code.updatedAt,
    });

    emitter(SOCKET_EVENTS.REVIEW_CODE_AND_RUN, {
      code: currentCode,
      flow_chart_id: id,
      origin: 'app_builder',
    });
  };

  const testCode = (currentCode: string) => {
    const shouldUpdateCode = code.content !== currentCode;

    console.debug('~ on testCode event function', currentCode);

    setCode({
      content: currentCode,
      loading: false,
      updatedAt: shouldUpdateCode ? new Date().getTime() : code.updatedAt,
    });

    setLoading(true);

    emitter(SOCKET_EVENTS.TEST_APPLICATION, {
      code: currentCode,
      flow_chart_id: id,
      origin: 'app_builder',
    });

    navigate({
      to: '/app/$routine/code/test',
      params: { routine: id },
    });
  };

  const updateCodeOnHistory = (code: string) => {
    saveCodeMutation.mutate({
      id,
      code,
      routineId,
    });
  };

  return {
    code,
    review,
    reviewCode,
    testCode,
    updateCodeOnHistory,
  };
};
