import { ButtonHTMLAttributes, useState } from 'react';
import { cn } from '@app/lib/utils.ts';
import { Search } from 'react-feather';

interface SearchRoutineProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const FilterButton = ({
  children,
  selected,
  onClick,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean;
}) => {
  return (
    <button
      className={cn(
        'px-4 py-2 rounded-md shadow-md text-sm font-medium',
        selected ? 'bg-dark-300 text-white' : 'bg-white text-gray-700',
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export const SearchRoutine = ({
  searchTerm,
  setSearchTerm,
}: SearchRoutineProps) => {
  const [filter, setFilter] = useState('all');

  return (
    <div className="flex items-center gap-2">
      <div className="relative flex items-center">
        <label
          htmlFor="routine-search"
          className="absolute left-3 text-gray-400"
        >
          <Search width={16} height={16} />
        </label>
        <input
          id="routine-search"
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search routine"
          className={cn(
            'w-96 pl-10 pr-6 py-3 text-xs rounded-md border border-gray-300 outline-none focus:outline-none',
          )}
        />
      </div>
      <FilterButton
        selected={filter === 'all'}
        onClick={() => setFilter('all')}
      >
        All
      </FilterButton>
      {/*<FilterButton*/}
      {/*  selected={filter === 'enabled'}*/}
      {/*  onClick={() => setFilter('enabled')}*/}
      {/*>*/}
      {/*  Enabled*/}
      {/*</FilterButton>*/}
      {/*<FilterButton*/}
      {/*  selected={filter === 'disabled'}*/}
      {/*  onClick={() => setFilter('disabled')}*/}
      {/*>*/}
      {/*  Disabled*/}
      {/*</FilterButton>*/}
    </div>
  );
};
