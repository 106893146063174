import { ButtonHTMLAttributes } from 'react';
import { cn } from '@app/lib/utils.ts';

interface StepperButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
}

export const StepperButton = ({
  children,
  className,
  disabled,
  selected,
  ...props
}: StepperButtonProps) => {
  return (
    <button
      className={cn(
        'py-2 px-12 rounded-lg text-center text-sm font-medium bg-dark-100 text-dark-200',
        selected
          ? 'bg-dark-300 text-white hover:opacity-90 hover:shadow-xl'
          : 'bg-white text-gray-700',
        disabled ? 'opacity-50 bg-dark-200 text-dark-1' : '',
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
