import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '@rangpt/components/ui/drawer.tsx';
import { Code as CodeIcon, Trash } from 'react-feather';
import { Markup } from '@rangpt/components/code/markup.tsx';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';

interface ChartOptionsProps {
  code: string;
  handleDelete: () => void;
}

export const DashChartOptions = ({ code, handleDelete }: ChartOptionsProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const triggerSizeAnalise = () => {
    setTimeout(() => {
      if (!ref.current) return;

      const editor = ref.current;
      const contentIsBigger =
        ref.current.scrollHeight > ref.current.offsetHeight;

      if (contentIsBigger) {
        if (editor.classList.contains('overflow-shadow')) return;
        editor.classList.add('overflow-shadow');
      }
    }, 30);
  };

  const scrollListener = () => {
    if (!ref.current) return;
    const editor = ref.current;

    if (editor.scrollTop + editor.offsetHeight + 1 >= editor.scrollHeight) {
      if (!editor.classList.contains('overflow-shadow')) return;
      editor.classList.remove('overflow-shadow');
      return;
    }
    if (editor.classList.contains('overflow-shadow')) return;
    editor.classList.add('overflow-shadow');
  };

  return (
    <div className="hidden group-hover:flex flex-col gap-2 justify-center items-center absolute top-2 right-4 z-10">
      <Drawer>
        <DrawerTrigger
          className="py-2 px-3 ring-1 ring-inset ring-gray-300 focus:outline-none cursor-pointer rounded-md"
          onClick={triggerSizeAnalise}
        >
          <CodeIcon width={16} height={16} className="m-auto" />
        </DrawerTrigger>
        <DrawerContent className="max-h-code min-h-sm p-2">
          <h2 className="text-xl ">
            <span className="fade-in">Chart Code</span>
          </h2>
          <div
            className="overflow-auto w-full max-h-code h-code fade-in"
            ref={ref}
            onScroll={scrollListener}
          >
            <Markup
              code={{
                id: uuidv4(),
                content: code,
                timestamp: Date.now() / 1000,
                type: 'chart',
              }}
              readOnly={true}
              updateCode={() => null}
            />
          </div>
        </DrawerContent>
      </Drawer>
      <button
        className="rounded-md bg-white py-2 px-3 ring-1 ring-inset ring-gray-300 focus:outline-none cursor-pointer"
        onClick={handleDelete}
      >
        <Trash width={16} height={16} />
      </button>
    </div>
  );
};
