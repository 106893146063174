import { Handle, NodeResizer, Position } from '@xyflow/react';
import { ShapesProps } from '@app/types';
import { cn } from '@app/lib/utils.ts';

export const RectangleShape = ({
  selected,
  children,
  data,
  resized,
  onResizedChange,
}: ShapesProps) => {
  const hasObjectives = data.objectives ? data.objectives.length > 1 : false;

  return (
    <section
      className={cn(
        'w-full h-full min-w-[122px] min-h-[61px] rounded-lg shadow-xl flex overflow-hidden',
        hasObjectives ? 'bg-dark-300' : 'bg-white',
        resized ? 'w-full h-full' : 'w-[122px] h-[61px]',
      )}
    >
      <NodeResizer
        color="#ff0071"
        isVisible={selected}
        minWidth={122}
        minHeight={61}
        maxHeight={240}
        maxWidth={420}
        onResize={onResizedChange}
        handleClassName="!w-2 !h-2"
        keepAspectRatio
      />
      {children}
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Left} />
      <Handle type="target" position={Position.Right} />
      <Handle type="source" position={Position.Right} />
    </section>
  );
};
