import { ReactElement } from 'react';
import { FeatureCard } from './featureCard.tsx';
import { LayoutIcon, LightbulbIcon, ListTodoIcon } from 'lucide-react';
import { useAccManagement } from '@management/hooks';
import { FEATURE_TYPE, FeatureList } from '@packages/api';
import { Smartphone } from 'react-feather';

export const CapabilityContainer = () => {
  const {
    clientFeatures,
    setClientFeatures,
    updateClientMutation,
  } = useAccManagement();

  const features: { type: FEATURE_TYPE; icon: ReactElement; name: string }[] = [
    {
      type: FEATURE_TYPE.DASHBOARD,
      icon: <LayoutIcon width={30} height={38} />,
      name: 'Dashboard',
    },
    {
      type: FEATURE_TYPE.TOOLS,
      icon: <ListTodoIcon width={30} height={38} />,
      name: 'Tools List',
    },
    {
      type: FEATURE_TYPE.INSIGHTS,
      icon: <LightbulbIcon width={30} height={38} />,
      name: 'Insights',
    },
    {
      type: FEATURE_TYPE.APP_BUILDER,
      icon: <Smartphone width={30} height={38} />,
      name: 'App Builder',
    },
  ];

  const updateClientFeatures = (arr: FeatureList[]) => {
    console.debug(
      JSON.stringify({ features: arr }, (_k, v: unknown) => v ?? undefined),
    );
    updateClientMutation.mutate({
      features: arr,
    });
  };

  return (
    <div className="w-full h-full bg-transparent flex flex-col items-center justify-start gap-12 px-28 py-10 m-auto">
      <header className="w-full flex items-center justify-between">
        <h2 className="font-medium">Capability Management</h2>
      </header>
      <div className="w-full flex items-start gap-6 flex-wrap">
        {features.map(feature => {
          const onCheck = (checked: boolean) => {
            let newList: FeatureList[] = [];

            if (clientFeatures.length > 0) {
              newList = clientFeatures.map(feat =>
                feat.type === feature.type
                  ? { ...feat, isActive: checked }
                  : feat,
              );
            } else {
              newList = features.map(
                feat =>
                  ({
                    type: feat.type,
                    isActive: feat.type === feature.type,
                  } as FeatureList),
              );
            }

            setClientFeatures(newList);
            updateClientFeatures(newList);
          };
          return (
            <FeatureCard
              key={feature.type}
              icon={feature.icon}
              type={feature.type}
              name={feature.name}
              isActive={
                !!clientFeatures.find(feat => feat.type === feature.type)
                  ?.isActive
              }
              onChecked={onCheck}
            />
          );
        })}
      </div>
    </div>
  );
};
