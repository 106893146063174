// @ts-nocheck
import { token } from '@packages/api';
import { API_URL } from '@api/configs.ts';


// @TODO: fix any types here
export class SessionAPI {
  private readonly defaultHeaders: HeadersInit;

  constructor() {
    this.defaultHeaders = {
      authorization: token,
      'Content-Type': 'application/json',
    };
  }

  private async fetchWithErrorHandling(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      const result: { error: string } = await response.json();
      throw new Error(result.error);
    }
    return response.json();
  }

  public async getNewestHistory(username: string): Promise<any> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return await this.fetchWithErrorHandling(`${API_URL}/session?username=${username}`, {
        method: 'GET',
        headers: this.defaultHeaders,
      });
    } catch (error) {
      throw new Error(`Failed to fetch newest history: ${error.message}`);
    }
  }

  public async getSessionById(id: string): Promise<any> {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/session/${id}`, {
        method: 'GET',
        headers: this.defaultHeaders,
      });
    } catch (error) {
      throw new Error(`Failed to fetch session by ID: ${error.message}`);
    }
  }

  public async updatePrompt(prompt: any): Promise<any> {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/prompt`, {
        method: 'PATCH',
        headers: this.defaultHeaders,
        body: JSON.stringify(prompt),
      });
    } catch (error) {
      throw new Error(`Failed to update prompt: ${error.message}`);
    }
  }

  public async updateSession(payload: any) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/session`, {
        method: 'PATCH',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error) {
      throw new Error(`Failed to update session: ${error.message}`);
    }
  }

  public async postCodeReview(payload: Record<string, string | number | boolean>) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/chat/review`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error) {
      throw new Error(`Failed to post code review: ${error.message}`);
    }
  }

  public async createSession(payload: any) {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/session`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error) {
      throw new Error(`Failed to create session: ${error.message}`);
    }
  }
}
