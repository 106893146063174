import { Prompt } from '@rangpt/components/chat/prompt.tsx';
import { Answer } from '@rangpt/components/chat/answer.tsx';
import { PromptData } from '@rangpt/types';
import { MutableRefObject } from 'react';

interface ChatContentProps {
  chat: PromptData[];
  answersContainerRefs: MutableRefObject<HTMLDivElement[]>;
  indexToShowShadow: number;
}

export const Content = ({
  chat,
  answersContainerRefs,
  indexToShowShadow,
}: ChatContentProps) => {
  if (chat.length > 0)
    return chat.map((prompt, index) => {
      return (
        <div
          key={prompt.prompt_id}
          className="w-full flex flex-col h-fit pt-0"
          ref={ref => (answersContainerRefs.current[index] = ref!)}
        >
          <Prompt
            promptData={prompt}
            index={index + 1}
            showShadow={indexToShowShadow === index}
          />
          {prompt.answer?.map(answer => (
            <Answer
              key={answer.id}
              text={answer.content}
              timestamp={answer.timestamp}
              isLoading={answer.isLoading}
            />
          ))}
        </div>
      );
    });
};
