import { ReactNode, useState } from 'react';
import { Input } from '@packages/ui/shadcn/ui/input.tsx';
import { DefaultModal } from '@components/ui/DefaultModal.tsx';

export const RenameModal = ({
  onConfirm,
  children,
  name,
  loading,
}: {
  onConfirm: (value: string) => void;
  children: ReactNode;
  name: string;
  loading: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');

  const redefine = () => {
    if (inputValue.length === 0) return;

    onConfirm(inputValue);
    setInputValue('');
  };

  return (
    <DefaultModal
      trigger={children}
      onConfirm={redefine}
      title={`Rename: ${name}`}
      loading={loading}
    >
      <Input
        id="name"
        placeholder="Routine name"
        className="w-full outline-none focus:outline-none ring-0 focus:ring-0 focus-visible:ring-0 !focus:ring-offset-0 text-xs"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
    </DefaultModal>
  );
};
