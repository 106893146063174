import { Panel } from '@xyflow/react';
import { Square } from 'react-feather';
import { Circle, Diamond, RectangleHorizontal } from 'lucide-react';
import { FlowChartNode } from '@app/types';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@packages/ui/shadcn/ui/tooltip.tsx';
import { ReactNode } from 'react';

interface MenuPanelProps {
  handleAddNode: (args: Partial<FlowChartNode>) => void;
}

const ToolTipWrapper = ({
  children,
  text,
}: {
  children: ReactNode;
  text: string;
}) => {
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const MenuPanel = ({ handleAddNode }: MenuPanelProps) => {
  function addDiamond() {
    handleAddNode({
      type: 'diamond',
      data: {
        label: 'Name the process',
      },
    });
  }

  function addSquare() {
    handleAddNode({
      type: 'square',
      data: {
        label: 'Name the process',
      },
    });
  }

  function addCircle() {
    handleAddNode({
      type: 'circle',
      data: {
        label: 'Name the process',
      },
    });
  }

  function addRectangle() {
    handleAddNode({
      type: 'rectangle',
      data: {
        label: 'Name the process',
      },
    });
  }

  return (
    // I'm adjusting the padding bottom, because apparently the tooltip is making the panel bigger
    <Panel
      position="top-left"
      className="p-4 pb-3 bg-white shadow-lg rounded-md flex flex-col items-center gap-6"
    >
      <ToolTipWrapper text="Decision">
        <button
          className="w-full h-full disabled:opacity-40"
          onClick={addDiamond}
        >
          <Diamond className="m-auto" width={18} height={18} />
        </button>
      </ToolTipWrapper>
      <ToolTipWrapper text="Process">
        <button className="w-full h-full" onClick={addSquare}>
          <Square className="m-auto" width={18} height={18} />
        </button>
      </ToolTipWrapper>
      <ToolTipWrapper text="Start/End">
        <button className="w-full h-full" onClick={addCircle}>
          <Circle className="m-auto" width={18} height={18} />
        </button>
      </ToolTipWrapper>
      <ToolTipWrapper text="Loop">
        <button className="w-full h-full" onClick={addRectangle}>
          <RectangleHorizontal className="m-auto" width={18} height={18} />
        </button>
      </ToolTipWrapper>
    </Panel>
  );
};
