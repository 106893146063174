import { useRef, useState } from 'react';

export const useCopy = (value: string) => {
  const [copying, setCopying] = useState(false);
  const copyRef = useRef<HTMLDivElement>(null);
  const copiedRef = useRef<HTMLDivElement>(null);
  const nodeRef = copying ? copyRef : copiedRef;

  const handleCopy = () => {
    const codeValue = value.replace(/^```.*([\s\S]*)```$/, '$1').trim();
    if (navigator.clipboard) {
      void navigator.clipboard.writeText(codeValue).then(() => {
        setCopying(true);
      });
    } else {
      setCopying(true);
      const textArea = document.createElement('textarea');
      textArea.value = codeValue;

      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }

    setTimeout(() => {
      setCopying(false);
    }, 1000);
  };

  return {
    handleCopy,
    copying,
    nodeRef,
  };
};
