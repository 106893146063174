import { transformForInput } from '@management/utils/formatText.ts';
import { useDatabaseManagement } from '@management/hooks';
import { TableItem } from '../tables/tableItem.tsx';
import { Checkbox } from '@packages/ui/shadcn/ui/checkbox.tsx';

type DatabaseTablesProps = Pick<
  ReturnType<typeof useDatabaseManagement>,
  | 'checkColumns'
  | 'loading'
  | 'tables'
  | 'changeItemDescription'
  | 'checkAllColumns'
  | 'isAllColumnsChecked'
>;

export function TablesContainer({
  loading,
  checkColumns,
  changeItemDescription,
  checkAllColumns,
  tables,
  isAllColumnsChecked,
}: DatabaseTablesProps) {
  if (loading) {
    return (
      <section className="w-full h-full flex flex-col items-center justify-center gap-3">
        <img src="/favicon/favicon-32x32.png" alt="" />
        <p className="text-sm text-[#8c95a1]">Fetching Tables Fields</p>
      </section>
    );
  }

  if (!tables || tables.length === 0) {
    return null;
  }

  const Columns = () =>
    tables?.map(table => (
      <div
        className="w-full flex flex-col border-r border-gray-300 last:border-r-0 items-start justify-start"
        key={table.table_name.name}
      >
        {Object.keys(table).map(column => {
          if (column === 'table_name') return null;
          return (
            <>
              <TableItem
                key={column}
                item={column}
                table={table.table_name.name}
                isChecked={table[column].isSelected}
                checkColumn={checkColumns}
                description={table[column].description}
                changeItemDescription={changeItemDescription}
              />
              <div className="w-full min-h-[1px] h-[1px] bg-light-low" />
            </>
          );
        })}
      </div>
    ));

  return (
    <div className="max-w-full min-w-fit w-full flex-shrink max-h-[40%] overflow-y-hidden bg-white rounded-md shadow-lg">
      <div className="w-full flex items-center bg-light-1">
        {tables?.map(table => (
          <>
            <div className="min-w-[50px] p-4 flex items-center justify-center border-r border-white">
              <Checkbox
                id="select-all"
                defaultChecked={isAllColumnsChecked}
                checked={isAllColumnsChecked}
                onCheckedChange={checkAllColumns}
              />
            </div>
            <section
              key={table.table_name.name}
              className="h-full min-w-fit w-72 overflow-x-auto border-r border-white last:border-r-0 p-4 font-medium"
            >
              {transformForInput(table.table_name.name)}
            </section>
            <div className="min-w-[200px] p-4 font-medium">Description</div>
          </>
        ))}
      </div>
      <div className="w-full h-fit max-h-full overflow-y-auto pb-4 flex">
        {Columns()}
      </div>
    </div>
  );
}
