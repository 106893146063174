import { useEffect, useRef } from 'react';
import { WS_URL } from '@rangpt/types/config.ts';
import Skeleton from 'react-loading-skeleton';
import { CopyChartURL } from './CopyChartURL.tsx';
import { PromptSelect } from '@rangpt/components/prompt-select/promptSelectProps.tsx';
import { removeString } from '@rangpt/lib/utils.ts';
import { ChartResponse, CodeT, DefaultResponse, Response } from '@rangpt/types';
import { AddToDashBoard } from '@rangpt/components/chart/addToDashBoard.tsx';
import { useCommandInterface } from '@rangpt/hooks';

const Chart = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    chat,
    currentPrompt,
    setCurrentPrompt,
    currentNode,
    setCurrentNode,
    loading,
  } = useCommandInterface();
  const { chart, html, code } = chat[currentPrompt];

  let data:
    | (Response & ChartResponse & DefaultResponse)
    | Exclude<Response, ChartResponse>
    | null = null;

  if (chart?.[currentNode]) {
    data = chart[currentNode];
  }

  if (html?.[currentNode]) {
    data = html[currentNode];
  }

  const promptList: (number | number[])[] = [...Array(chat.length).keys()];

  chat.forEach((item, index) => {
    if (!item.chart && !item.html) return;
    const list = item.chart ?? item.html ?? [];
    if (list.length > 0) {
      promptList[index] = [...Array(list.length).keys()];
    }
  });

  useEffect(() => {
    if (!ref.current) return;

    const ro = new ResizeObserver(entries => {
      if (ref.current) {
        for (const entry of entries) {
          const cr = entry.contentRect;
          const chart = ref.current.children[1];
          const styleForSmall = ' row-start-2 row-end-2 col-start-1 col-end-4';
          const styleForBigger = ' xl:row-span-2 xl:col-span-1';

          if (cr.width <= 800) {
            if (!chart.attributes[0].value.includes(styleForSmall)) {
              chart.className =
                removeString(chart.attributes[0].value, styleForBigger) +
                styleForSmall;
            }
          }
          if (
            cr.width > 800 &&
            !chart.attributes[0].value.includes(styleForBigger)
          ) {
            chart.className =
              removeString(chart.attributes[0].value, styleForSmall) +
              styleForBigger;
          }
        }
      }
    });

    if (ref.current) {
      ro.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        ro.unobserve(ref.current);
      }
    };
  }, [ref]);

  const renderContent = () => {
    // Simple elegant question: We can only have an HTML or Chart at time. So if we have one of them, `data` will have values, if we doesn't have none, is undefined
    if (!data && !loading) {
      return null;
    }
    if (!data)
      return (
        <section className="w-full flex flex-col items-center justify-center gap-3 absolute right-0">
          <img src="/favicon/favicon-32x32.png" alt="" />
          <p className="text-sm text-[#8c95a1]">
            Please wait, we are generating the chart
          </p>
        </section>
      );

    if (html)
      return (
        <iframe
          title="Plot map"
          className="min-w-xl w-full h-full"
          src={`${WS_URL}/charts/${data.content}.html`}
        ></iframe>
      );

    if (chart) {
      return (
        <img
          src={
            chart[currentNode].cachedChart ??
            `${WS_URL}/charts/${chart[currentNode].content}.png`
          }
          alt={data.content}
          className="w-auto max-h-9/10 m-auto fade-in"
        />
      );
    }
  };

  const renderActions = () => {
    if (!data?.content && !loading) return null;

    if (!data?.content)
      return (
        <div>
          <Skeleton style={{ height: '35px' }} className="fade-in" />
          <Skeleton style={{ height: '35px' }} className="fade-in" />
        </div>
      );

    if (!code?.[currentNode]) return;

    if (data.content) {
      return (
        <div className="flex flex-col gap-2">
          <AddToDashBoard
            chart={data}
            code={code[currentNode] as CodeT}
            isHTML={!chart}
          />
          <CopyChartURL
            link={`${WS_URL}/charts/${data?.content}.${
              chat[currentPrompt].html ? 'html' : 'png'
            }`}
          />
        </div>
      );
    }
  };

  return (
    <div
      className="w-full h-full p-4 grid grid-cols-chart grid-rows-chart border-[#CBD5E1] border-b fade-in relative"
      ref={ref}
    >
      <section className="row-span-1 w-28">
        <h2 className="text-xl fade-in">
          <span className="fade-in">Chart {currentPrompt + 1}</span>
        </h2>
        <span className="text-xs text-gray-800 opacity-60">
          Data visualization
        </span>
      </section>

      <section className="h-full xl:row-span-2 xl:col-span-1 flex flex-col justify-center items-center">
        {renderContent()}
      </section>

      <div className="w-44 row-span-1 col-start-3 col-end-3 flex flex-col gap-2 ml-auto">
        <PromptSelect
          promptsList={promptList}
          setCurrentPrompt={setCurrentPrompt}
          currentPrompt={currentPrompt}
          setCurrentNode={setCurrentNode}
          currentNode={currentNode}
        />
        {renderActions()}
      </div>
    </div>
  );
};

export { Chart };
