import {
  FloatingInput,
  FloatingLabel,
} from '@packages/ui/shadcn/ui/floating-label-input.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@packages/ui/shadcn/ui/tooltip.tsx';
import { transformForInput } from '@management/utils/formatText.ts';
import { Info } from 'react-feather';
import { ChangeEvent, useEffect, useState } from 'react';
import { UiStore } from '@management/stores';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';

interface DatabaseInfoInputProps {
  disabled: boolean;
  field: string;
  tooltip: string;
  defaultValue?: string;
}

export const DatabaseInfoInput = ({
  tooltip,
  field,
  disabled,
  defaultValue = '',
}: DatabaseInfoInputProps) => {
  const [value, setValue] = useState<string>(defaultValue);
  const { setShowConnect } = UiStore();

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (field === 'tables') {
      setShowConnect(false);
    }
    setValue(e.currentTarget.value);
  };

  return (
    <section className="relative w-full">
      <FloatingInput
        name={field}
        className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-3 placeholder:text-xs"
        id={field}
        value={value}
        type="text"
        autoComplete="user"
        autoCorrect="off"
        disabled={disabled}
        onChange={handleChange}
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <FloatingLabel
              htmlFor={field}
              className={cn(
                'bg-transparent text-xs peer-focus:top-0 rtl:peer-focus:left flex gap-1 items-center bot-full',
                value.length > 0 && '!top-0',
              )}
            >
              {transformForInput(field)}
              <Info width={14} height={14} />
            </FloatingLabel>
          </TooltipTrigger>
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </section>
  );
};
