import { Fragment, memo } from 'react';
import { ProcessedLine } from '@app/stores/useObjectivesStore.ts';
import { KeyWordsList } from '@app/types';
import { HighlightBtn } from '@components/ObjectivesArea/HighlightBtn.tsx';

interface LineOverlayProps {
  line: ProcessedLine;
  handleAddSelectedKey: (_: {
    currentWord: string;
    newWord: string;
    position: number;
  }) => void;
  lineIndex: number;
  keyWordsList: KeyWordsList;
  addKeyWordList: (keyWordList: { key: string; list: string[] }) => void;
  addObjectiveChange: (change: string) => void;
}

export const LineOverlayComponent = ({
  line,
  handleAddSelectedKey,
  lineIndex,
  keyWordsList,
  addObjectiveChange,
  addKeyWordList,
}: LineOverlayProps) => {
  return (
    <span className="relative">
      {line.map(part => {
        if (part.type === 'normal') {
          return <span key={part.value}>{part.value}</span>;
        }

        // Highlight part
        return (
          <Fragment key={part.value}>
            <HighlightBtn
              word={part.value}
              handleAddSelectedKey={handleAddSelectedKey}
              lineIndex={lineIndex}
              addObjectiveChange={addObjectiveChange}
              addKeyWordList={addKeyWordList}
              keyWordsList={keyWordsList}
            />
          </Fragment>
        );
      })}
    </span>
  );
};

export const LineOverlay = memo(LineOverlayComponent);
