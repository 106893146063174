import { flowChartStore, UiStore } from '@app/stores';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const useUiStore = () => {
  const {
    flowChartStep,
    setFlowChartStep,
    isCodeEnabled,
    setIsCodeEnabled,
  } = UiStore(
    useShallow(state => ({
      flowChartStep: state.flowChartStep,
      setFlowChartStep: state.setFlowChartStep,
      isCodeEnabled: state.isCodeEnabled,
      setIsCodeEnabled: state.setIsCodeEnabled,
    })),
  );
  const nodes = flowChartStore(state => state.nodes);

  useEffect(() => {
    const nodesWithObjectives = nodes.filter(
      node => node.data.objectives && node.data.objectives?.length > 0,
    );

    setIsCodeEnabled(nodesWithObjectives.length > 0);
  }, [nodes, setIsCodeEnabled]);

  return {
    flowChartStep,
    setFlowChartStep,
    isCodeEnabled,
  };
};
