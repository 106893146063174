import { DatabaseSettingsForm } from './databaseSettingsForm.tsx';
import { useDatabaseManagement } from '@management/hooks';
import { Table } from '@management/components/database/tables';

export const DatabaseContainer = () => {
  const databaseManagement = useDatabaseManagement();

  return (
    <div className="w-full h-full flex flex-col items-center justify-start gap-6 px-28 py-10 pb-4">
      <header className="w-full flex items-center justify-between">
        <h2 className="font-medium">Database Management</h2>
      </header>
      <DatabaseSettingsForm {...databaseManagement} />
      <Table.Container {...databaseManagement} />
    </div>
  );
};
