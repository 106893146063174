import { useEffect } from 'react';

// Updates the height and width of a textarea when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // Reset height and width momentarily to calculate the correct dimensions
      textAreaRef.style.height = '0px';
      // textAreaRef.style.width = '40px'; // Let it grow naturally first to calculate scrollWidth
      const scrollHeight = textAreaRef.scrollHeight;
      // const scrollWidth = textAreaRef.scrollWidth;

      // Set the height and width directly based on scroll sizes
      textAreaRef.style.height = `${scrollHeight}px`;
      // textAreaRef.style.width = `${scrollWidth}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
