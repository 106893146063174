import { Separator } from '@app/components/ui/separator.tsx';
import { StepperButton } from '@components/stepper/button.tsx';
import { FlowChartStep } from '@app/types';
import { useLocation, useNavigate } from '@tanstack/react-router';
import { useRoutinesStore } from '@app/stores/routine.store.ts';
import { useCodeStore } from '@app/stores/code.store.ts';
import { useTestStore } from '@app/stores/test.store.ts';

// interface StepperContainerProps {
//   isCodeEnabled: boolean;
//   currentRoutine: RoutineData;
// }

export const StepperContainer = () => {
  // Get the routine is really bad
  const currentRoutineId = useRoutinesStore(state => state.currentRoutine.id);
  const code = useCodeStore(state => state.code);
  const testTables = useTestStore(state => state.tables);
  const step = useLocation().pathname;

  const navigate = useNavigate();

  // const disableNavigation = currentRoutine.name ? !isCodeEnabled : true;

  const handleNavigation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (e.currentTarget.id === FlowChartStep.FLOW_CHART) {
      return navigate({
        to: `/app/$routine/flowchart`,
        params: { routine: currentRoutineId },
      });
    }
    if (e.currentTarget.id === FlowChartStep.CODE) {
      return navigate({
        to: '/app/$routine/code/development',
        params: { routine: currentRoutineId },
      });
    }
    return navigate({
      to: `/app/$routine/code/${e.currentTarget.id}`,
      params: { routine: currentRoutineId },
    });
  };

  return (
    <header className="w-full h-24 py-3 px-6 flex items-center justify-center gap-2 shadow-xl bg-light-3 z-50 relative">
      <StepperButton
        id={FlowChartStep.FLOW_CHART}
        onClick={handleNavigation}
        selected={step.includes(FlowChartStep.FLOW_CHART)}
        disabled={false}
      >
        Design
      </StepperButton>
      <Separator className="w-32" />
      <StepperButton
        id={FlowChartStep.CODE}
        onClick={handleNavigation}
        selected={step.includes('development')}
        disabled={!code.content}
      >
        Code Development
      </StepperButton>
      <Separator className="w-32" />
      <StepperButton
        id={FlowChartStep.TEST}
        onClick={handleNavigation}
        selected={step.includes(FlowChartStep.TEST)}
        disabled={!testTables.initial}
      >
        Test
      </StepperButton>
    </header>
  );
};
