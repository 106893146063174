import { Bounce, ToastOptions } from 'react-toastify';

export const defaultLightToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light',
  transition: Bounce,
};

export const defaultToastErrorToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'light',
  transition: Bounce,
};
