const SendArrow = ({ width, height, fill }: { width: number; height: number; fill?: string }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    className="icon-sm m-1 md:m-0"
    width={width || 16}
    height={height || 16}
  >
    <path
      d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
      fill={fill ?? '#ACACBE'}
      strokeWidth="2px"
    ></path>
  </svg>
);

export { SendArrow };
